import React from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBView, MDBBtn } from "mdbreact";

const BlogFirst = () => {
  return (
    <MDBCard className="my-5 px-5 pb-5">
      <MDBCardBody>
        <h2 className="h1-responsive font-weight-bold text-center my-5">
          ¿Te gustaría que tus hijos fueran más autónomos? ¿Quieres saber cómo lograrlo?
        </h2>
        <img
          className="img-fluid"
          src="images/image001.jpg"
          alt=""
        />
        <p className="text-center w-responsive mx-auto mb-5">
          En <strong>eduk2</strong>, permea un ambiente de constante investigación para poder responder a los desafíos que los tiempos presentan, por lo que, contamos con un equipo de especialistas en diversas áreas de desarrollo en marketing educativo que día con día crean y desarrollan nuevos contextos de interacción para responder a tan importantes retos.
        </p>
        <MDBRow>
          <MDBCol lg="5" xl="4">

              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
          </MDBCol>
          <MDBCol lg="7" xl="8">
            <h3 className="font-weight-bold mb-3 p-0">
              <strong>Como crear hijos autónomos</strong>
            </h3>
            <p className="dark-grey-text">

           ¿Te gustaría que tus hijos fueran más autónomos? ¿Quieres saber cómo lograrlo?

           Si les inculcamos el sentido de la autonomía desde los primeros años, crecerán con una buena autoestima, con confianza en sí mismos, valientes, atrevidos, alegres, optimistas y preparados para perseguir sus sueños.

           Hemos elaborado una lista de 10 valiosas recomendaciones para criar hijos autosuficientes y sanos emocionalmente:



           1. Asigna responsabilidades
           Hay algunas labores que deberán cumplir, como hacer la cama, cepillarse los dientes sin ayuda, ducharse, encargarse ellos solos de los deberes del colegio, etc.

            </p>

            <img width="530" height="354" src="images/image022.jpg" />
            <h3><strong><span>10. Respeta su independencia</span></strong></h3>
          </MDBCol>
        </MDBRow>
        <hr className="my-5" />
      </MDBCardBody>
    </MDBCard>
  );
}

export default BlogFirst;
