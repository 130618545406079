/* eslint-disable no-underscore-dangle,no-unused-vars */
/**
 * Axios Request Wrapper
 * ---------------------
 *
 * @author  Sheharyar Naseer (@sheharyarn)
 * @license MIT
 *
 */

import axios from 'axios';
import React from 'react';
import qs from 'qs';

let authTokenRequest;

// @todo: these method should live in services
function getAccessToken() {
  const token = localStorage.getItem('accessToken');
  return token
}
function getAuthToken(dataConf) {
  if (!authTokenRequest) {
   authTokenRequest = requestNewToken(dataConf);
   authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }
  return authTokenRequest;
  //return  'A21AAFZa0BBtpHnoU16SyMHlLBosv_zmxo5WTTc_f_8PnyFhgT2omjRaXFNXOiLMlGsoZxYoyWuNdxVZ92nkJtxtFtYGYNKKQ' //functions.config().paypal.key;
}

function requestNewToken(dataConf) {
  console.log(dataConf);
 var newToken = Request({
 method: "post",
 url: 'v1/oauth2/token',
 headers: {
   "Content-Type":"application/x-www-form-urlencoded"
 },
 auth: {
   username: dataConf.data.username,
   password: dataConf.data.secret
 },
 data: qs.stringify({
   "grant_type": "client_credentials"
 })
 },dataConf).then((res)=>{
   console.log(res);
  if(res.data.access_token){
    console.log(res.data.access_token);
    localStorage.setItem('accessToken',res.data.access_token);
  }
  else {
   console.log("/logout");
  }
  });
  return newToken;

}

function resetAuthTokenRequest() {
  authTokenRequest = null;
}

export class AxiosWrapper {
  static _instance = null;
  static _config = null;

  constructor(config) {
    this._config = config;
  }
  // eslint-disable-next-line class-methods-use-this
  get instance() {
    /**
     * Create an Axios Client with defaults
     */
    const token = getAccessToken();
    const url = this._config.data.url;
    console.log(url);
    AxiosWrapper._instance = axios.create({
      baseURL: url,
      ...(token &&
         { headers: { Authorization: `Bearer ${token}` } }),
    });

    return AxiosWrapper._instance;
  }
  get config() {
    return AxiosWrapper._config;
  }
}


/**
 * Request Wrapper with default success/error actions
 */
const Request = function AxiosWrapperRequest(options, dataConf) {
  console.log(dataConf);
  const onSuccess = function AxiosWrapperOnSuccess(response) {
    return response;
  };

  const onError = function AxiosWrapperOnError(error) {
    console.log(error);
    return Promise.reject(error.response || error.message);
  };
  const axiosClient = new AxiosWrapper(dataConf);
  const exec = axiosClient.instance;

  exec.interceptors.response.use(undefined, err => {
    const error = err.response;
    // if error is 401
    if (error.status===401 && error.config &&
    !error.config.__isRetryRequest) {
    // request for a new token
    return getAuthToken(dataConf).then(response => {
     // update the error config with new token
     const token = localStorage.getItem("accessToken");
     error.config.__isRetryRequest = true;
     error.config.headers.Authorization= `Bearer ${token}`;
     return exec(error.config, dataConf);
    });
   }
  });

  return exec(options)
    .then(onSuccess)
    .catch(onError);
};

export default Request;
