import React, { useEffect, useState }  from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBIframe } from 'mdbreact';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase';
import { toast } from "react-toastify";
import i18n from 'i18next';
import Openpay from 'openpay'

const openpay = new Openpay('mhge3mbbb33cckorof7y','sk_fc6f922dc4364c6490ad1979c54ff02e', false);

const EmbedsPage = () => {

  return (

          <MDBIframe id="header" src="https://b44e4152-aeb6-446b-9ff9-db89f0a2ad28.filesusr.com/ugd/850e72_7a5399b364184a9d8fe7fb21bcfa66f5.pdf" />

      //<object data="https://b44e4152-aeb6-446b-9ff9-db89f0a2ad28.filesusr.com/ugd/850e72_7a5399b364184a9d8fe7fb21bcfa66f5.pdf" type="application/pdf" >
      //  <embed height="100%" width="100%" src="https://b44e4152-aeb6-446b-9ff9-db89f0a2ad28.filesusr.com/ugd/850e72_7a5399b364184a9d8fe7fb21bcfa66f5.pdf"></embed>
      //</object>

  )
}

const PagoForm = (props) => {
  const initialStateValues = {
    rfc: '',
    nombreFiscal: '',
    usoCFDI: '',
    tipoPago: '',
    condicionesPago: '',
    observacionesPago: '',
    emailPago: '',
    aceptado: false,
    mostrar: false,
    paquete: '',
    authorId: '',
    correoFacturacion: '',
  };
  const {firestore, auth} = props;
  const [values, setValues] = useState(initialStateValues);
  const [clientId, setClientId] = useState('');
  const [pagoId, setPagoId] = useState('');
  const [paqueteRegistro, setPaqueteRegistro] = useState('base');
  const [monto, setMonto] = useState('2204.00');
  const [mensaje, setMensaje] = useState('');
  const {paquete} = props;
  console.log(paquete);
  console.log(auth.uid);
  const getUser = async () => {
    console.log('getUser');
    firestore.onSnapshot({ collection: "users", doc: auth.uid},
    function(querySnapshot) {
        console.log(source, " data: ", querySnapshot.data());
        var source = querySnapshot.metadata.hasPendingWrites ? "Local" : "Server";

        const doc = querySnapshot.data();
        if ('openpayClientId' in doc) {
          console.log(doc.openpayClientId);
          setClientId(doc.openpayClientId);
        }

        if ('DatosPago' in doc) {
          setValues(doc.DatosPago);
        }
        if ('PagoId' in doc) {
          setPagoId(doc.PagoId);
        }

    });
  };
  const setUserPago = async (datos) => {
    console.log('updateUser');
    firestore.update(
        { collection: "users", doc: auth.uid },
         datos
    ).then()
    .catch((error) =>
      {console.log(error);}
    );
  };
  useEffect(() => {
    getUser();
  }, []);

  const createPago = (cantidad, customer, desc) => {
    const chargeRequest = {
     'method' : 'card',
     'amount' : cantidad,
     'description' : desc,
     'customer' : customer,
    'send_email' : false,
    'confirm' : false,
    'redirect_url' : 'https://www.eduk2.mx/success'
    }

    openpay.charges.create(chargeRequest, function(error, charge) {
      console.log(charge);
      const {id, payment_method} = charge;
      console.log(id);
      setUserPago({PagoId: id});
      console.log(payment_method);
      window.location.href = payment_method.url;
      // ...
    });
  }

  const createCustomer = () => {
    const newCustomer = {
      "name":"John",
      "email":"johndoe@example.com",
      "last_name":"Doe",
      "address":{
        "city":"Queretaro",
        "state":"Queretaro",
        "line1":"Calle Morelos no 10",
        "line2":"col. san pablo",
        "postal_code":"76000",
        "country_code":"MX"
      },
      "phone_number":"44209087654"
    };

    openpay.customers.create(newCustomer, function(error, body) {
        console.log(error);    // null if no error occurred (status code != 200||201||204)
        console.log(body);     // contains the object returned if no error occurred (status code == 200||201||204)
    });
  }

  const handleSubmit = (e) => {
    console.log(e);
    e.preventDefault();
    e.target.className += " was-validated";

    if (values.rfc && values.rfc.length > 1 &&
        values.nombreFiscal && values.nombreFiscal.length > 1 &&
        values.correoFacturacion &&values.correoFacturacion.length > 1) {
          setUserPago({ DatosPago: values });
        //createCustomer();
      //let x = this.props.createOrden(this.state);
      console.log(values);
      const precios = {
        "plataforma": "2204.00",
        "despegue": "2900.00",
        "explora": "3596.00",
        "descubre": "4524.00"
      }
      const descripciones = {
        "plataforma": "Pago Inscripcion Eduk2 Plataforma",
        "despegue": "Pago Inscripcion Eduk2 Despegue",
        "explora": "Pago Inscripcion Eduk2 Explora",
        "descubre": "Pago Inscripcion Eduk2 Descubre"
      }
      let desc = descripciones[paquete];
      let datosCliente = {
           'name' : values.nombreFiscal,
           'email' : values.correoFacturacion
      }
      createPago(precios[paquete], datosCliente, desc);
      //this.setState({
      //  mostrar: true
      //})
    }
    else {
      alert("Debe capturar todos los datos y aceptar");
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }
  const handleChangeCheckbox = (e) => {
    console.log('handleChangeCheckbox');
    const { name, checked } = e.target;
    setValues({ ...values, aceptado: checked });
  }
  const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) { console.log("bottom") }
  }

  return (
    <MDBContainer>
      <h1>Pago Inscripción</h1>
      <MDBRow>
        <p className="h4 text-center">Datos Facturación</p>
      </MDBRow>
      <form className="needs-validation" onSubmit={handleSubmit} noValidate>
      <div class="d-flex flex-column col-md-8 item-center">
          <MDBInput
            value={values.rfc}
            onChange={handleChange}
            type="text"
            id="rfc"
            name="rfc"
            label="RFC"
            required
          >
            <div className="invalid-feedback">
               {i18n.t('proveeDatosValidos',{s: 'RFC'})}
            </div>
            <div className="valid-feedback">{i18n.t('Correcto')}</div>
          </MDBInput>
          <MDBInput
            value={values.nombreFiscal}
            onChange={handleChange}
            type="text"
            id="nombreFiscal"
            name="nombreFiscal"
            label="Nombre Fiscal"
            required
          >
            <div className="invalid-feedback">
              {i18n.t('proveeDatosValidos',{s: 'Nombre Fiscal'})}
             </div>
            <div className="valid-feedback">{i18n.t('Correcto')}</div>
          </MDBInput>
          <MDBInput
            value={values.usoCFDI}
            onChange={handleChange}
            type="text"
            id="usoCFDI"
            name="usoCFDI"
            label="Uso CFDI"
          >
          </MDBInput>
          <MDBInput
            value={values.tipoPago}
            onChange={handleChange}
            type="text"
            id="tipoPago"
            name="tipoPago"
            label="Tipo de Pago"
          >
          </MDBInput>
          <MDBInput
            value={values.condicionesPago}
            onChange={handleChange}
            type="text"
            id="condicionesPago"
            name="condicionesPago"
            label="Condiciones de Pago"
          >
          </MDBInput>
          <MDBInput
            value={values.observacionesPago}
            onChange={handleChange}
            type="text"
            id="observacionesPago"
            name="observacionesPago"
            label="Observaciones"
          >
          </MDBInput>
          <MDBInput
            value={values.correoFacturacion}
            onChange={handleChange}
            type="email"
            id="correoFacturacion"
            name="correoFacturacion"
            label="Correo para Facturación"
            required
          >
            <div className="invalid-feedback">
             {i18n.t('proveeDatosValidos',{s: '$t(correo)'})}
             </div>
             <div className="valid-feedback">{i18n.t('Correcto')}</div>
          </MDBInput>
          <MDBInput
               label='Contrato de Adehesión'
               type="checkbox"
               group
               validate
               id="invalidCheck"
               required
               onChange={handleChangeCheckbox}
           >
           <div className="invalid-feedback">
               Debe estar de acuerdo.
           </div>
           <div className="valid-feedback">{i18n.t('Correcto')}</div>
           </MDBInput>
           {values.aceptado &&   <MDBContainer >
               <h1>Contrato de Adehesión</h1>
               <EmbedsPage onScroll={handleScroll}/>
             </MDBContainer>}
      <MDBBtn rounded className="float-left"  type="submit">
          Continuar Pago
      </MDBBtn>
      </div>
      </form>

    </MDBContainer>
   )
};

const mapDispatchToProps = (dispatch) => {
	return {
	}
}


const mapStateToProps = (state) => {
	console.log(state)
	return {
		auth: state.firebase.auth
	}
}
export default compose(
  withFirestore,
	connect(mapStateToProps, mapDispatchToProps),)(PagoForm);
