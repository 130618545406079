import React from 'react';
import { MDBTable, MDBTableBody, MDBTableHead, MDBIcon } from 'mdbreact';

const DatatablePage = props => {

  return (
    <MDBTable bordered small>
      <MDBTableHead color="primary-color" textWhite>
        <tr>
          <th>Paquete</th>
          <th>Plataforma</th>
          <th>Despegue</th>
          <th>Explora</th>
          <th>Descubre</th>
          <th>Conquista</th>
          <th>Aventura</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        <tr class="table-secondary">
          <td colspan="7"><b>Información</b></td>
        </tr>
        <tr>
          <td>Contacto</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Ubicación en mapa</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Teléfono</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
        </tr>
        <tr>
          <td>Correo</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
        </tr>
        <tr>
          <td>Dirección</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
        </tr>
        <tr class="table-secondary">
          <td colspan="7"><b>Ubicación por filtros</b></td>
        </tr>
        <tr>
          <td>Ciudad</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
        </tr>
        <tr>
          <td>Edad</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Nivel</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Inversion Anual Mensualizada</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Metodología</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Idiomas</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Instalaciones</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Extracurriculares</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Servicios</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Satisfaccion de Usuario</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Horario</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
        </tr>
        <tr class="table-secondary">
          <td colspan="7" ><b>Botones Reactivos</b></td>
        </tr>
        <tr>
          <td>URL</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
        </tr>
        <tr>
          <td>Booking*</td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Contacto</td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Redes Sociales</td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
        </tr>
        <tr class="table-secondary">
          <td colspan="7" ><b>Popups</b></td>
        </tr>
        <tr>
          <td>Descriptivo Servicios texto</td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Promociones</td>
          <td align="center"></td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
        </tr>
        <tr>
        <td>Video Descriptivo</td>
          <td align="center"></td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" />(3)</td>
          <td align="center"><MDBIcon icon="rocket" />(1)</td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr class="table-secondary">
          <td colspan="7" ><b>Imagen</b></td>
        </tr>
        <tr>
          <td>Logotipo</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Foto Institucional</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
        </tr>
        <tr>
          <td>Galeria</td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" />(8)</td>
          <td align="center"><MDBIcon icon="rocket" />(20)</td>
          <td align="center"><MDBIcon icon="rocket" />(20)</td>
          <td align="center"><MDBIcon icon="rocket" />(5)</td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Video Institucional</td>
          <td align="center"></td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Video por Nivel</td>
          <td align="center"></td>
          <td align="center"></td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Video Servicios</td>
          <td align="center"></td>
          <td align="center"></td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr class="table-secondary">
          <td colspan="7" ><b>Concepto</b></td>
        </tr>
        <tr>
          <td>Entrevista para Blog</td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Artículo en Blog</td>
          <td align="center"></td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Entrevista en Vlog</td>
          <td align="center"></td>
          <td align="center"></td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr class="table-secondary">
          <td colspan="7" ><b>Backend</b></td>
        </tr>
        <tr>
          <td>Analíticos Semestrales</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Carga Inicial de Datos</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Acceso a Edición</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr>
          <td>Foro Anual Eduk2</td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"></td>
          <td align="center"></td>
        </tr>
        <tr class="table-secondary">
          <td colspan="7" ><b>Promocional</b></td>
        </tr>
        <tr>
          <td>Carrusel</td>
          <td align="center"></td>
          <td align="center"></td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
        </tr>
        <tr>
          <td>Cuponera</td>
          <td align="center"></td>
          <td align="center"></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
          <td align="center"><MDBIcon icon="rocket" /></td>
        </tr>
      </MDBTableBody>
    </MDBTable>
  );
}

export default DatatablePage;
