import React from "react";
//import { MDBInputGroup  } from "mdbreact";

export const Infraestructura = props =>  {
  //console.log(props);
  return (
    <div class="row">
       <label for={props.nivel+"_"+props.id} className="col-sm-4 col-form-label">{props.name}</label>
       <div class="col-sm-4">
         <div>
            <div className="def-number-input number-input">
               <button onClick={props.decrease.bind(this)} id={props.nivel+"_"+props.id} className="minus"></button>
               <input className="quantity"
                  key={props.id}
                  id={props.nivel+"_"+props.id}
                  name={props.nivel+"_"+props.id}
                  value={props.value}
                  onChange={props.handleInfraChange}
                  type="number" />
               <button onClick={props.increase.bind(this)} id={props.nivel+"_"+props.id} className="plus"></button>
             </div>
         </div>
       </div>
    </div>
    //<div class="input-group  flex-wrap">
    //  <div class="input-group-prepend">
    //    <span class="input-group-text mb-3">{props.name}</span>
    //  </div>
    //  <input class="form-control" aria-label="{props.name}"></input>
    //</div>
      //<MDBInputGroup containerClassName="flex-nowrap mb-3" prepend={props.name} hint="Cantidad"/>
      //#<MDBInput label={props.name} id={props.id} name={props.id} noTag size="sm" type="text" />
  )
}

export default Infraestructura
