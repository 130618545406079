import React, { useEffect, useState } from "react";
import CitaForm from "./Cita";

import { toast } from "react-toastify";
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase';

const Citas = (props) => {
  const [links, setLinks] = useState([]);
  const [currentId, setCurrentId] = useState("");
  const {firestore} = props;

  const getLinks = async () => {
    console.log('getLinks');
    firestore.onSnapshot({ collection: "citas"},
    function(querySnapshot) {
        console.log(source, " data: ", querySnapshot.data());
        var source = querySnapshot.metadata.hasPendingWrites ? "Local" : "Server";

        console.log(source, " data: ", querySnapshot.data());
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        setLinks(docs);
    });
  };

  useEffect(() => {
    getLinks();
  }, []);

  const addOrEditCita = async (linkObject) => {
    console.log("addCita");
    try {
      if (currentId === "") {
        const emailAdmis = props.escuela.admisionesEmail || 'eduk2saltillo@gmail.com'
        await firestore.collection('citas').add(
           {...linkObject, escuela: props.escuela.comercialName, emailAdmis}
       ).then((data) => {
         console.log(data.id);
         setCurrentId(data.id);
         toast("Nueva cita agregada", {
           type: "success",
         });
       })
       .catch((error) =>
         {
           console.log(error);
           toast(error, {
                  type: "error",
                });
          }
       );
        //await firestore.collection("citas").doc().set(linkObject);

      } else {
        await firestore.collection("citas").doc(currentId).update(linkObject);
        toast("Link Updated Successfully", {
          type: "info",
        });
        setCurrentId("");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div key={props.escuela.CCT} className="col-md-10 p-2">
        <CitaForm {...{ addOrEditCita, currentId, links, escuela: props.escuela }} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
	return {
	}
}


const mapStateToProps = (state) => {
	return {
	}
}
export default compose(
  withFirestore,
	connect(mapStateToProps, mapDispatchToProps),)(Citas);
