import React, { Component, useReducer } from 'react'
import Select from 'react-select'
import i18n from 'i18next';

const data = {
  estados: [
    {value: "Aguascalientes", label: "Aguascalientes",
    ciudades: [{value: "Aguascalientes", label: "Aguascalientes"}] },
    {value: "Baja California", label:"Baja California",
    ciudades: [{value:"Ensenada", label:"Ensenada"},{value:"Mexicali", label:"Mexicali"},{value:"Tijuana", label:"Tijuana"}] },
    {value: "Baja California Sur", label: "Baja California Sur",
    ciudades: [{value:"La Paz", label:"La Paz"}] },
    {value: "Campeche", label: "Campeche",
    ciudades: [{value:"Campeche", label:"Campeche"},{value:"Ciudad del Carmen", label:"Ciudad del Carmen"}] },
    {value: "Chiapas", label: "Chiapas",
    ciudades: [{value:"San Cristóbal de las Casas", label:"San Cristóbal de las Casas"},{value:"Tapachula", label:"Tapachula"},{value:"Tuxtla", label:"Tuxtla"}] },
    {value: "Chihuahua",label: "Chihuahua",
    ciudades: [{value:"Chihuahua", label:"Chihuahua"},{value:"Cuauhtémoc", label:"Cuauhtémoc"}, {value:"Delicias", label:"Delicias"},{value:"Hidalgo del Parral", label:"Hidalgo del Parral"},{value:"Juárez", label:"Juárez"}] },
    {value: "Coahuila", label: "Coahuila",
    ciudades: [{value: "Saltillo", label: "Saltillo"},{value: "Monclova", label: "Monclova"},{value: "Piedras Negras", label: "Piedras Negras"},{value: "Torreón", label: "Torreón"}, {value: "Ciudad Acuña", label: "Ciudad Acuña"}] },
    {value: "Colima" ,label: "Colima",
      ciudades: [{value: "Colima",label: "Colima"},{value: "Manzanillo",label: "Manzanillo"}]},
    {value: "Distrito Federal",label:"Distrito Federal",
      ciudades: [{value: "Ciudad de México",label: "Ciudad de México"}]},
    {value: "Durango",label: "Durango",
      ciudades: [{value: "Durango",label: "Durango"}, {value: "Gómez Palacio",label: "Gómez Palacio"}]},
    {value: "Guanajuato",label: "Guanajuato",
      ciudades: [{value: "Celaya",label: "Celaya"},{value: "Irapuato",label: "Irapuato"},{value: "León",label: "León"},{value: "Salamanca",label: "Salamanca"}]},
    {value: "Guerrero",label: "Guerrero",
      ciudades: [{value: "Acapulco",label: "Acapulco"},{value: "Chilpancingo",label: "Chilpancingo"},{value: "Iguala",label: "Iguala"}]},
    {value: "Hidalgo",label: "Hidalgo",
      ciudades: [{value: "Pachuca",label: "Pachuca"},{value: "Tulancingo de Bravo",label: "Tulancingo de Bravo"}]},
    {value: "Jalisco",label: "Jalisco",
      ciudades: [{value: "Guadalajara",label: "Guadalajara"},{value: "Puerto Vallarta",label: "Puerto Vallarta"},{value: "Tlaquepaque",label: "Tlaquepaque"},{value: "Tonalá",label: "Tonalá"},{value: "Zapopan",label: "Zapopan"}]},
    {value: "Nuevo León",label: "Nuevo León",
      ciudades: [{value: "Monterrey",label: "Monterrey"},{value: "Juárez",label: "Juárez"},{value: "San Nicolás de los Garza",label: "San Nicolás de los Garza"},{value: "San Pedro Garza García ",label: "San Pedro Garza García"},{value: "Santa Catarina",label: "Santa Catarina"},{value: "Apodaca",label: "Apodaca"},{value: "General Escobedo",label: "General Escobedo"},{value: "Guadalupe",label: "Guadalupe"}]},
    {value: "México",label: "México",
     ciudades: [{value: "Toluca", label: "Toluca"},{value: "Buenavista", label: "Buenavista"},{value: "Chalco", label: "Chalco"},{value: "Chicoloapan", label: "Chicoloapan"},{value: "Chimalhuacán", label: "Chimalhuacán"},{value: "Ciudad López Mateos", label: "Ciudad López Mateos"},{value: "Cuautitlán", label: "Cuautitlán"},{value: "Cuautitlán Izcalli", label: "Cuautitlán Izcalli"},{value: "Ecatepec", label: "Ecatepec"},{value: "Ixtapaluca", label: "Ixtapaluca"},{value: "Naucalpan", label: "Naucalpan"},{value: "Naucalpan de Juárez", label: "Naucalpan de Juárez"},{value: "Nezahualcóyotl", label: "Nezahualcóyotl"},{value: "Ojo de Agua", label: "Ojo de Agua"},{value: "San Francisco Coacalco", label: "San Francisco Coacalco"},{value: "San Pablo de las Salinas", label: "San Pablo de las Salinas"},{value: "Tepexpan", label: "Tepexpan"},{value: "Texcoco de Mora", label: "Texcoco de Mora"},{value: "Tlalnepantla", label: "Tlalnepantla"},{value: "Villa Nicolás Romero", label: "Villa Nicolás Romero"},{value: "Xico", label: "Xico"}]},
    {value: "Michoacán",label: "Michoacán",
     ciudades: [{value: "Morelia", label: "Morelia"},{value: "Uruapan", label: "Uruapan"},{value: "Zamora", label: "Zamora"}]},
    {value: "Morelos",label: "Morelos",
      ciudades: [{value: "Cuautla ", label: "Cuautla "},{value: "Cuernavaca", label: "Cuernavaca"},{value: "Jiutepec", label: "Jiutepec"}]},
    {value: "Nayarit",label: "Nayarit",
      ciudades: [{value: "Tepic", label: "Tepic"}]},
    {value: "Oaxaca",label: "Oaxaca",
      ciudades: [{value: "Oaxaca", label: "Oaxaca"},{value: "San Juan Bautista Tuxtepec", label: "San Juan Bautista Tuxtepec"}]},
    {value: "Puebla",label: "Puebla",
      ciudades: [{value: "Puebla", label: "Puebla"},{value: "Tehuacán", label: "Tehuacán"}]},
    {value: "Querétaro",label: "Querétaro",
      ciudades: [{value: "Querétaro", label: "Querétaro"},{value: "San Juan del Río", label: "San Juan del Río"}]},
    {value: "Quintana Roo",label: "Quintana Roo",
      ciudades: [{value: "Cancún", label: "Cancún"},{value: "Chetumal", label: "Chetumal"},{value: "Playa del Carmen", label: "Playa del Carmen"}]},
    {value: "San Luis Potosí",label: "San Luis Potosí",
      ciudades: [{value: "San Luis Potosí", label: "San Luis Potosí"},{value: "Soledad de Graciano Sánchez", label: "Soledad de Graciano Sánchez"},{value: "Ciudad Valles", label: "Ciudad Valles"}]},
    {value: "Sinaloa",label: "Sinaloa",
      ciudades: [{value: "Culiacán", label: "Culiacán"},{value: "Los Mochis", label: "Los Mochis"},{value: "Mazatlán", label: "Mazatlán"}]},
    {value: "Sonora",label: "Sonora",
      ciudades: [{value: "Ciudad Obregón", label: "Ciudad Obregón"},{value: "Guaymas", label: "Guaymas"},{value: "Hermosillo", label: "Hermosillo"},{value: "Navojoa", label: "Navojoa"},{value: "Nogales", label: "Nogales"},{value: "San Luis Río Colorado", label: "San Luis Río Colorado"}]},
    {value: "Tabasco",label: "Tabasco",
      ciudades: [{value: "Villahermosa", label: "Villahermosa"}]},
    {value: "Tamaulipas",label: "Tamaulipas",
      ciudades: [{value: "Tampico", label: "Tampico"},{value: "Reynosa", label: "Reynosa"},{value: "Nuevo Laredo", label: "Nuevo Laredo"},{value: "Miramar", label: "Miramar"},{value: "Matamoros", label: "Matamoros"},{value: "Ciudad Madero", label: "Ciudad Madero"},{value: "Ciudad Victoria", label: "Ciudad Victoria"}]},
    {value: "Tlaxcala",label: "Tlaxcala",
      ciudades: [{value: "Tlaxcala", label: "Tlaxcala"}]},
    {value: "Veracruz",label: "Veracruz",
      ciudades: [{value: "Veracruz", label: "Veracruz"},{value: "Coatzacoalcos", label: "Coatzacoalcos"},{value: "Córdoba", label: "Córdoba"},{value: "Minatitlán", label: "Minatitlán"},{value: "Orizaba", label: "Orizaba"},{value: "Poza Rica", label: "Poza Rica"},{value: "Xalapa", label: "Xalapa"}]},
    {value: "Yucatán",label: "Yucatán",
      ciudades: [{value: "Mérida", label: "Mérida"}]},
    {value: "Zacatecas",label: "Zacatecas",
      ciudades: [{value: "Zacatecas", label: "Zacatecas"},{value: "Fresnillo", label: "Fresnillo"},{value: "Guadalupe", label: "Guadalupe"}]},
  ]
}
const POPULATE_STATE = 'populateState'
const CLEAR = 'clear'
const DATA = 'data'

const initialState = {
  disableCountry: false,
  disableState: true,
  loadingState: false,
  statesToBeLoaded: [],
  selectedState: ''
}

const reducer = (state, action) => {
  switch (action.type) {
    case POPULATE_STATE:
      return {
        ...state,
        disableCountry: true,
        disableState: false,
        loadingState: true,
        statesToBeLoaded: data.estados.find(
          country => country.value === action.country
        ).ciudades,
        selectedState: action.country
      }
    case CLEAR:
    default:
      return initialState
  }
}

function handleChange(e) {
      console.log(e)

}


function SelectState(props) {

  if (props.estado) {
      initialState.disableState = false;
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const estado = {value: props.estado, label: props.estado}
  const ciudad = {value: props.ciudad, label: props.ciudad}
  return (
        <div className="App">
          <p>Estado y Ciudad</p>

          <Select
            isDisabled={state.disableCountry}
            isLoading={state.loadingState}
            isClearable
            isSearchable
            required
            validate
            placeholder="Seleccionar Estado..."
            name="country"
            options={data.estados}
            value={estado}
            onChange={e => dispatch({ type: POPULATE_STATE, country: e.value })}
          >
            <div className="invalid-feedback">
              {i18n.t('proveeDatosValidos',{s: 'dato'})}
            </div>
            <div className="valid-feedback">{i18n.t('Correcto')}</div>
          </Select>
          <br />

          {!state.disableState && (
            <>
              <Select
                isDisabled={state.disableState}
                isLoading={false}
                isClearable
                isSearchable
                required
                validate
                placeholder="Seleccionar Ciudad..."
                name="state"
                options={state.statesToBeLoaded}
                onChange={(e) => props.onChange({city: e.value, state: state.selectedState })}
                value={ciudad}
              >
              <div className="invalid-feedback">
                 Seleccione una Ciudad
              </div>
              <div className="valid-feedback">{i18n.t('Correcto')}</div>
              </Select>

              <br />

              <button type="button" onClick={() => dispatch({ type: CLEAR })}>
                Limpiar Ciudad
              </button>
            </>
          )}
        </div>
      )
}
export default SelectState
