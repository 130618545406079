import React, { Component } from 'react';
import { MDBContainer, MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class ModalPage extends Component {
state = {
  modal: true,
  selected: ""
}

toggle = () => {
  this.setState({
    modal: false
  });
}
onClick = (e) => {
  this.setState({
    selected: e.target.id
  });
}

render() {
  return (
    <MDBContainer>
      <MDBModal isOpen={this.props.isOpen && this.state.modal} toggle={this.toggle}>
        <MDBModalHeader toggle={this.toggle}>Copiar Información</MDBModalHeader>
        <MDBModalBody>
          <p className="mb-3">Niveles</p>
          <hr />
            <div className="text-left">
            {
              this.props.nivel !== '1' ? (<MDBInput label="Maternal" type="radio" id="maternal" onClick={this.onClick} checked={this.state.selected === 'maternal'} />): (<div></div>)
            }
            {
              this.props.nivel !== '2' ? (<MDBInput label="Kinder" type="radio" id="kinder" onClick={this.onClick} checked={this.state.selected === 'kinder'} />): (<div></div>)
            }
            {
              this.props.nivel !== '3' ? (<MDBInput label="Primaria" type="radio" id="primaria" onClick={this.onClick} checked={this.state.selected === 'primaria'} />): (<div></div>)
            }
            {
              this.props.nivel !== '4' ? (<MDBInput label="Secundaria" type="radio" id="secundaria" onClick={this.onClick} checked={this.state.selected === 'secundaria'} />): (<div></div>)
            }
            {
              this.props.nivel !== '5' ? (<MDBInput label="Bachillerato" type="radio" id="bachillerato" onClick={this.onClick} checked={this.state.selected === 'bachillerato'} />): (<div></div>)
            }
            </div>
            <p className="mb-3">De cual se quiere copiar información?</p>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={this.toggle}>Cerrar</MDBBtn>
          <MDBBtn color="primary" disabled={!this.state.selected} onClick={this.props.close(this.state.selected)}>Copiar Datos</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
    );
  }
}

export default ModalPage;
