export const createOrden = (ordencompra) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const authorId = getState().firebase.auth.uid;
    firestore.collection('ordenes').add({
      ...ordencompra,
      authorId: authorId,
      createdAt: new Date()
    }).then((res) => {
      const data = {id: res.id}
      console.log(data);
      dispatch({ type: 'CREATE_PROJECT_SUCCESS', data});
    }).catch(err => {
      console.log(err)
      dispatch({ type: 'CREATE_PROJECT_ERROR' }, err);
    });
  }
};

export const getOrdenes = () => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const authorId = getState().firebase.auth.uid;
    firestore.collection('ordenes').where("authorId", "==", authorId)
    .get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

  }
}
