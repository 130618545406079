import React, { Component } from "react";
import MDBWysiwyg from 'mdb-react-wysiwyg';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn
} from "mdbreact";
// const initialContent =
//     `<br>
//     <h1 style="text-align: center;">Misión, Visión e Historia</h1>
//     <p style="text-align: center;">WYSIWYG Editor</p>
//     <p style="text-align: center;"><a href="https://mdbootstrap.com" target="_blank" contenteditable="false" style="font-size: 1rem; text-align: left;">MDBootstrap.com</a>&nbsp;© 2018</p>
//     <p><b>Features:</b></p>
//     <ul>
//     <li>Changing block type</li>
//     <li>Text formatting (bold, italic, strikethrough, underline)</li>
//     <li>Setting text color</li>
//     <li>Text aligning</li>
//     <li>Inserting links</li>
//     <li>Inserting pictures</li>
//     <li>Creating a list (bulled or numbered)</li>
//     </ul>
//     <p><b>Options:</b></p>
//     <ul>
//     <li>Translations</li>
//     <li>Using your own color palette</li>
//     <li>Disabling/enabling tooltips</li>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</ul>
//     `;
class CreaText extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  state = {
    editor: "<p>Hello</p>"
  }

  handleText = (e) => {
      console.log(this.textInput.current);
      console.log(this.textInput.current.props.initialContent);
      this.textInput.current.changeViewHandler();
      this.setState({editor: this.textInput.current.props.initialContent});
      console.log(this.state.editor)
      var myInnerHtml = document.getElementById("mywysiwyg").innerHTML;
      console.log(myInnerHtml)
      var miData = document.querySelector(".mdb-wysiwyg-textarea").innerHTML;
      console.log(miData);
  }

  render() {
    return (
      <MDBContainer className="mt-2">
        <MDBRow>
          <p className="h6 text-center mb-12">Information</p>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12">
              <MDBBtn color="primary" onClick={this.handleText}>Save</MDBBtn>
              <div id="mywysiwyg">
                <MDBWysiwyg initialContent={this.state.editor} ref={this.textInput} value={this.state.editor}  />
              </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}
export default CreaText
