const getData = (state = {}, action) => {

	const { data } = action

	switch(action.type){
		case 'GET_CONFIG':
			return {
				...state,
				data
			}
		case 'GET_REGISTRY':
			return {
				...state,
				data
			}
		default:
			return state
	}
}

export default getData
