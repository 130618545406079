import React, { Component } from "react";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";

class ImagePage extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    //username: "",
    description: this.props.desc,
    image: "",
    isUploading: false,
    progress: 0,
    imageURL: "",
    refURL: null,
    updatedURL: [],
  };
  handleChangeDesc = event => {
    this.setState({ description: event.target.value});
    let testDesc = {
      [this.props.id]:  event.target.value,
      key: this.props.id
    }
    this.props.handleChangeDesc(testDesc);
  }
  //handleChangeUsername = event =>
  //  this.setState({ username: event.target.value });
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = filename => {
    this.setState({ image: filename, progress: 100, isUploading: false });
    console.log(filename);
    console.log(this.props.auth)
    firebase
      .storage()
      .ref("images")
      .child(this.props.auth)
      .child(filename)
      .getDownloadURL()
      .then(
        url => {
          console.log(url);
          let testURL = {
            [this.props.id]: url
          }
          this.state.refURL = {
            ...this.state.refURL,
            [this.props.id]: testURL,
            key: this.props.id
          }
          this.state.updatedURL.push(testURL)
          this.setState({ imageURL: url, updatedURL: this.state.updatedURL});
          this.props.handleChangeFile(this.state.refURL);
        }
      );
  };

  render() {
    const { auth, authError } = this.props;
    console.log(this.props.edit);
    if (auth.uid) console.log(auth.uid);
    return (
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{this.props.texto}</h5>
          <div>
            <form>
            {this.props.edit && (<div>
              <img alt='' className="img-fluid" src={this.props.url} />
            </div>)}
            <div class="row">
              <label>Descripción:</label>
              <input
                type="text"
                value={this.state.description}
                name="description"
                onChange={this.handleChangeDesc}
              />
              </div>
              <div class="row">
              {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
              {this.state.imageURL && !this.props.edit && <img alt="" src={this.state.imageURL} />}
              </div>
              <div class="row">
              <FileUploader
                accept="image/*"
                name="image"
                //filename={file => this.state.username + '.' + file.name.split('.')[1]}
                randomizeFilename
                storageRef={firebase.storage().ref("images").child(this.props.auth)}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ImagePage;
