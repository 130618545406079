import React from "react"
import { connect } from 'react-redux'
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker  } from "react-google-maps"
import { listSchools, listadoEscuelas } from '../../store/actions/schoolAction'
const { DrawingManager } = require("react-google-maps/lib/components/drawing/DrawingManager");
const MyMapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyA6zdmMWJ8K8AITyiy708xnP-65od7XSRc&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    defaultZoom={10}
    defaultCenter={{ lat: props.currentLocation.lat, lng: props.currentLocation.lng }}
  >
  {props.isMarkerShown && <Marker position={{ lat: props.currentLocation.lat, lng: props.currentLocation.lng }} onClick={props.onMarkerClick} />}

  <DrawingManager
      defaultDrawingMode={"circle"}
      defaultOptions={{
        drawingControl: true,
        drawingControlOptions: {
          position: 2,
          drawingModes: [
            "circle",
          ],
        },
        circleOptions: {
          fillColor: `#ffcc00`,
          fillOpacity: 0.4,
          strokeWeight: 5,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      }}
      onOverlayComplete={props.handleChangeMap}
      onCenterChanged={props.handleChangeMap}
      onRadiusChanged={props.handleChangeMap}
    />
  </GoogleMap>
);

class MyFancyComponent extends React.PureComponent {
  constructor(props){
      super(props)
      this.state = {
        currentLatLng: {
          lat: 25.33,
          lng: -100.9967
        },
        isMarkerShown: false
      }
  }
  componentWillUpdate(){
     this.getGeoLocation()
  }
  componentDidMount() {
    this.delayedShowMarker()
  }
  componentWillReceiveProps(next_props) {
      this.setState({
        all: next_props.data.hits,
        data: next_props.data.hits,
      });
  }
  delayedShowMarker = () => {
    setTimeout(() => {
      this.getGeoLocation();
      this.setState({ isMarkerShown: true })
    }, 3000)
  }
  handleMarkerClick = () => {
    this.setState({ isMarkerShown: false })
    this.delayedShowMarker()
  }
  getGeoLocation = () => {
          if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                  position => {
                      console.log(position.coords);
                      this.setState(prevState => ({
                          currentLatLng: {
                              ...prevState.currentLatLng,
                              lat: position.coords.latitude,
                              lng: position.coords.longitude
                          }
                      }))
                  }
              )
          } else {
              //error => {console.log(error)}
          }
  }
  handleOverlayComplete(e){
      console.log(e);
      console.log(e.overlay.radius);
      console.log(e.overlay.center);
      this.setDrawingMode(null);
      this.setOptions({drawingControlOptions: {drawingModes: [] }});
      //this.props.handleChangeMap({cicle: {center: e.overlay.center, radius: e.overlay.radius}})
  };
  render() {
    console.log(this.props);
    if (this.props.data) {
      return  (
        <MyMapComponent
          isMarkerShown={this.state.isMarkerShown}
          onMarkerClick={this.handleMarkerClick}
          onOverlayComplete={this.handleOverlayComplete}
          onRadiusChanged={this.onRadiusChanged}
          onCenterChanged={this.onCenterChanged}
          handleChangeMap={this.props.handleChangeMap}
          currentLocation={this.state.currentLatLng}
          data={this.props.data}
        />
      )
    }else {
      return (<div>loading</div>)
    }
  }
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    query: state.query,
    data: state.elasticsearch.escuelas
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    listSchools: (query) => {dispatch(listSchools(query))}
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(MyFancyComponent);
