import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions'

// Replace this with your own config details
var config = {
  apiKey: "AIzaSyDSunMy5KU04auJ3VoNjSgqar7hoOkvyzA",
  authDomain: "eduk2-8f3d4.firebaseapp.com",
  databaseURL: "https://eduk2-8f3d4.firebaseio.com",
  projectId: "eduk2-8f3d4",
  storageBucket: "eduk2-8f3d4.appspot.com",
  messagingSenderId: "224143167772"
};
firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });

const storage = firebase.storage().ref();
const functions = firebase.functions();
export {
    functions, storage, firebase as default
}
