import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { signUp } from '../../store/actions/authActions'
import { getData } from '../../store/actions/paypalActions'
import WelcomePage from './Welcome'
import ImagePage from '../ordenes/ImageFile';
import SelectState from './SelectStatesCity'
import i18n from 'i18next';
import StickyVideo from 'react-sticky-video';
import 'react-sticky-video/dist/index.css';

import {
  MDBContainer,
  MDBSticky, MDBStickyContent,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBIframe,
  MDBCollapse,
} from "mdbreact";
import InvoiceService from '../../config/services/api/invoice';
import elasticsearch from 'elasticsearch';

const client = new elasticsearch.Client({
    //node: 'https://9f71b0ac6c4d4aa0a16542713c017c1b.us-central1.gcp.cloud.es.io:9243',
  //host: 'http://localhost:9200'
    //host: 'https://3slfiesotk:hs7hpzxkb2@eduk2-4709248868.us-west-2.bonsaisearch.net:443'
    host: 'https://4xgg7rftje:q0vesrnzkv@eduk2-3683615528.us-east-1.bonsaisearch.net:443'
    // If you have set username and password
});

const edades = {
  maternal: [1,2,3,4],
  kinder: [3,4,5,6,7],
  primaria: [6,7,8,9,10,11,12,13],
  secundaria: [12,13,14,15,16],
  bachillerato: [15,16,17,18,19],
  academia: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]
}

class SignUpFree extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    email: '',
    confirmemail: '',
    password: '',
    confirmpassword: '',
    name: '',
    directorName: '',
    admisionesPhone: '',
    invalidCheck: false,
    street: '',
    colonia: '',
    noExt: '',
    noInt: '',
    city: '',
    state: '',
    zip: '',
    url: '',
    CCT: '',
    lema: '',
    niveles: [],
    paquete: '',
    nMaternal: false,
    nKinder: false,
    nPrimaria: false,
    nSecundaria: false,
    nBachillerato: false,
    aCultural: false,
    aDeportiva: false,
    aTecnologica: false,
    aOtra: false,
    toPay: false,
    goInvoice: false,
    href: '',
    phone: '',
    whatsapp: '',
    uploadImages: {
      foto_num1: {desc: '', url: ''},
      foto_num2: {desc: '', url: ''}
    },
    collapseID: "basicCollapse",
    showVideo: false,
    authorId: '',
  }
  componentDidMount(){
    console.log('componentDidMount')
    const { auth } = this.props;
    this.setState({
      authorId: auth.uid
    })
    this.props.getData(auth.uid);
  }
  componentDidUpdate(prevProps) {
    console.log(prevProps);
	  // Typical usage (don't forget to compare props):
	  if (this.props.getData !== prevProps.getData) {
      if (prevProps.getData)
	    {
        const data = this.props.getData.data
        console.log(data);
      }
    }
  }
  toggleVideo = () => {
    this.setState({
      showVideo: !this.state.showVideo
    })
  }
  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }
  handleSelectCity = (e) =>{
    console.log(e);
    this.setState({
      city: e.city,
      state: e.state
    })
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleChangeCheckbox = (e) => {
    this.setState({
      [e.target.id]: e.target.checked
    })
  }
  handleInvoice = (data) => {
    console.log(data);
    InvoiceService.getNumber(data).then((response) => {
      console.log(response);
      const number = response.data.invoice_number;
      const sendBody= {
        "send_to_invoicer": true
      }
      var body = data.content;
      body.detail.invoice_number = number;
      InvoiceService.generateInvoice(data, body).then((response) => {
        console.log(response);
        const invoiceId = response.data.href.split("/").pop();
        console.log(invoiceId);
        InvoiceService.sendInvoice(data, sendBody, invoiceId).then((response) => {
          console.log(response);
          this.setState(() => ({
            goInvoice: true,
            href: response.data.href
          }))
        })
      })

      alert(number);
    })
  }
  handleChangeDesc = (e) => {
    console.log('handleChangeDesc')
    console.log(e)
    Object.keys(this.state.uploadImages).map(key => {
      const refKey = Object.keys(e[key]||{}).map(key => {
        return key
      })
      if(refKey.length!==0){
        console.log(refKey)
        this.setState((prevState)=>({uploadImages: {
          ...prevState.uploadImages,
          [e.key]: {
            ...prevState.uploadImages[e.key],
            desc: e[e.key]
          }
        }}));
        } else {
            console.log(e.key)
            this.setState((prevState)=>({uploadImages: {
              ...prevState.uploadImages,
              [e.key]: {
                ...prevState.uploadImages[e.key],
                desc: e[e.key]
              }
            }}));
        }
    })
  }
  handleChangeFile = (e) => {
    console.log('handleChangeFile')
    console.log(e)
    if (e) {
      Object.keys(this.state.uploadImages).map(key => {
        const refKey = Object.keys(e[key]||{}).map(key => {
          return key
        })
        if(refKey.length!==0){
          console.log(refKey)
          this.setState((prevState)=>({uploadImages: {
            ...prevState.uploadImages,
            [e.key]: {
              ...prevState.uploadImages[e.key],
              url: e[e.key][e.key]
            }
          }}));
        }
        else {
          console.log(e.key)
          this.setState((prevState)=>({uploadImages: {
            ...prevState.uploadImages,
            [e.key]: {
              ...prevState.uploadImages[e.key],
              url: e[e.key][e.key]
            }
          }}));
        }
      })

    }
  }
  publish = (orden) => {
    console.log("handleSubmit");
    let niveles = orden["niveles"]
    niveles.map(key => {
      let escuela = {
        nivel: key,
        paquete: orden["paquete"],
        edades: edades[`${key}`],
        admisionesEmail: orden["admisionesEmail"],
        admisionesPhone: orden["admisionesPhone"],
        authorId: orden["authorId"],
        city: orden["city"],
        colonia: orden["colonia"],
        comercialName: orden["comercialName"],
        condicionesPago: orden["condicionesPago"],
        createdAt: orden["createdAt"],
        directorName: orden["directorName"],
        directorEmail: orden["directorEmail"],
        directorPhone: orden["directorPhone"],
        enlaceEmail: orden["enlaceEmail"],
        enlacePhone: orden["enlacePhone"],
        entreCalles: orden["entreCalles"],
        historia: orden["historia"],
        municipio: orden["municipio"],
        lema: orden["lema"],
        location: orden["location"],
        nombreFiscal: orden["nombreFiscal"],
        numExt: orden["numExt"],
        numInt: orden["numInt"],
        oficialName: orden["oficialName"],
        phone: orden["phone"],
        recepcionEmail: orden["recepcionEmail"],
        recepcionPhone: orden["recepcionPhone"],
        representanteLegal: orden["representanteLegal"],
        rfc: orden["rfc"],
        rsFacebook: orden["rsFacebook"],
        rsGoogle: orden["rsGoogle"],
        rsInstagram: orden["rsInstagram"],
        rsLinkedIn: orden["rsLinkedIn"],
        rsTwitter: orden["rsTwitter"],
        rsYouTube: orden["rsYouTube"],
        state: orden["state"],
        street: orden["street"],
        uploadImages: orden["uploadImages"],
        url: orden["url"],
        url_entrevista_dir: '',
        url_articulos_publicados: '',
        url_reportajes_y_mas: '',
        video1: '',
        video2: '',
        video3: '',
        video_institucional: '',
        webpage: '',
        zip: orden["zip"],
        encuestaSatisfaccion: orden[`${key}_encuestaSatisfaccion`],
        metodologia_laica: false,
        methodology: [],
        idioma_horas: 0,
        idioma_mcer: '',
        idioma_modelo: '',
        montoAnualizado: 0,
        capacidad_alumnos_por_aula: 0,
        promedio_alumnos_por_aula: 0,
        total_alumnos_actual: 0,
        total_alumnos_capacidad: 0,
        redEscuelas: false,
        academica: [],
        academicos: [],
        adicional: [],
        administracion: [],
        alianzas: [],
        canchas: [],
        comunicacion: [],
        cultural: [],
        deportivas: [],
        elementos_clase: [],
        instalaciones: [],
        laboratorios: [],
        seguridad: [],
        tecnologicas: [],
        idiomas: []
      }
      console.log(escuela);
      setTimeout(function () {
        client.index({
            index: "educa2",
            type: "_doc",
            refresh: true,
            body: escuela
        }).then(function (resp) {
            console.log(resp);
        }, function (err) {
            console.log(err.message);
        });
      }, 100);
      return escuela
    })
  }
  handleSubmit = (e) => {
    console.log(e);
    e.preventDefault();
    e.target.className += " was-validated";
    let niveles = [];
    let isLevel = false;
    if (this.state.nMaternal) {
      niveles.push("maternal")
      isLevel=true;
    }
    if (this.state.nKinder) {
      niveles.push("kinder")
      isLevel=true;
    }
    if (this.state.nPrimaria) {
      niveles.push("primaria")
      isLevel=true;
    }
    if (this.state.nSecundaria) {
      niveles.push("secundaria")
      isLevel=true;
    }
    if (this.state.nBachillerato) {
      niveles.push("bachillerato")
      isLevel=true;
    }
    if (this.state.aCultural || this.state.aDeportiva || this.state.aTecnologica || this.state.aOtra) {
      niveles = [];
      niveles.push("academia")
      isLevel=true;
    }
    if (!isLevel) {
      alert("al menos un nivel Educativo");
    } else if (!this.state.invalidCheck) {
      alert("Debe aceptar el aviso de privacidad");
    } else {
      this.state["niveles"] = niveles;
      let {paquete} = this.props.match.params
      console.log(paquete);
      if (paquete === 'base' ||
          paquete === 'despegue' ||
          paquete === 'plataforma' ||
          paquete === 'descubre' ||
          paquete === 'explora') {
            this.state["paquete"] = paquete;
      }

      this.props.signUp(this.state);
      this.publish(this.state);
      this.setState(() => ({
        toPay: true,
        paquete: paquete,
        niveles: niveles
      }))
    }

  }

  render() {
    const {auth, authError, dataError, data } = this.props;
    const precios = {
      "base": 0.00,
      "plataforma": 950.00,
      "despegue": 1250.00,
      "explora": 1550.00,
      "descubre": 1950.00,
      "nivelAdicional": 200.00
    }
    const imagenes = {
      "base": "Logo e imagen institucional",
      "plataforma": "Logo e imagen institucional",
      "despegue": "8 Imágenes",
      "explora": "20 Imágenes",
      "descubre": "20 Imágenes"
    }
    let {paquete} = this.props.match.params
    console.log(data);
    var user = auth.uid;
    if (user != null) {
      if (this.state.toPay === true) {
        return <WelcomePage />
      } else {
        if (this.state.goInvoice && this.state.href) {
          window.open(this.state.href);
          return (
            <Redirect to='/orden' />);
            //<MDBContainer className="text-center">
            //  <MDBIframe src={this.state.href} />
            //</MDBContainer>); //render function should return something
        } else {
        return (
          <section className="text-center my-5">
      <h2 className="h1-responsive font-weight-bold text-center my-5">
        Nuestro plan de pagos
      </h2>
      <p className="grey-text text-center w-responsive mx-auto mb-5">
        Se tiene una cuota de registro que equivale al pago mensual incluyendo un nivel seleccionado.
      </p>
      <MDBRow>
        <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
        </MDBCol>
        <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
          <MDBCard pricing>
            <div className="price header white-text indigo rounded-top">
              <h2 className="number">{precios[paquete]}</h2>
              <div className="version">
                <h5 className="mb-0">Paquete {paquete}</h5>
              </div>
            </div>
            <MDBCardBody className="striped mb-1">
              <ul>
                <li>
                  <p className="mt-2">
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Información comercial
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />1 Ubicación por filtros
                    {}
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Datos Analíticos
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    {imagenes[paquete]}
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Captura backend{" "}
                  </p>
                </li>
              </ul>
              {(this.props.data) ? (<MDBBtn color="indigo" onClick={() => {this.handleInvoice(data.data)}}>Pagar ahora</MDBBtn>): null}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </section>
        )
      }
      }
    }

    //if (auth.uid) return <Redirect to='/' />
    const gratis = paquete === 'base' ? 'Gratis' : '';
    return (
    <MDBContainer className="mt-5">


    <p className="h1 text-center mb-12">{i18n.t('Preregistro', {s: gratis})}</p>
    <div class="d-flex justify-content-end">
      <MDBBtn
         class="btn btn-outline-primary waves-effect"
         color="primary"
         onClick={this.toggleVideo}
         style={{ marginBottom: "1rem" }}
       >
       {!this.state.showVideo ? 'Ver tutorial': 'Ocultar Tutorial'}
       </MDBBtn>
     </div>
    {this.state.showVideo && (
    <div className="embed-responsive">
    <StickyVideo
      url="https://www.youtube.com/watch?v=4wL3Pcx_cto"
      stickyConfig={{
        width: 320,
        height: 180,
        position: 'bottom-right'
      }}
    />
    </div>)}
    <MDBRow>
    <MDBCol lg="12">
    <form className="needs-validation" onSubmit={this.handleSubmit.bind(this)} noValidate>
      <MDBRow className="mt-3">

         <MDBCol md="6">
           <p className="h5 text-center mb-4">{i18n.t('datosContacto')}</p>
           <div className="grey-text">

             <MDBInput
               label={i18n.t('nombre')}
               icon="user"
               group
               type="text"
               required
               validate
               error="wrong"
               success="right"
               id="name"
               name="name"
               value={this.state.name}
               onChange={this.handleChange}
             >
              <div className="invalid-feedback">
                 {i18n.t('proveeDatosValidos',{s: '$t(nombre)'})}
              </div>
              <div className="valid-feedback">{i18n.t('Correcto')}</div>
            </MDBInput>
             <MDBInput
               label={i18n.t('correo')}
               icon="envelope"
               required
               group
               type="email"
               validate
               error="wrong"
               success="right"
               id="email"
               onChange={this.handleChange}
              >
               <div className="invalid-feedback">
                  {i18n.t('proveeDatosValidos',{s: '$t(correo)'})}
               </div>
               <div className="valid-feedback">{i18n.t('Correcto')}</div>
             </MDBInput>

             <MDBInput
               id="confirmemail"
               label={i18n.t('confirmaCorreo')}
               icon="exclamation-triangle"
               group
               type="email"
               validate
               error="wrong"
               success="right"
               onChange={this.handleChange}
               className={this.state.email === this.state.confirmemail ? "is-valid" : "is-invalid"}
               >
                <div className="invalid-feedback">
                   {this.state.email !== this.state.confirmemail ? 'debe coincidir el correo' : i18n.t('confirmaDatos',{s: '$t(correo)'})}
                </div>
                <div className="valid-feedback">{i18n.t('Correcto')}</div>
              </MDBInput>
             <MDBInput
               label={i18n.t('Password')}
               icon="lock"
               group
               type="password"
               validate
               required
               id="password"
               onChange={this.handleChange}
             />
             <MDBInput
               label={i18n.t('confirmpassword')}
               icon="exclamation-triangle"
               group
               type="password"
               validate
               required
               id="confirmpassword"
               onChange={this.handleChange}
               error="wrong"
               success="right"
               className={!!this.state.confirmpassword && this.state.password ===this.state.confirmpassword ? "is-valid" : "is-invalid"}
             >
                <div className="invalid-feedback">
                  {i18n.t('proveeDatosValidos',{s: '$t(password)'})}
                </div>
                <div className="valid-feedback">{i18n.t('Correcto')}</div>
             </MDBInput>

             <MDBRow className="mt-5">
                <MDBCol md="8">
                   <MDBInput
                     label={i18n.t('telefono')}
                     icon="phone"
                     group
                     type="tel"
                     validate
                     pattern="[0-9]{10}"
                     maxlength="10"
                     id="phone"
                     onChange={this.handleChange}
                     required
                   >
                   <div className="invalid-feedback">
                      {i18n.t('confirmaDatos',{s: '$t(telefono)'})}
                      {this.state.phone.length != 10 ? ' Deben ser 10 dígitos' : '' }
                   </div>
                   <div className="valid-feedback">{i18n.t('Correcto')}</div>
                 </MDBInput>
                </MDBCol>
                <MDBCol md="4">
                    <MDBInput
                      label={i18n.t('extension')}
                      icon="star"
                      group
                      type="number"
                      validate
                      id="ext"
                      onChange={this.handleChange}
                    />

                </MDBCol>

             </MDBRow>
             <MDBInput
               label={i18n.t('directorName')}
               group
               type="text"
               validate
               id="directorName"
               onChange={this.handleChange}
               required
             >
               <div className="invalid-feedback">
                  {i18n.t('proveeDatosValidos',{s: '$t(directorName)'})}
               </div>
               <div className="valid-feedback">{i18n.t('Correcto')}</div>
             </MDBInput>
             <MDBInput
               label={i18n.t('whatsapp')}
               icon="whatsapp"
               group
               type="tel"
               pattern="[0-9]{10}"
               maxlength="10"
               validates
               id="whatsapp"
               onChange={this.handleChange}
               required
             >
               {this.state.error && <p className="error">{this.state.error}</p>}
               <div className="invalid-feedback">
                  {i18n.t('proveeDatosValidos',{s: '$t(telefono)'})}
                  {this.state.whatsapp.length != 10 ? ' Deben ser 10 dígitos' : '' }
               </div>
               <div className="valid-feedback">{i18n.t('Correcto')}</div>
             </MDBInput>

             <MDBInput
               label={i18n.t('admisionesPhone')}
               icon="phone"
               group
               type="tel"
               validate
               pattern="[0-9]{10}"
               maxlength="10"
               id="admisionesPhone"
               onChange={this.handleChange}
               required
             >
             <div className="invalid-feedback">
                {i18n.t('confirmaDatos',{s: '$t(admisionesPhone)'})}
                {this.state.admisionesPhone.length != 10 ? ' Deben ser 10 dígitos' : '' }
             </div>
             <div className="valid-feedback">{i18n.t('Correcto')}</div>
           </MDBInput>
             </div>
             </MDBCol>
             <MDBCol md="6">
               <p className="h5 text-center mb-4">{i18n.t('datosEscuela')}</p>
               <div className="grey-text">
                 <MDBInput
                  label={i18n.t('cct')}
                  icon="key"
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  id="CCT"
                  value={this.state.CCT}
                  onChange={this.handleChange}
                 />
                 <MDBInput
                   label={i18n.t('comercialName')}
                   icon="home"
                   group
                   type="text"
                   validate
                   required
                   error="wrong"
                   success="right"
                   id="comercialName"
                   onChange={this.handleChange}
                 />
                 <MDBInput
                   validate
                   value={this.state.lema}
                   onChange={this.handleChange}
                   type="text"
                   id="lema"
                   name="lema"
                   label={i18n.t('lema')}
                   required
                 >
                   <div className="invalid-feedback">
                     {i18n.t('proveeDatosValidos',{s: 'dato'})}
                   </div>
                   <div className="valid-feedback">{i18n.t('Correcto')}</div>
                 </MDBInput>
                 <MDBInput
                   validate
                   value={this.state.street}
                   onChange={this.handleChange}
                   type="text"
                   id="street"
                   name="street"
                   label={i18n.t('street')}
                   required
                 >
                   <div className="invalid-feedback">
                     {i18n.t('proveeDatosValidos',{s: 'dato'})}
                   </div>
                   <div className="valid-feedback">{i18n.t('Correcto')}</div>
                 </MDBInput>
                 <MDBRow >
                   <MDBInput
                     validate
                     value={this.state.noExt}
                     onChange={this.handleChange}
                     type="text"
                     id="noExt"
                     name="noExt"
                     label={i18n.t('noExt')}
                     className="mr-1 col-md-6"
                     required
                   >
                     <div className="invalid-feedback">
                       {i18n.t('proveeDatosValidos',{s: 'dato'})}
                     </div>
                     <div className="valid-feedback">{i18n.t('Correcto')}</div>
                   </MDBInput>
                   <MDBInput
                     validate
                     value={this.state.noInt}
                     onChange={this.handleChange}
                     type="text"
                     id="noInt"
                     name="noInt"
                     label={i18n.t('noInt')}
                     className="mr-1 col-md-6"
                   >
                     <div className="invalid-feedback">
                       {i18n.t('proveeDatosValidos',{s: 'dato'})}
                     </div>
                     <div className="valid-feedback">{i18n.t('Correcto')}</div>
                   </MDBInput>
                 </MDBRow>
                 <MDBInput
                   validate
                   value={this.state.colonia}
                   onChange={this.handleChange}
                   type="text"
                   id="colonia"
                   name="colonia"
                   label={i18n.t('colonia')}
                   required
                 >
                   <div className="invalid-feedback">
                     {i18n.t('proveeDatosValidos',{s: 'dato'})}
                   </div>
                   <div className="valid-feedback">{i18n.t('Correcto')}</div>
                 </MDBInput>

                 <MDBInput
                   value={this.state.zip}
                   onChange={this.handleChange}
                   type="text"
                   id="zip"
                   name="zip"
                   label={i18n.t('zip')}
                   required
                 >
                 </MDBInput>
                 <SelectState onChange={this.handleSelectCity} onChangeState={this.handleSelectState} />
                 <br />
                 <ImagePage id='foto_num1' key='foto_num1' texto='Escudo' handleChangeFile={this.handleChangeFile} handleChangeDesc={this.handleChangeDesc} auth='gratis'/>
                 <br />
                 <ImagePage id='foto_num2' key='foto_num2' texto='Foto Institucional' handleChangeFile={this.handleChangeFile} handleChangeDesc={this.handleChangeDesc} auth='gratis'/>
                 <br />
                 <MDBBtn
                    color="primary"
                    onClick={this.toggleCollapse("basicCollapse")}
                    style={{ marginBottom: "1rem" }}
                  >
                  Escuela
                  </MDBBtn>
                  <MDBBtn
                   color="primary"
                   onClick={this.toggleCollapse("academiasCollapse")}
                   style={{ marginBottom: "1rem" }}
                   >
                    Academias
                  </MDBBtn>
                 <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
                 <div class="input-group is-invalid">

                   <MDBInput label={i18n.t('maternal')}
                      name="a"
                      filled
                      type="checkbox"
                      id="nMaternal"
                      onChange={this.handleChangeCheckbox}
                      checked={this.state.nMaternal}
                      validate
                      className={this.state.nMaternal||this.state.nKinder||this.state.nPrimaria||this.state.nSecundaria||this.state.nBachillerato ? "is-valid" : "is-invalid"}
                       >
                   </MDBInput>
                   <MDBInput name="a" label={i18n.t('preescolar')} filled type="checkbox" id="nKinder"
                    onChange={this.handleChangeCheckbox} checked={this.state.nKinder}
                    className={this.state.nMaternal||this.state.nKinder||this.state.nPrimaria||this.state.nSecundaria||this.state.nBachillerato ? "is-valid" : "is-invalid"}  />
                   <MDBInput name="a" label={i18n.t('primaria')} filled type="checkbox" id="nPrimaria"
                   className={this.state.nMaternal||this.state.nKinder||this.state.nPrimaria||this.state.nSecundaria||this.state.nBachillerato ? "is-valid" : "is-invalid"}
                   onChange={this.handleChangeCheckbox} checked={this.state.nPrimaria} />
                   <MDBInput name="a" label={i18n.t('secundaria')} filled type="checkbox" id="nSecundaria"
                   className={this.state.nMaternal||this.state.nKinder||this.state.nPrimaria||this.state.nSecundaria||this.state.nBachillerato ? "is-valid" : "is-invalid"}
                   onChange={this.handleChangeCheckbox} checked={this.state.nSecundaria} />
                   <MDBInput name="a" label={i18n.t('bachillerato')} filled type="checkbox"
                    id="nBachillerato"
                    onChange={this.handleChangeCheckbox}
                    className={this.state.nMaternal||this.state.nKinder||this.state.nPrimaria||this.state.nSecundaria||this.state.nBachillerato ? "is-valid" : "is-invalid"}
                    checked={this.state.nBachillerato} >
                   {!(this.state.nMaternal||this.state.nKinder||this.state.nPrimaria||this.state.nSecundaria||this.state.nBachillerato) ? (<div className="invalid-feedback">
                     {i18n.t('proveNivel')}
                   </div>):null}
                   </MDBInput>
                 </div>
                 </MDBCollapse>
                 <MDBCollapse id="academiasCollapse" isOpen={this.state.collapseID}>
                  <div class="input-group is-invalid">

                     <MDBInput label={i18n.t('academiaCultural')}
                        name="b"
                        filled
                        type="checkbox"
                        id="aCultural"
                        onChange={this.handleChangeCheckbox}
                        checked={this.state.aCultural}
                        validate
                        className={this.state.aCultural||this.state.aDeportiva||this.state.aTecnologica||this.state.aOtra ? "is-valid" : "is-invalid"}
                         >
                     </MDBInput>
                     <MDBInput name="b" label={i18n.t('academiaDeportiva')} filled type="checkbox" id="aDeportiva"
                      onChange={this.handleChangeCheckbox} checked={this.state.aDeportiva}
                      className={this.state.aCultural||this.state.aDeportiva||this.state.aTecnologica||this.state.aOtra ? "is-valid" : "is-invalid"}  />
                     <MDBInput name="b" label={i18n.t('academiaTecno')} filled type="checkbox" id="aTecnologica"
                      className={this.state.aCultural||this.state.aDeportiva||this.state.aTecnologica||this.state.aOtra ? "is-valid" : "is-invalid"}
                      onChange={this.handleChangeCheckbox} checked={this.state.aTecnologica} />
                      <MDBInput name="b" label={i18n.t('academiaOtra')} filled type="checkbox"
                       id="aOtra"
                       onChange={this.handleChangeCheckbox}
                       className={this.state.aCultural||this.state.aDeportiva||this.state.aTecnologica||this.state.aOtra ? "is-valid" : "is-invalid"}
                       checked={this.state.aOtra} >
                      {!(this.state.aCultural||this.state.aDeportiva||this.state.aTecnologica||this.state.aOtra) ? (<div className="invalid-feedback">
                        {i18n.t('proveAcademia')}
                      </div>):null}
                      </MDBInput>
                   </div>
                 </MDBCollapse>

              </div>
            </MDBCol>
            </MDBRow>
            <MDBRow>
             <MDBCol md="10">

             <MDBInput
                  label={<a href='https://b44e4152-aeb6-446b-9ff9-db89f0a2ad28.filesusr.com/ugd/850e72_7a5399b364184a9d8fe7fb21bcfa66f5.pdf' target='_blank'>Aviso Privacidad</a>}
                  type="checkbox"
                  group
                  validate
                  id="invalidCheck"
                  required
                  onChange={this.handleChangeCheckbox}
              >
              <div className="invalid-feedback">
                  Debe estar de acuerdo antes de aceptar.
              </div>
              <div className="valid-feedback">{i18n.t('Correcto')}</div>
              </MDBInput>

           <div className="text-center">
             <MDBBtn color="primary" type="submit">Registrar</MDBBtn>
             <div className="center red-text">
               { dataError ? dataError.map((x) => {return (<p>{x.message}</p>)}) : null }
               { authError ? (<p>{authError}</p>) : null }
             </div>
           </div>
           </MDBCol>
     </MDBRow>
     </form>
     </MDBCol>

     </MDBRow>
     </MDBContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    dataError: state.auth.dataError,
    data: state.getData,
  }
}

const mapDispatchToProps = (dispatch)=> {
  return {
    signUp: (creds) => dispatch(signUp(creds)),
    getData: (uid) => dispatch(getData(uid))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpFree)
