export default function(state = {}, action) {
    switch (action.type) {
        case 'ORDERS_STATUS':
            return { ...state, order: action.payload };
        case 'USER_STATUS':
            return { ...state, user: action.payload };
        default:
            return state;
    }
}
