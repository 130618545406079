export default {
  translations: {
    // Name of the pages
    escuelas: 'Escuelas',
    'Encuentra tu escuela': 'Encuentra tu escuela',
    Yes: 'Yes',
    No: 'No',
    Name: 'Name',
    Tags: 'Tags',
    Status: 'Status',
    Date: 'Date',
    Hour: 'Hour',
    Message: 'Message',
    Previous: 'Previous',
    Next: 'Next',
    Page: 'Page',
    of: 'of',
    Field: 'Field',
    Value: 'Value',
    Login: 'Login',
    Logout: 'Logout',
    Maintenance: 'Maintenance',
    Active: 'Active',
    Time: 'Time',
    Lost: 'Lost',
    lost: '$t(Lost)',
    Username: 'Usuario',
    Password: 'Contraseña',
    Preregistro: 'Registro {{s}}',
    datosContacto: 'Datos de Contacto',
    datosEscuela: 'Datos de la Escuela',
    proveeDatosValidos: 'Por favor provee un {{s}}  válido.',
    confirmaDatos: 'Por favor confirma tu {{s}}.',
    nombre: "Nombre",
    correo: "Correo",
    telefono: "Teléfono",
    extension: "Extensión",
    city: "Ciudad",
    state: "Estado",
    zip: "Código Postal",
    whatsapp: "Whatsapp",
    comercialName: "Nombre comercial",
    confirmaCorreo: "Confirma Correo",
    confirmpassword: "Confirma Contraseña",
    maternal: "Maternal",
    preescolar: "Preescolar",
    primaria: "Primaria",
    secundaria: "Secundaria",
    bachillerato: "Bachillerato",
    proveNivel: "Por favor selecciona al menos un nivel.",
    Role: 'Role',
    Options: 'Options',
    Modify: 'Modify',
    Cancel: 'Cancel',
    Apply: 'Apply',
    Save: 'Save',
    Create: 'Create',
    Ok: 'Ok',
    Frances: "French",
    Coreano: "Corean",
    "basquet": "Básquetbol",
    "volei": "Vóleibol",
    "futbol": "Futbol",
    "soccer": "Soccer",
    "tenis": "Tenis",
    "alberca": "Alberca",
    "arenero": "Arenero",
    "beisbol": "Béisbol",
    "areajuegos": "Área de Juegos",
    "otros": "Otros",
    "quimica": "Química",
    "cocina": "Cocina",
    "electricidad": "Electricidad",
    "dibujo": "Dibujo",
    "musica": "Música",
    "computacion": "Computación",
    "huertos": "Huertos",
    "biblioteca": "Biblioteca",
    "otras": "Otras",
    "oficina": "Oficinas",
    "comedor": "Comedor",
    "banios": "Baños",
    "vestidores": "Vestidores",
    "abiente_digital": "Ambiente digital",
    "auditorio": "Auditorio",
    "bodega": "Bodega",
    "jardines": "Jardines",
    "cajones": "Cajones",
    "basketbol": "Básquetbol",
    "atletismo": "Atletismo",
    "porristas": "Porristas",
    "natacion": "Natación",
    "tae_kwon_do": "Tae Kwon Do",
    "ajedrez": "Ajedrez",
    "oratoria": "Oratoria",
    "debate": "Debate",
    "pintura": "Pintura",
    "danza": "Danza",
    "teatro": "Teatro",
    "banda": "Banda",
    "matematicas": "Matemáticas",
    "entomologia": "Entomología",
    "botanica": "Botánica",
    "fisica": "Física",
    "literatura": "Literatura",
    "huerto": "Huerto",
    "robotica": "Robótica",
    "programacion": "Programación",
    "edicion": "Edición de foto o Video",
    "radio": "Rádio",
    "lms": "LMS",
    "apps": "App’s",
    "cert_ingles": "Certificación docente Inglés",
    "cert_metodo": "Certificación docente Metodológica",
    "cert_iso": "Certificación docente ISO",
    "plataforma": "Plataforma de pagos",
    "domiciliado": "Acepta Pago Domiciliado",
    "tdtc": "Acepta Pago TD/TC",
    "anual": "Acepta Pago Anual",
    "cheque": "Acepta Pago Cheque",
    "descuento": "Acepta Pago Descuento de nómina",
    "web": "Página Web",
    "facebook": "Facebook",
    "twiter": "Twiter",
    "whatsapp": "Whatsapp",
    "youtube": "Youtube",
    "webinarios": "Webinarios",
    "enfermera": "Enfermera",
    "psicologo": "Psicólogo",
    "guardia": "Guardia",
    "cctv": "CCTV",
    "primerrespondiente": "Certificación Primer Respondiente",
    "ambulancia": "Ambulancia",
    "transporte": "Transporte",
    "alimentacion": "Programa de alimentación",
    "menu": "Menú Nutritivo",
    "cafeteria": "Cafetería de padres",
    "constructivista":"Constructivista",
    "conductista": "Conductista",
    "metacademico": "Académico",
    "humanista": "Humanista",
    "tics": "TICs",
    "porproyectos": "Por Proyectos",
    "tradicional": "Tradicional",
    "flipped": "Flipped Classroom",
    "designthinking": "Design Thinking",
    "ibo": "IBO",
    "tareas": "Tareas",
    "valores": "Clase de Valores",
    "religion": "Clase de Religión",
    "clasecomputacion": "Clase de Computación",
    "arte": "Clase de Arte",
    "sustentabilidad": "Clase de Sustentabilidad",
    "intelig_emocional": "Clase de Inteligencia Emocional",
    "Correcto": "Correcto",
    "resultadoEncuesta": "Resultado General de encuesta de satisfacción:",
    "encuestaSatisfaccion": "Encuesta Satisfacción",
    "iam": "Inversión Anual Mensualizada",
    "redEscuelas": "Red de Escuelas",
    "formacion": "Formación",
    "religiosa": "Religiosa",
    "laica": "Laica",
    "Modelo": "Modelo",
    "elementosMet": "Elementos Metodología",
    "Idiomas": "Idiomas",
    "numHoras": "Número de horas",
    "numHorasIngles": "$t(numHoras) en que los alumnos están expuestos al idioma inglés a la semana",
    "modeloIdioma": "Modelo apegado para la adquisición de la lengua inglesa",
    "perfil": "Perfil de egreso de nivel",
    "idiomasExtra": "Idiomas Extracurriculares",
    "bilingue": "Bilingüe",
    "bicultural": "Bicultural",
    "Infraestructura": "Infraestructura",
    "numTotalAlumnos": "Número total de alumnos que la escuela puede atender",
    "numActualAlumnos": "Número total de alumnos actuales",
    "numAlumnosAula": "Capacidad de alumnos por aula",
    "promAlumnoporAula": "Alumnos promedio por aula",
    "Extracurriculares": "Extracurriculares",
    "Descripcion": "Descripción",
    "Servicios": "Servicios",
    bienvenido: "Bienvenido",
    textoBienvenida: "Te damos una cordial bienvenida y agradecemos tu confianza",
    Seguridad: "Seguridad",
    pasos: "Siguientes pasos",
    textoSeguridad: "Conserva tu usuario y contraseña, serán necesarios para ingresar al portal eduk2.mx y capturar la información de tu escuela",
    textoPasos: "Recibiras un correo con las instrucciones de pago del paquete contratado y la liga para la captura de tu información",
    cct: "Clave de Centro de Trabajo (CCT)",
    lema: "Lema de la Escuela",
    street: "Calle",
    colonia: "Colonia",
    noExt: "Número Exterior",
    noInt: "Número Interior",
    academiaCultural: "Cultural",
    academiaDeportiva: "Deportiva",
    academiaTecno: "Tecnología",
    academiaOtra: "Otra",
    proveAcademia: "Por favor selecciona al menos un tipo de academia",
    admisionesPhone: "Teléfono Admisiones",
    directorName: "Nombre del director",
  }
};
