import React from "react";
import {
  MDBContainer
}  from "mdbreact";
class CancelPago extends React.Component {

  constructor(props) {
    super(props);
  }
  render() {
    console.log(this.props)
    localStorage.setItem('cancelPayment',this.props.match);
    return (
      <MDBContainer className="mt-5">
        <h2>Pago Cancelado</h2>
      </MDBContainer>
    )
  }
}
export default CancelPago;
