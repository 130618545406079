import React from 'react';
import Cards from 'react-credit-cards';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput} from 'mdbreact';
export default class PaymentForm extends React.Component {
  state = {
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  };

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  render() {
    return (
      <div id="PaymentForm">
        <Cards
          cvc={this.state.cvc}
          expiry={this.state.expiry}
          focused={this.state.focus}
          name={this.state.name}
          number={this.state.number}
        />
        <form>

          <MDBRow>
            <MDBCol md="6" className="mb-3">
              <label htmlFor="name">Nombre sobre la Tarjeta</label>
              <input type="text" className="form-control" id="name" name="name" required
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus} />
              <small className="text-muted">Nombre completo como aparece en la tarjeta</small>
              <div className="invalid-feedback">
                Nombre sobre la tarjeta es requerido
              </div>
            </MDBCol>
            <MDBCol md="6" className="mb-3">
              <label htmlFor="number">Número de Tarjeta</label>
              <input
                type="tel"
                name="number"
                placeholder="Número de Tarjeta"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
                required
              />
              <div className="invalid-feedback">
                Número de Tarjeta es requerido
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="3" className="mb-3">
              <label htmlFor="expiry">Expiration</label>
              <input type="text" className="form-control" id="expiry" name="expiry" required
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus} />
              <div className="invalid-feedback">
                Expira en
              </div>
            </MDBCol>
            <MDBCol md="3" className="mb-3">
              <label htmlFor="cvc">CVV</label>
              <input type="text" className="form-control" id="cvc" name="cvc" required  onChange={this.handleInputChange}
              onFocus={this.handleInputFocus} />
              <div className="invalid-feedback">
                Código de seguridad requerido
              </div>
            </MDBCol>
          </MDBRow>
        </form>
      </div>
    );
  }
}
