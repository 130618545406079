import Request from '../axios'

function getNumber(data){
  return Request(
    {
      url: 'v2/invoicing/generate-next-invoice-number',
      method: 'POST'
    },
    {data: data}
  );
}

function generateInvoice(config, body){
  return Request(
    {
      url: 'v2/invoicing/invoices',
      method: 'POST',
      data: body
    },
    {data: config}
  );
}

function sendInvoice(config, body, invoice) {
  return Request(
    {
      url: `v2/invoicing/invoices/${invoice}/send`,
      method: 'POST',
      data: body
    },
    {data: config}
  )
}
const InvoiceService = {
  getNumber, generateInvoice, sendInvoice
}
export default InvoiceService;
