export const createGoalAction = (goal) => {
	return (dipatch, getState, {getFirestore}) => {

		const firestore = getFirestore()
		firestore.collection('ordenes').add({
			...goal
		}).then(() => {
			dipatch({ type: 'CREATE_GOAL', goal })
		}).catch((err) => {
			dipatch({ type: 'CREATE_GOAL_ERROR', err })
		})
	}
}

export const deleteGoal = (id) => {
	return (dispatch, getState, { getFirestore }) => {

		const firestore = getFirestore()
		firestore.collection('ordenes').doc(id).delete();
	}
}

export const updateGoal = (updatedGoal, id) => {
	return (dispatch, getState, { getFirestore }) => {
		console.log(updatedGoal)
		console.log(id)
		delete updatedGoal['formActivePanel3Changed']
		delete updatedGoal['formActivePanel3']
		delete updatedGoal['formActivePanel1Changed']
		delete updatedGoal['isEdit']
		//delete updatedGoal['infraestructura']
		//delete updatedGoal['extracur']
		//delete updatedGoal['servicios']
		delete updatedGoal['invalidCheck']
		delete updatedGoal['activeItem']
		delete updatedGoal['imagenes']
		delete updatedGoal['isModalOpen']
		delete updatedGoal['isEditorOpen']
		delete updatedGoal['isCreated']
		//delete updatedGoal['maternal_idiomas']
		//delete updatedGoal['kinder_idiomas']
		//delete updatedGoal['primaria_idiomas']
		//delete updatedGoal['secundaria_idiomas']
		//delete updatedGoal['bachillerato_idiomas']
		const data = JSON.stringify({...updatedGoal})
		console.log(data)
		const firestore = getFirestore()
		firestore.collection('ordenes').doc(id).update({
			...updatedGoal
		});
	}
}

export const getAGoal = (goalID) => {
	return (dispatch, getState, { getFirestore }) => {
		console.log('ordenes')
		const firestore = getFirestore()
		firestore.collection('ordenes').doc(goalID).get().then((doc) => {
			if(doc.exists){
				const data = doc.data()
				dispatch({ type: 'GET_GOAL', data })
			}else{
				console.log('does not exist')
			}

		})
	}
}

export const getAUser = (userID) => {
	return (dispatch, getState, { getFirestore }) => {
		console.log('users')
		const firestore = getFirestore()
		firestore.collection('users').doc(userID).get().then((doc) => {
			if(doc.exists){
				const userData = doc.data()
				dispatch({ type: 'GET_USER', userData })
			}else{
				console.log('does not exist')
			}

		})
	}
}
