import { init as initApm } from '@elastic/apm-rum'
const apm = initApm({

  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: 'eduk2',
  serverUrl: 'http://13.58.236.83:8200',
  distributedTracingOrigins: ['http://localhost:3000'],
  // Set custom APM Server URL (default: http://localhost:8200)
  //serverUrl: 'https://80524ea517c44ae1be0e2dc3e3c0fd45.apm.us-central1.gcp.cloud.es.io',

  // Set service version (required for sourcemap feature)
  serviceVersion: ''
})
export default apm;
