import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import elasticsearch from 'elasticsearch';

const client = new elasticsearch.Client({
    //node: 'https://9f71b0ac6c4d4aa0a16542713c017c1b.us-central1.gcp.cloud.es.io:9243',
    //cloud: { id: 'eduk2:dXMtY2VudHJhbDEuZ2NwLmNsb3VkLmVzLmlvJDlmNzFiMGFjNmM0ZDRhYTBhMTY1NDI3MTNjMDE3YzFiJGQzMmExZmYzMmNhYzRmMmQ4NWZlNzgxYjljNTJkZGM0' },
    //auth: {
    //  username: 'elastic',
    //  password: 'o4pRFFj3j36XjcF3bzJQM07c'
    //},
    //maxRetries: 5,
    //requestTimeout: 60000,
    //sniffOnStart: false
    //host: 'http://localhost:9200'
    //host: 'https://3slfiesotk:hs7hpzxkb2@eduk2-4709248868.us-west-2.bonsaisearch.net:443'
    host: 'https://4xgg7rftje:q0vesrnzkv@eduk2-3683615528.us-east-1.bonsaisearch.net:443'
    // <a class="vglnk" href="http://root" rel="nofollow"><span>http</span><span>://</span><span>root</span></a>:12345@localhost:9200/
    // If you have set username and password
});

const edades = {
  maternal: [1,2,3,4],
  kinder: [3,4,5,6,7],
  primaria: [6,7,8,9,10,11,12,13],
  secundaria: [12,13,14,15,16],
  bachillerato: [15,16,17,18,19]
}
const getIdiomas = (idiomas) =>{
  console.log("getIdiomas");
  if (idiomas) {
  const lista = idiomas.filter(item => item.isChecked).map(item=>{return item.value})
  return lista
} else {
  return [];
}
}

const getFiltros = (lista, key, categoria, isBool) =>{
  console.log("getFiltros");
  console.log(`${key}_${categoria}_`);
  const filtro = `${key}_${categoria}_`
  const refKey = Object.keys(lista)
      .filter(key => key.includes(filtro) &&
                    !key.includes("_desc") &&
                    ((isBool && lista[key] === true)
                    || !isBool && lista[key] > 0))
      .map(key=> {return key.substring(filtro.length)});
  console.log(refKey)
  return refKey
}

const handleSubmit = (orden) => {
  console.log("handleSubmit");
  let niveles = orden["niveles"]
  niveles.map(key => {
    let escuela = {
      nivel: key,
      paquete: orden["paquete"],
      edades: edades[`${key}`],
      admisionesEmail: orden["admisionesEmail"],
      admisionesPhone: orden["admisionesPhone"],
      authorId: orden["authorId"],
      city: orden["city"],
      colonia: orden["colonia"],
      comercialName: orden["comercialName"],
      condicionesPago: orden["condicionesPago"],
      createdAt: orden["createdAt"],
      directorEmail: orden["directorEmail"],
      directorPhone: orden["directorPhone"],
      enlaceEmail: orden["enlaceEmail"],
      enlacePhone: orden["enlacePhone"],
      entreCalles: orden["entreCalles"],
      historia: orden["historia"],
      municipio: orden["municipio"],
      lema: orden["lema"],
      location: orden["location"],
      nombreFiscal: orden["nombreFiscal"],
      numExt: orden["numExt"],
      numInt: orden["numInt"],
      oficialName: orden["oficialName"],
      phone: orden["phone"],
      recepcionEmail: orden["recepcionEmail"],
      recepcionPhone: orden["recepcionPhone"],
      representanteLegal: orden["representanteLegal"],
      rfc: orden["rfc"],
      rsFacebook: orden["rsFacebook"],
      rsGoogle: orden["rsGoogle"],
      rsInstagram: orden["rsInstagram"],
      rsLinkedIn: orden["rsLinkedIn"],
      rsTwitter: orden["rsTwitter"],
      rsYouTube: orden["rsYouTube"],
      state: orden["state"],
      street: orden["street"],
      uploadImages: orden["uploadImages"],
      url: orden["url"],
      url_entrevista_dir: orden["url_entrevista_dir"],
      url_articulos_publicados: orden["url_entrevista_dir"],
      url_reportajes_y_mas: orden["url_entrevista_dir"],
      video1: orden["video1"],
      video2: orden["video2"],
      video3: orden["video3"],
      video_institucional: orden["video_institucional"],
      webpage: orden["webpage"],
      zip: orden["zip"],
      encuestaSatisfaccion: orden[`${key}_encuestaSatisfaccion`],
      metodologia_laica: orden[`${key}_metodologia_laica`],
      methodology: orden[`${key}_methodology`],
      idioma_horas: orden[`${key}_idioma_horas`],
      idioma_mcer: orden[`${key}_idioma_mcer`],
      idioma_modelo: orden[`${key}_idioma_modelo`],
      montoAnualizado: orden[`${key}_montoAnualizado`],
      capacidad_alumnos_por_aula: orden[`${key}_capacidad_alumnos_por_aula`],
      promedio_alumnos_por_aula: orden[`${key}_promedio_alumnos_por_aula`],
      total_alumnos_actual: orden[`${key}_total_alumnos_actual`],
      total_alumnos_capacidad: orden[`${key}_total_alumnos_capacidad`],
      redEscuelas: orden[`${key}_redEscuelas`],
      academica: getFiltros(orden, key, "academica", true),
      academicos: getFiltros(orden, key, "academicos", true),
      adicional: getFiltros(orden, key, "adicional", true),
      administracion: getFiltros(orden, key, "administracion", true),
      alianzas: getFiltros(orden, key, "alianzas", true),
      canchas: getFiltros(orden, key, "canchas", false),
      comunicacion: getFiltros(orden, key, "comunicacion", true),
      cultural: getFiltros(orden, key, "cultural", true),
      deportivas: getFiltros(orden, key, "deportivas", true),
      elementos_clase: getFiltros(orden, key, "element_clase", true),
      instalaciones: getFiltros(orden, key, "instalaciones", false),
      laboratorios: getFiltros(orden, key, "lab", false),
      seguridad: getFiltros(orden, key, "seguridad", true),
      tecnologicas: getFiltros(orden, key, "tecnologicas", true),
      idiomas: getIdiomas(orden["idiomas"])
    }
    console.log(escuela);
    client.index({
        index: "educa2",
        type: "_doc",
        refresh: true,
        body: escuela
    }).then(function (resp) {
        console.log(resp);
    }, function (err) {
        console.log(err.message);
    });
    return escuela
  })

  // Check if Connection is ok or not
  client.ping({
      // ping usually has a 3000ms timeout
      requestTimeout: Infinity,
  }, function (error) {
      if (error) {
          console.trace('elasticsearch cluster is down!');
      } else {
          console.log('All is well');
      }
  });

  // if (this.state.escudo) {
  //     this.props.uploadFile(this.state);
  // }
  //this.props.history.push('/');
}

const ProjectDetails = (props) => {
  const { project, auth } = props;
  if (!auth.uid) return <Redirect to='/signin' />
  if (project) {
      console.log(project)
      return (
      <div className="container section project-details">
        <div className="card z-depth-0">
          <div className="card-content">
            <span className="card-title">{project.nombreFiscal}</span>
            <p>{project.name} {project.currentNivel}</p>s
          </div>
          <div className="card-action grey lighten-4 grey-text">
            <div>
              <button onClick={handleSubmit(project)}>Process</button>
            </div>
            <div>Posted by {project.authorId} </div>
            <div>{moment(project.createdAt.toDate()).calendar()}</div>
          </div>
        </div>
        <div><pre>{JSON.stringify(project, null, 2) }</pre></div>
      </div>

    )
  } else {
    return (
      <div className="container center">
        <p>Loading ordenes...</p>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(state);
  const id = ownProps.match.params.id;
  const projects = state.firestore.data.ordenes;
  const project = projects ? projects[id] : null
  return {
    project: project,
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{
    collection: 'ordenes'
  }])
)(ProjectDetails)
