import React, { Component } from 'react';
import MediaQuery from 'react-responsive'
import { MDBLightbox,MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView} from 'mdbreact';
import Lightbox from "react-image-lightbox";
//const mixins =ResponsiveMixin;
class LightboxPage extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      mediaView: 'Desktop',
      images: Object.keys(props.images).slice(1).map(key => {
          console.log(props.images[key].url)
          return {src: props.images[key].url, alt: props.images[key].desc}
        }
      )
    }
  }

  state = {
    images: []
  }

  render() {
    if (this.state.images) {
      return (
        <div  className='custom-class'>
        <MediaQuery minWidth={992}>
          <MDBLightbox md='3' images={this.state.images} noMargins/>
        </MediaQuery>
        <MediaQuery maxWidth={991}>
            <MDBCarousel
              activeItem={1}
              length={this.state.images.length}
              showControls={true}
              showIndicators={false}
              className="z-depth-1"
              slide
            >
            <MDBCarouselInner>
            {
                this.state.images.map((img, idx) => {
                  console.log(img, idx);
                return (
                  <MDBCarouselItem itemId={idx+1}>
                    <MDBView>
                      <img
                        className="d-block w-100"
                        src={img.src}
                        alt={img.alt}
                      />
                    </MDBView>
                  </MDBCarouselItem>
                )
              })
            }
            </MDBCarouselInner>
          </MDBCarousel>
        </MediaQuery>
        </div>);
    } else {
      return <div></div>
    }
  }
}

export default LightboxPage;
