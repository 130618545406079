import React, { Component, useState } from "react";
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase';
import { MDBContainer, MDBCollapse, MDBCard, MDBCardBody, MDBCollapseHeader, MDBInput } from "mdbreact";
import PaymentForm from './PaymentForm'
import 'react-credit-cards/es/styles-compiled.css';
import { signUp } from '../../store/actions/authActions'
import { getData } from '../../store/actions/paypalActions'
import { createOrden } from '../../store/actions/ocCreate'
const ClientPage = (props) => {

  const initialStateValues = {
    collapseID: "collapse1"
  }

  const {firestore, auth} = props;
  const [values, setValues] = useState(initialStateValues);

  const toggleCollapse = (collapseID) => {
     setValues({ ...values, collapseID: values.collapseID !== collapseID ? collapseID : ""});
  }

  return (
      <MDBContainer>
        <MDBContainer className="accordion md-accordion accordion-4">
          <MDBCard>
            <MDBCollapseHeader
              onClick={() => {toggleCollapse("collapse1")}}
              className="z-depth-1 orange lighten-3"
              tagClassName="white-text text-center font-weight-bold text-uppercase"
              tag="h4"
            >
              Datos de cliente para pago
            </MDBCollapseHeader>
            <MDBCollapse id="collapse1" isOpen={values.collapseID}>
              <MDBCardBody className="rgba-white-strong black-text">
                <MDBInput label="Nombre(s)" className="mt-3" />
                <MDBInput label="Apellidos" className="mt-3" />
                <MDBInput label="Correo" className="mt-3" />
                <MDBInput label="Dirección" type="textarea" rows="2" />
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={() => {toggleCollapse("collapse2")}}
              className="z-depth-1 orange lighten-2"
              tagClassName="white-text text-center font-weight-bold text-uppercase"
              tag="h4"
            >
            Tarjeta
            </MDBCollapseHeader>
            <MDBCollapse id="collapse2" isOpen={values.collapseID}>
              <MDBCardBody className="rgba-white-strong black-text">
                <PaymentForm />
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={() => {toggleCollapse("collapse3")}}
              className="z-depth-1 orange lighten-2"
              tagClassName="white-text text-center font-weight-bold text-uppercase"
              tag="h4"
            >
              Pago Inscripción
            </MDBCollapseHeader>
            <MDBCollapse id="collapse3" isOpen={values.collapseID}>
              <MDBCardBody className="rgba-orange-strong white-text">
                <p>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life
                  accusamus terry richardson ad squid. 3 wolf moon officia aute,
                  non cupidatat skateboard dolor brunch. Food truck quinoa
                  nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                  aliqua put a bird on it squid single-origin coffee nulla
                  assumenda shoreditch et.
                </p>

                <p>
                  Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                  cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
                  vice lomo. Leggings occaecat craft beer farm-to-table, raw
                  denim aesthetic synth nesciunt you probably haven't heard of
                  them accusamus labore.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={() => {toggleCollapse("collapse4")}}
              className="z-depth-1 orange lighten-1"
              tagClassName="white-text text-center font-weight-bold text-uppercase"
              tag="h4"
            >
              Suscripción
            </MDBCollapseHeader>
            <MDBCollapse id="collapse4" isOpen={values.collapseID}>
              <MDBCardBody className="rgba-orange-strong white-text">
                <p>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life
                  accusamus terry richardson ad squid. 3 wolf moon officia aute,
                  non cupidatat skateboard dolor brunch. Food truck quinoa
                  nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                  aliqua put a bird on it squid single-origin coffee nulla
                  assumenda shoreditch et.
                </p>

                <p>
                  Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                  cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
                  vice lomo. Leggings occaecat craft beer farm-to-table, raw
                  denim aesthetic synth nesciunt you probably haven't heard of
                  them accusamus labore.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={() => {toggleCollapse("collapse5")}}
              className="z-depth-1 orange"
              tagClassName="white-text text-center font-weight-bold text-uppercase"
              tag="h4"
            >
              Datos Facturación
            </MDBCollapseHeader>
            <MDBCollapse id="collapse5" isOpen={values.collapseID}>
              <MDBCardBody className="rgba-orange-strong white-text">
                <p>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life
                  accusamus terry richardson ad squid. 3 wolf moon officia aute,
                  non cupidatat skateboard dolor brunch. Food truck quinoa
                  nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                  aliqua put a bird on it squid single-origin coffee nulla
                  assumenda shoreditch et.
                </p>

                <p>
                  Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                  cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
                  vice lomo. Leggings occaecat craft beer farm-to-table, raw
                  denim aesthetic synth nesciunt you probably haven't heard of
                  them accusamus labore.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>
        </MDBContainer>
      </MDBContainer>
    );
}


const mapStateToProps = (state) => {
 return {
   auth: state.firebase.auth,
   authError: state.auth.authError,
   dataError: state.auth.dataError,
 }
}

const mapDispatchToProps = (dispatch)=> {
 return {
   createOrden: (ordencompra) => dispatch(createOrden(ordencompra)),
   signUp: (creds) => dispatch(signUp(creds)),
 }
}

export default compose(
 withFirestore,
 connect(mapStateToProps, mapDispatchToProps),)(ClientPage);
