import React from 'react';
import messages from '../messages'
/**
 * This function is a helper fro getLocaleFromUserAgent
 * It is in charge of getting the correct values out of a value in navigator.languages
 *
 * @param language
 * @returns {{}}
 */
export function getLocaleValues(language) {
  const output = {};

  // eslint-disable-next-line prefer-destructuring
  output.locale = language.split('-')[0];
  output.messages = messages[language];

  return output;
}

export const getLocaleFromUserAgent = ({ language, languages }) => {
  /**
   * In the javascript specification, language gives the languages[0]
   */
  let output = {};

  if (typeof messages[language] !== 'undefined') {
    output = getLocaleValues(language);
  } else {
    try {
      languages.forEach(lang => {
        if (Object.keys(output).length > 0) {
          throw new Error();
        }
        if (typeof messages[lang] !== 'undefined') {
          output = getLocaleValues(lang);
        } else if (typeof messages[lang.split('-')[0]] !== 'undefined') {
          output = getLocaleValues(lang.split('-')[0]);
        }
      });
    } catch (e) {
      // Logger(e);
    }
  }

  // fallback to English
  if (Object.keys(output).length <= 0) {
    let langs = '';

    // eslint-disable-next-line no-return-assign
    languages.map(lang => (langs += `${lang},`));
    langs = langs.replace(/(^,)|(,$)/g, '');
    console.log(`User had languages '${langs}' but we don't support those languages`);
    output = {
      locale: 'en',
      messages: messages.en,
    };
  }

  return output;
};
