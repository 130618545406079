import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom'
import { signUp } from '../../store/actions/authActions'
import { getData } from '../../store/actions/paypalActions'
import { createOrden } from '../../store/actions/ocCreate'
import WelcomePage from './Welcome'
import PagoForm from '../pagos/PagoOpenpay';
import ClientPage from '../pagos/Cliente';
import OrdenCompra from '../ordenes/ordencompra';
import ImagePage from '../ordenes/ImageFile';
import SelectState from './SelectStatesCity'
import i18n from 'i18next';
import StickyVideo from 'react-sticky-video';
import 'react-sticky-video/dist/index.css';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBStepper,
  MDBStep,
  MDBIframe,
  MDBCollapse,
} from "mdbreact";
import InvoiceService from '../../config/services/api/invoice';



const SignUpSteps = (props) => {

  const edades = {
    maternal: [1,2,3,4],
    kinder: [3,4,5,6,7],
    primaria: [6,7,8,9,10,11,12,13],
    secundaria: [12,13,14,15,16],
    bachillerato: [15,16,17,18,19],
    academia: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]
  }
  const initialStateValues = {
      email: '',
      confirmemail: '',
      password: '',
      confirmpassword: '',
      name: '',
      directorName: '',
      admisionesPhone: '',
      invalidCheck: false,
      street: '',
      colonia: '',
      noExt: '',
      noInt: '',
      city: '',
      state: '',
      zip: '',
      url: '',
      CCT: '',
      lema: '',
      niveles: [],
      paquete: '',
      nMaternal: false,
      nKinder: false,
      nPrimaria: false,
      nSecundaria: false,
      nBachillerato: false,
      aCultural: false,
      aDeportiva: false,
      aTecnologica: false,
      aOtra: false,
      toPay: false,
      goInvoice: false,
      href: '',
      phone: '',
      whatsapp: '',
      uploadImages: {
        foto_num1: {desc: '', url: ''},
        foto_num2: {desc: '', url: ''}
      },
      collapseID: "basicCollapse",
      showVideo: false,
      authorId: '',
    }
  const {firestore, auth} = props;
  const [values, setValues] = useState(initialStateValues);
  const [authorId, setAuthorId] = useState('');
  const [formActivePanel, setFormActivePanel] = useState(1);
  const [paquete,setPaquete] = useState('');
  const [error,setError] = useState('');
  const getUser = async () => {
    console.log('getUser');
    if (auth.uid) {
      setFormActivePanel(2);
      setAuthorId(auth.uid);
      firestore.onSnapshot({ collection: "users", doc: auth.uid},
      function(querySnapshot) {
        var source = querySnapshot.metadata.hasPendingWrites ? "Local" : "Server";
        console.log(source, " data: ", querySnapshot.data());
        const doc = querySnapshot.data();
        setValues({...values
          , name: doc.name
          , email: doc.email
          , directorName: doc.directorName
          ,admisionesPhone: doc.admisionesPhone,
          street: doc.street,
          colonia: doc.colonia,
          noExt: doc.noExt,
          noInt: doc.noInt,
          state: doc.state,
          city: doc.city,
          zip: doc.zip,
          CCT: doc.CCT,
          lema: doc.lema,
          comercialName: doc.comercialName,
          url: doc.url,
          package: doc.paquete,
          levels: doc.levels,
          phone: doc.phone,
          whatsapp: doc.whatsapp,
          paquete: doc.paquete,
          nMaternal: doc.nMaternal,
          nKinder: doc.nKinder,
          nPrimaria: doc.nPrimaria,
          nSecundaria: doc.nSecundaria,
          nBachillerato: doc.nBachillerato,
          aCultural: doc.aCultural,
          aDeportiva: doc.aDeportiva,
          aTecnologica: doc.aTecnologica,
          aOtra: doc.aOtra,
          toPay: doc.toPay,
          goInvoice: doc.goInvoice
        });
        if (doc.paquete) {
          setPaquete(doc.paquete);
        }
        setFormActivePanel(3);
        if ('openpayClientId' in doc) {
          console.log(doc.openpayClientId);
        }

        if ('DatosPago' in doc) {
          setValues(doc.DatosPago);
        }
        if ('PagoId' in doc) {
          console.log(doc.PagoId);
        }

    });
  } else {
    setFormActivePanel(1);
  }
  };
  useEffect(() => {
    console.log('useEffect')
    getUser();
  }, []);

  useEffect(() => {
    console.log('authError')
  }, [error]);

  useEffect(() => {
    console.log('paquete')
    setValues({ ...values,
      paquete: paquete
    });
  }, [paquete]);

  const toggleVideo = () => {
   setValues({ ...values, showVideo: !values.showVideo });
  }
  const toggleCollapse = (collapseID) => {
     setValues({ ...values, collapseID: values.collapseID !== collapseID ? collapseID : ""});
  }
  const handleSelectCity = (e) => {
    console.log(e);
    setValues({ ...values,
      city: e.city,
      state: e.state
    });
  }
  const handleChange = (e) => {
    const { id, value } = e.target;
    setValues({ ...values, [id]: value });
  }
  const handleChangeCheckbox = (e) => {
    const { id, checked } = e.target;
    setValues({ ...values, [id]: checked });
  }
  const handleInvoice = (data) => {
    console.log(data);
    InvoiceService.getNumber(data).then((response) => {
      console.log(response);
      const number = response.data.invoice_number;
      const sendBody= {
        "send_to_invoicer": true
      }
      var body = data.content;
      body.detail.invoice_number = number;
      InvoiceService.generateInvoice(data, body).then((response) => {
        console.log(response);
        const invoiceId = response.data.href.split("/").pop();
        console.log(invoiceId);
        InvoiceService.sendInvoice(data, sendBody, invoiceId).then((response) => {
          console.log(response);
          this.setState(() => ({
            goInvoice: true,
            href: response.data.href
          }))
        })
      })

      alert(number);
    })
  }
  const handleSignup = () => {
    props.signUp(values);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.className += " was-validated";
    let niveles = [];
    let isLevel = false;
    let found = false;
    if (values.nMaternal) {
      niveles.push("maternal")
      isLevel=true;
    }
    if (values.nKinder) {
      niveles.push("kinder")
      isLevel=true;
    }
    if (values.nPrimaria) {
      niveles.push("primaria")
      isLevel=true;
    }
    if (values.nSecundaria) {
      niveles.push("secundaria")
      isLevel=true;
    }
    if (values.nBachillerato) {
      niveles.push("bachillerato")
      isLevel=true;
    }
    if (values.aCultural || values.aDeportiva || values.aTecnologica || values.aOtra) {
      niveles = [];
      niveles.push("academia")
      isLevel=true;
    }
    if (!isLevel) {
      alert("al menos un nivel Educativo");
    } else if (!values.invalidCheck) {
      alert("Debe aceptar el aviso de privacidad");
    } else {
      values["levels"] = niveles;

      console.log(values.paquete);
      if (paquete === 'base' ||
          paquete === 'despegue' ||
          paquete === 'plataforma' ||
          paquete === 'descubre' ||
          paquete === 'explora') {

            setValues ({...values,
              toPay: true,
              paquete: paquete,
              levels: niveles
            });
            console.log(values);
            console.log('setUser');
            if (auth.uid) {
                firestore.collection('users').doc(auth.uid).update({
                  ...values
                });
                found = false;
                firestore.collection('ordenes').where("authorId", "==", authorId)
                .get()
                .then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc) {
                        // doc.data() is never undefined for query doc snapshots
                        console.log(doc.id, " => ", doc.data());
                        found = true;
                    });
                })
                .catch(function(error) {
                    console.log("Error getting documents: ", error);
                });
                if (found === false){
                  firestore.collection('ordenes').add({
                    ...values,
                    authorId: auth.uid,
                    createdAt: new Date()
                  });
                }
            }
            //props.createOrden(values);
      }

    }

  }
  const {authError, dataError} = props;
  const precios = {
    "base": 0.00,
    "plataforma": 950.00,
    "despegue": 1250.00,
    "explora": 1550.00,
    "descubre": 1950.00,
    "nivelAdicional": 200.00
  }
  const imagenes = {
    "base": "Logo e imagen institucional",
    "plataforma": "Logo e imagen institucional",
    "despegue": "8 Imágenes",
    "explora": "20 Imágenes",
    "descubre": "20 Imágenes"
  }
  const swapFormActive = (a) => (param) => (e) => {
    setFormActivePanel(param);
  }

  const handleNextPrevClick = (a) => (param) => (e) => {
    setFormActivePanel(param);
  }

  const handleSubmission = () => {
    alert('Form submitted!');
  }

  const calculateAutofocus = (a) => {
    if (formActivePanel) {
      return true
    }
  }
  const onPackageSelect = (p) => {
    alert(p);
    setPaquete(p);
  }
  return (
    <MDBContainer>
      <h2 className="text-center font-weight-bold pt-4 pb-5 mb-2"><strong>Proceso de Registro</strong></h2>
      <MDBStepper form>
        <MDBStep form>
          <a onClick={swapFormActive(1)(1)}>
            <MDBBtn color={ formActivePanel===1 ? "indigo" : "default" } circle>
              1
            </MDBBtn>
          </a>
          <p>Usuario</p>
        </MDBStep>
        <MDBStep form>
          <a onClick={swapFormActive(1)(2)}>
            <MDBBtn size="lg" color={ formActivePanel===2 ? "indigo" : "default" } circle>
              2
            </MDBBtn>
          </a>
          <p>Paquete</p>
        </MDBStep>
        <MDBStep form>
          <a onClick={swapFormActive(1)(3)}>
            <MDBBtn size="lg" color={ formActivePanel===3 ? "indigo" : "default" } circle>
              3
            </MDBBtn>
          </a>
          <p>Datos Escuela</p>
        </MDBStep>
        <MDBStep form>
          <a onClick={swapFormActive(1)(4)}>
            <MDBBtn color={ formActivePanel===4 ? "indigo" : "default" } circle>
              4
            </MDBBtn>
          </a>
          <p>Terminos y condiciones</p>
        </MDBStep>
        <MDBStep form>
          <a onClick={swapFormActive(1)(5)}>
            <MDBBtn color={ formActivePanel===5 ? "indigo" : "default" } circle>
              5
            </MDBBtn>
          </a>
          <p>Información Comercial</p>
        </MDBStep>
      </MDBStepper>

      <form role="form" onSubmit={handleSubmit}>
        <MDBRow>
          {authError && (<p className="center red-text pl-0 my-4">
            <strong>{authError}</strong></p>)}
            {formActivePanel == 1 && auth.uid &&
            (<MDBCol md="12">
              <h3 className="font-weight-bold pl-0 my-4">
                <strong>Datos de Usuario</strong></h3>
                <MDBInput
                    label={i18n.t('nombre')}
                    icon="user"
                    group
                    type="text"
                    required
                    validate
                    error="wrong"
                    success="right"
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  >
                  <div className="invalid-feedback">
                     {i18n.t('proveeDatosValidos',{s: '$t(nombre)'})}
                  </div>
                  <div className="valid-feedback">{i18n.t('Correcto')}</div>
                </MDBInput>
                 <MDBInput
                   label={i18n.t('correo')}
                   icon="envelope"
                   group
                   type="email"
                   disabled
                   id="email"
                   value={values.email}
                  />
                <MDBBtn color="mdb-color" rounded className="float-right" onClick={handleNextPrevClick(1)(2)}>Siguiente</MDBBtn>
            </MDBCol>
          )}
          {formActivePanel == 1 && !auth.uid &&
          (<MDBCol md="12">
            <h3 className="font-weight-bold pl-0 my-4">
              <strong>Datos de Usuario</strong></h3>
              <MDBInput
                  label={i18n.t('nombre')}
                  icon="user"
                  group
                  type="text"
                  required
                  validate
                  error="wrong"
                  success="right"
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                >
                 <div className="invalid-feedback">
                    {i18n.t('proveeDatosValidos',{s: '$t(nombre)'})}
                 </div>
                 <div className="valid-feedback">{i18n.t('Correcto')}</div>
               </MDBInput>
                <MDBInput
                  label={i18n.t('correo')}
                  icon="envelope"
                  required
                  group
                  type="email"
                  validate
                  error="wrong"
                  success="right"
                  id="email"
                  onChange={handleChange}
                 >
                  <div className="invalid-feedback">
                     {i18n.t('proveeDatosValidos',{s: '$t(correo)'})}
                  </div>
                  <div className="valid-feedback">{i18n.t('Correcto')}</div>
                </MDBInput>

                <MDBInput
                  id="confirmemail"
                  label={i18n.t('confirmaCorreo')}
                  icon="exclamation-triangle"
                  group
                  type="email"
                  validate
                  error="wrong"
                  success="right"
                  onChange={handleChange}
                  className={values.email === values.confirmemail ? "is-valid" : "is-invalid"}
                  >
                   <div className="invalid-feedback">
                      {values.email !== values.confirmemail ? 'debe coincidir el correo' : i18n.t('confirmaDatos',{s: '$t(correo)'})}
                   </div>
                   <div className="valid-feedback">{i18n.t('Correcto')}</div>
                 </MDBInput>
                <MDBInput
                  label={i18n.t('Password')}
                  icon="lock"
                  group
                  type="password"
                  validate
                  required
                  id="password"
                  onChange={handleChange}
                />
                <MDBInput
                  label={i18n.t('confirmpassword')}
                  icon="exclamation-triangle"
                  group
                  type="password"
                  validate
                  required
                  id="confirmpassword"
                  onChange={handleChange}
                  error="wrong"
                  success="right"
                  className={!!values.confirmpassword && values.password ===values.confirmpassword ? "is-valid" : "is-invalid"}
                >
                   <div className="invalid-feedback">
                     {i18n.t('proveeDatosValidos',{s: '$t(password)'})}
                   </div>
                   <div className="valid-feedback">{i18n.t('Correcto')}</div>
                </MDBInput>

            <MDBBtn color="mdb-color" rounded className="float-right" onClick={handleSignup}>Registrar</MDBBtn>
          </MDBCol>)}
          {formActivePanel === 2 && !paquete &&
          (
            <OrdenCompra paquete={paquete} onSelect={onPackageSelect} />
          )}
          {formActivePanel === 2 && paquete &&
          (
            <MDBCol md="12">
              <h3 className="font-weight-bold pl-0 my-4"><strong>Paquete Seleccionado: {paquete}</strong></h3>
              <MDBBtn onClick={() => {onPackageSelect('')}}>
                Cambiar Paquete
              </MDBBtn>
            </MDBCol>
          )
          }
          {formActivePanel === 3 &&
          (
            <MDBCol md="12">
              <h3 className="font-weight-bold pl-0 my-4"><strong>Datos Escuela</strong></h3>
              <MDBBtn
                 color="primary"
                 onClick={() => {toggleCollapse("basicCollapse")}}
                 style={{ marginBottom: "1rem" }}
               >
               Niveles
               </MDBBtn>
              <MDBCollapse id="basicCollapse" isOpen={values.collapseID}>
                <div class="input-group is-invalid">

                  <MDBInput label={i18n.t('maternal')}
                     name="a"
                     filled
                     type="checkbox"
                     id="nMaternal"
                     onChange={handleChangeCheckbox}
                     checked={values.nMaternal}
                     validate
                     className={values.nMaternal||values.nKinder||values.nPrimaria||values.nSecundaria||values.nBachillerato ? "is-valid" : "is-invalid"}
                      >
                  </MDBInput>
                  <MDBInput name="a" label={i18n.t('preescolar')} filled type="checkbox" id="nKinder"
                   onChange={handleChangeCheckbox} checked={values.nKinder}
                   className={values.nMaternal||values.nKinder||values.nPrimaria||values.nSecundaria||values.nBachillerato ? "is-valid" : "is-invalid"}  />
                  <MDBInput name="a" label={i18n.t('primaria')} filled type="checkbox" id="nPrimaria"
                  className={values.nMaternal||values.nKinder||values.nPrimaria||values.nSecundaria||values.nBachillerato ? "is-valid" : "is-invalid"}
                  onChange={handleChangeCheckbox} checked={values.nPrimaria} />
                  <MDBInput name="a" label={i18n.t('secundaria')} filled type="checkbox" id="nSecundaria"
                  className={values.nMaternal||values.nKinder||values.nPrimaria||values.nSecundaria||values.nBachillerato ? "is-valid" : "is-invalid"}
                  onChange={handleChangeCheckbox} checked={values.nSecundaria} />
                  <MDBInput name="a" label={i18n.t('bachillerato')} filled type="checkbox"
                   id="nBachillerato"
                   onChange={handleChangeCheckbox}
                   className={values.nMaternal||values.nKinder||values.nPrimaria||values.nSecundaria||values.nBachillerato ? "is-valid" : "is-invalid"}
                   checked={values.nBachillerato} >
                  {!(values.nMaternal||values.nKinder||values.nPrimaria||values.nSecundaria||values.nBachillerato) ? (<div className="invalid-feedback">
                    {i18n.t('proveNivel')}
                  </div>):null}
                  </MDBInput>
                </div>
              </MDBCollapse>
              <MDBCollapse id="academiasCollapse" isOpen={values.collapseID}>
               <div class="input-group is-invalid">

                  <MDBInput label={i18n.t('academiaCultural')}
                     name="b"
                     filled
                     type="checkbox"
                     id="aCultural"
                     onChange={handleChangeCheckbox}
                     checked={values.aCultural}
                     validate
                     className={values.aCultural||values.aDeportiva||values.aTecnologica||values.aOtra ? "is-valid" : "is-invalid"}
                      >
                  </MDBInput>
                  <MDBInput name="b" label={i18n.t('academiaDeportiva')} filled type="checkbox" id="aDeportiva"
                   onChange={handleChangeCheckbox} checked={values.aDeportiva}
                   className={values.aCultural||values.aDeportiva||values.aTecnologica||values.aOtra ? "is-valid" : "is-invalid"}  />
                  <MDBInput name="b" label={i18n.t('academiaTecno')} filled type="checkbox" id="aTecnologica"
                   className={values.aCultural||values.aDeportiva||values.aTecnologica||values.aOtra ? "is-valid" : "is-invalid"}
                   onChange={handleChangeCheckbox} checked={values.aTecnologica} />
                   <MDBInput name="b" label={i18n.t('academiaOtra')} filled type="checkbox"
                    id="aOtra"
                    onChange={handleChangeCheckbox}
                    className={values.aCultural||values.aDeportiva||values.aTecnologica||values.aOtra ? "is-valid" : "is-invalid"}
                    checked={values.aOtra} >
                   {!(values.aCultural||values.aDeportiva||values.aTecnologica||values.aOtra) ? (<div className="invalid-feedback">
                     {i18n.t('proveAcademia')}
                   </div>):null}
                   </MDBInput>
                </div>
              </MDBCollapse>

              <MDBInput
                 label={i18n.t('directorName')}
                 group
                 type="text"
                 validate
                 id="directorName"
                 onChange={handleChange}
                 value={values.directorName}
                 required
               >
                 <div className="invalid-feedback">
                    {i18n.t('proveeDatosValidos',{s: '$t(directorName)'})}
                 </div>
                 <div className="valid-feedback">{i18n.t('Correcto')}</div>
               </MDBInput>
               <MDBInput
                 label={i18n.t('whatsapp')}
                 icon="whatsapp"
                 group
                 type="tel"
                 pattern="[0-9]{10}"
                 maxlength="10"
                 validates
                 id="whatsapp"
                 onChange={handleChange}
                 required
                 value={values.whatsapp}
               >
                 {error && <p className="error">{error}</p>}
                 <div className="invalid-feedback">
                    {i18n.t('proveeDatosValidos',{s: '$t(telefono)'})}
                    {values.whatsapp && values.whatsapp.length != 10 ? ' Deben ser 10 dígitos' : '' }
                 </div>
                 <div className="valid-feedback">{i18n.t('Correcto')}</div>
               </MDBInput>

           <MDBInput
             label={i18n.t('admisionesPhone')}
             icon="phone"
             group
             type="tel"
             validate
             pattern="[0-9]{10}"
             maxlength="10"
             id="admisionesPhone"
             onChange={handleChange}
             required
             value={values.admisionesPhone}
           >
           <div className="invalid-feedback">
              {i18n.t('confirmaDatos',{s: '$t(admisionesPhone)'})}
              {values.admisionesPhone && values.admisionesPhone.length != 10 ? ' Deben ser 10 dígitos' : '' }
           </div>
           <div className="valid-feedback">{i18n.t('Correcto')}</div>
         </MDBInput>
        <MDBInput
                label={i18n.t('cct')}
                icon="key"
                group
                type="text"
                validate
                error="wrong"
                success="right"
                id="CCT"
                value={values.CCT}
                onChange={handleChange}
               />
         <MDBInput
           label={i18n.t('comercialName')}
           icon="home"
           group
           type="text"
           validate
           required
           error="wrong"
           success="right"
           id="comercialName"
           onChange={handleChange}
           value={values.comercialName}
         />
         <MDBInput
           validate
           value={values.lema}
           onChange={handleChange}
           type="text"
           id="lema"
           name="lema"
           label={i18n.t('lema')}
           required
           value={values.lema}
         >
           <div className="invalid-feedback">
             {i18n.t('proveeDatosValidos',{s: 'dato'})}
           </div>
           <div className="valid-feedback">{i18n.t('Correcto')}</div>
         </MDBInput>
         <MDBInput
           validate
           value={values.street}
           onChange={handleChange}
           type="text"
           id="street"
           name="street"
           label={i18n.t('street')}
           required
           value={values.street}
         >
           <div className="invalid-feedback">
             {i18n.t('proveeDatosValidos',{s: 'dato'})}
           </div>
           <div className="valid-feedback">{i18n.t('Correcto')}</div>
         </MDBInput>
               <MDBRow >
                 <MDBInput
                   validate
                   value={values.noExt}
                   onChange={handleChange}
                   type="text"
                   id="noExt"
                   name="noExt"
                   label={i18n.t('numExt')}
                   className="mr-1 col-md-6"
                   required
                 >
                   <div className="invalid-feedback">
                     {i18n.t('proveeDatosValidos',{s: 'dato'})}
                   </div>
                   <div className="valid-feedback">{i18n.t('Correcto')}</div>
                 </MDBInput>
                 <MDBInput
                   validate
                   value={values.noInt}
                   onChange={handleChange}
                   type="text"
                   id="noInt"
                   name="noInt"
                   label={i18n.t('numInt')}
                   className="mr-1 col-md-6"
                 >
                   <div className="invalid-feedback">
                     {i18n.t('proveeDatosValidos',{s: 'dato'})}
                   </div>
                   <div className="valid-feedback">{i18n.t('Correcto')}</div>
                 </MDBInput>
               </MDBRow>
               <MDBInput
                 validate
                 value={values.colonia}
                 onChange={handleChange}
                 type="text"
                 id="colonia"
                 name="colonia"
                 label={i18n.t('colonia')}
                 required
               >
                 <div className="invalid-feedback">
                   {i18n.t('proveeDatosValidos',{s: 'dato'})}
                 </div>
                 <div className="valid-feedback">{i18n.t('Correcto')}</div>
               </MDBInput>

               <MDBInput
                 value={values.zip}
                 onChange={handleChange}
                 type="text"
                 id="zip"
                 name="zip"
                 label={i18n.t('zip')}
                 required
               >
               </MDBInput>
               <SelectState onChange={handleSelectCity} ciudad={values.city} estado={values.state} />
               <br />

              <MDBRow>
               <MDBCol md="10">

                 <MDBInput
                      label={<a href='https://b44e4152-aeb6-446b-9ff9-db89f0a2ad28.filesusr.com/ugd/850e72_7a5399b364184a9d8fe7fb21bcfa66f5.pdf' target='_blank'>Aviso Privacidad</a>}
                      type="checkbox"
                      group
                      validate
                      id="invalidCheck"
                      required
                      onChange={handleChangeCheckbox}
                  >
                  <div className="invalid-feedback">
                      Debe estar de acuerdo antes de aceptar.
                  </div>
                  <div className="valid-feedback">{i18n.t('Correcto')}</div>
                  </MDBInput>

                 <div className="text-center">
                   <MDBBtn color="primary" type="submit">Registrar</MDBBtn>
                   <div className="center red-text">
                   { dataError ? dataError.map((x) => {return (<p>{x.message}</p>)}) : null }
                   { authError ? (<p>{authError}</p>) : null }
                   </div>
                 </div>
               </MDBCol>
             </MDBRow>

            </MDBCol>

          )}
          {formActivePanel == 4 &&
          (<MDBCol md="12">
            <h3 className="font-weight-bold pl-0 my-4"><strong>Terminos y condiciones</strong></h3>
            <MDBInput label="I agreee to the terms and conditions" type="checkbox" id="checkbox" autoFocus={calculateAutofocus(1)} />
            <MDBBtn color="mdb-color" rounded className="float-left" onClick={handleNextPrevClick(1)(3)}>previous</MDBBtn>
            <MDBBtn color="mdb-color" rounded className="float-right" onClick={handleNextPrevClick(1)(5)}>next</MDBBtn>
          </MDBCol>)}

          {formActivePanel == 5 &&
          (<MDBCol md="12">
            <h3 className="font-weight-bold pl-0 my-4"><strong>Terminado</strong></h3>
            <h2 className="text-center font-weight-bold my-4">Registro completado vaya a Datos de Escuela!</h2>
            <MDBBtn color="mdb-color" rounded className="float-left" onClick={handleNextPrevClick(1)(4)}>previous</MDBBtn>
            <MDBBtn color="success" rounded className="float-right" onClick={handleSubmission}>submit</MDBBtn>
          </MDBCol>)}
        </MDBRow>
      </form>
    </MDBContainer>
  )
//       } else {
//         if (this.state.goInvoice && this.state.href) {
//           window.open(this.state.href);
//           return (
//             <Redirect to='/orden' />);
//         } else {
//         return (
//           <section className="text-center my-5">
//       <h2 className="h1-responsive font-weight-bold text-center my-5">
//         Nuestro plan de pagos
//       </h2>
//       <p className="grey-text text-center w-responsive mx-auto mb-5">
//         Se tiene una cuota de registro que equivale al pago mensual incluyendo un nivel seleccionado.
//       </p>
//       <MDBRow>
//         <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
//         </MDBCol>
//         <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
//           <MDBCard pricing>
//             <div className="price header white-text indigo rounded-top">
//               <h2 className="number">{precios[paquete]}</h2>
//               <div className="version">
//                 <h5 className="mb-0">Paquete {paquete}</h5>
//               </div>
//             </div>
//             <MDBCardBody className="striped mb-1">
//               <ul>
//                 <li>
//                   <p className="mt-2">
//                     <MDBIcon icon="check" className="green-text pr-2" />
//                     Información comercial
//                   </p>
//                 </li>
//                 <li>
//                   <p>
//                     <MDBIcon icon="check" className="green-text pr-2" />1 Ubicación por filtros
//                     {}
//                   </p>
//                 </li>
//                 <li>
//                   <p>
//                     <MDBIcon icon="check" className="green-text pr-2" />
//                     Datos Analíticos
//                   </p>
//                 </li>
//                 <li>
//                   <p>
//                     <MDBIcon icon="check" className="green-text pr-2" />
//                     {imagenes[paquete]}
//                   </p>
//                 </li>
//                 <li>
//                   <p>
//                     <MDBIcon icon="check" className="green-text pr-2" />
//                     Captura backend{" "}
//                   </p>
//                 </li>
//               </ul>
//               {(this.props.data) ? (<MDBBtn color="indigo" onClick={() => {this.handleInvoice(data.data)}}>Pagar ahora</MDBBtn>): null}
//             </MDBCardBody>
//           </MDBCard>
//         </MDBCol>
//       </MDBRow>
//     </section>
//         )
//       }
//       }
//     }
//
//     //if (auth.uid) return <Redirect to='/' />
//     const gratis = paquete === 'base' ? 'Gratis' : '';
//     return (
//     <MDBContainer className="mt-5">
//     <p className="h1 text-center mb-12">{i18n.t('Preregistro', {s: gratis})}</p>
//     <div class="d-flex justify-content-end">
//       <MDBBtn
//          class="btn btn-outline-primary waves-effect"
//          color="primary"
//          onClick={this.toggleVideo}
//          style={{ marginBottom: "1rem" }}
//        >
//        {!this.state.showVideo ? 'Ver tutorial': 'Ocultar Tutorial'}
//        </MDBBtn>
//      </div>
//     {this.state.showVideo && (
//     <div className="embed-responsive">
//     <StickyVideo
//       url="https://www.youtube.com/watch?v=4wL3Pcx_cto"
//       stickyConfig={{
//         width: 320,
//         height: 180,
//         position: 'bottom-right'
//       }}
//     />
//     </div>)}
//     <MDBRow>
//     <MDBCol lg="12">
//     <form className="needs-validation" onSubmit={this.handleSubmit.bind(this)} noValidate>
//       <MDBRow className="mt-5">
//
//          <MDBCol md="6">
//            <p className="h5 text-center mb-4">{i18n.t('datosContacto')}</p>
//            <div className="grey-text">
//
//              <MDBInput
//                label={i18n.t('nombre')}
//                icon="user"
//                group
//                type="text"
//                required
//                validate
//                error="wrong"
//                success="right"
//                id="name"
//                name="name"
//                value={this.state.name}
//                onChange={this.handleChange}
//              >
//               <div className="invalid-feedback">
//                  {i18n.t('proveeDatosValidos',{s: '$t(nombre)'})}
//               </div>
//               <div className="valid-feedback">{i18n.t('Correcto')}</div>
//             </MDBInput>
//              <MDBInput
//                label={i18n.t('correo')}
//                icon="envelope"
//                required
//                group
//                type="email"
//                validate
//                error="wrong"
//                success="right"
//                id="email"
//                onChange={this.handleChange}
//               >
//                <div className="invalid-feedback">
//                   {i18n.t('proveeDatosValidos',{s: '$t(correo)'})}
//                </div>
//                <div className="valid-feedback">{i18n.t('Correcto')}</div>
//              </MDBInput>
//
//              <MDBInput
//                id="confirmemail"
//                label={i18n.t('confirmaCorreo')}
//                icon="exclamation-triangle"
//                group
//                type="email"
//                validate
//                error="wrong"
//                success="right"
//                onChange={this.handleChange}
//                className={this.state.email === this.state.confirmemail ? "is-valid" : "is-invalid"}
//                >
//                 <div className="invalid-feedback">
//                    {this.state.email !== this.state.confirmemail ? 'debe coincidir el correo' : i18n.t('confirmaDatos',{s: '$t(correo)'})}
//                 </div>
//                 <div className="valid-feedback">{i18n.t('Correcto')}</div>
//               </MDBInput>
//              <MDBInput
//                label={i18n.t('Password')}
//                icon="lock"
//                group
//                type="password"
//                validate
//                required
//                id="password"
//                onChange={this.handleChange}
//              />
//              <MDBInput
//                label={i18n.t('confirmpassword')}
//                icon="exclamation-triangle"
//                group
//                type="password"
//                validate
//                required
//                id="confirmpassword"
//                onChange={this.handleChange}
//                error="wrong"
//                success="right"
//                className={!!this.state.confirmpassword && this.state.password ===this.state.confirmpassword ? "is-valid" : "is-invalid"}
//              >
//                 <div className="invalid-feedback">
//                   {i18n.t('proveeDatosValidos',{s: '$t(password)'})}
//                 </div>
//                 <div className="valid-feedback">{i18n.t('Correcto')}</div>
//              </MDBInput>
//
//              <MDBRow className="mt-5">
//                 <MDBCol md="8">
//                    <MDBInput
//                      label={i18n.t('telefono')}
//                      icon="phone"
//                      group
//                      type="tel"
//                      validate
//                      pattern="[0-9]{10}"
//                      maxlength="10"
//                      id="phone"
//                      onChange={this.handleChange}
//                      required
//                    >
//                    <div className="invalid-feedback">
//                       {i18n.t('confirmaDatos',{s: '$t(telefono)'})}
//                       {this.state.phone.length != 10 ? ' Deben ser 10 dígitos' : '' }
//                    </div>
//                    <div className="valid-feedback">{i18n.t('Correcto')}</div>
//                  </MDBInput>
//                 </MDBCol>
//                 <MDBCol md="4">
//                     <MDBInput
//                       label={i18n.t('extension')}
//                       icon="star"
//                       group
//                       type="number"
//                       validate
//                       id="ext"
//                       onChange={this.handleChange}
//                     />
//
//                 </MDBCol>
//
//              </MDBRow>
//              <MDBInput
//                label={i18n.t('directorName')}
//                group
//                type="text"
//                validate
//                id="directorName"
//                onChange={this.handleChange}
//                required
//              >
//                <div className="invalid-feedback">
//                   {i18n.t('proveeDatosValidos',{s: '$t(directorName)'})}
//                </div>
//                <div className="valid-feedback">{i18n.t('Correcto')}</div>
//              </MDBInput>
//              <MDBInput
//                label={i18n.t('whatsapp')}
//                icon="whatsapp"
//                group
//                type="tel"
//                pattern="[0-9]{10}"
//                maxlength="10"
//                validates
//                id="whatsapp"
//                onChange={this.handleChange}
//                required
//              >
//                {this.state.error && <p className="error">{this.state.error}</p>}
//                <div className="invalid-feedback">
//                   {i18n.t('proveeDatosValidos',{s: '$t(telefono)'})}
//                   {this.state.whatsapp.length != 10 ? ' Deben ser 10 dígitos' : '' }
//                </div>
//                <div className="valid-feedback">{i18n.t('Correcto')}</div>
//              </MDBInput>
//
//              <MDBInput
//                label={i18n.t('admisionesPhone')}
//                icon="phone"
//                group
//                type="tel"
//                validate
//                pattern="[0-9]{10}"
//                maxlength="10"
//                id="admisionesPhone"
//                onChange={this.handleChange}
//                required
//              >
//              <div className="invalid-feedback">
//                 {i18n.t('confirmaDatos',{s: '$t(admisionesPhone)'})}
//                 {this.state.admisionesPhone.length != 10 ? ' Deben ser 10 dígitos' : '' }
//              </div>
//              <div className="valid-feedback">{i18n.t('Correcto')}</div>
//            </MDBInput>
//              </div>
//              </MDBCol>
//              <MDBCol md="6">
//                <p className="h5 text-center mb-4">{i18n.t('datosEscuela')}</p>
//                <div className="grey-text">
//                  <MDBInput
//                   label={i18n.t('cct')}
//                   icon="key"
//                   group
//                   type="text"
//                   validate
//                   error="wrong"
//                   success="right"
//                   id="CCT"
//                   value={this.state.CCT}
//                   onChange={this.handleChange}
//                  />
//                  <MDBInput
//                    label={i18n.t('comercialName')}
//                    icon="home"
//                    group
//                    type="text"
//                    validate
//                    required
//                    error="wrong"
//                    success="right"
//                    id="comercialName"
//                    onChange={this.handleChange}
//                  />
//                  <MDBInput
//                    validate
//                    value={this.state.lema}
//                    onChange={this.handleChange}
//                    type="text"
//                    id="lema"
//                    name="lema"
//                    label={i18n.t('lema')}
//                    required
//                  >
//                    <div className="invalid-feedback">
//                      {i18n.t('proveeDatosValidos',{s: 'dato'})}
//                    </div>
//                    <div className="valid-feedback">{i18n.t('Correcto')}</div>
//                  </MDBInput>
//                  <MDBInput
//                    validate
//                    value={this.state.street}
//                    onChange={this.handleChange}
//                    type="text"
//                    id="street"
//                    name="street"
//                    label={i18n.t('street')}
//                    required
//                  >
//                    <div className="invalid-feedback">
//                      {i18n.t('proveeDatosValidos',{s: 'dato'})}
//                    </div>
//                    <div className="valid-feedback">{i18n.t('Correcto')}</div>
//                  </MDBInput>
//                  <MDBRow >
//                    <MDBInput
//                      validate
//                      value={this.state.noExt}
//                      onChange={this.handleChange}
//                      type="text"
//                      id="noExt"
//                      name="noExt"
//                      label={i18n.t('noExt')}
//                      className="mr-1 col-md-6"
//                      required
//                    >
//                      <div className="invalid-feedback">
//                        {i18n.t('proveeDatosValidos',{s: 'dato'})}
//                      </div>
//                      <div className="valid-feedback">{i18n.t('Correcto')}</div>
//                    </MDBInput>
//                    <MDBInput
//                      validate
//                      value={this.state.noInt}
//                      onChange={this.handleChange}
//                      type="text"
//                      id="noInt"
//                      name="noInt"
//                      label={i18n.t('noInt')}
//                      className="mr-1 col-md-6"
//                    >
//                      <div className="invalid-feedback">
//                        {i18n.t('proveeDatosValidos',{s: 'dato'})}
//                      </div>
//                      <div className="valid-feedback">{i18n.t('Correcto')}</div>
//                    </MDBInput>
//                  </MDBRow>
//                  <MDBInput
//                    validate
//                    value={this.state.colonia}
//                    onChange={this.handleChange}
//                    type="text"
//                    id="colonia"
//                    name="colonia"
//                    label={i18n.t('colonia')}
//                    required
//                  >
//                    <div className="invalid-feedback">
//                      {i18n.t('proveeDatosValidos',{s: 'dato'})}
//                    </div>
//                    <div className="valid-feedback">{i18n.t('Correcto')}</div>
//                  </MDBInput>
//
//                  <MDBInput
//                    value={this.state.zip}
//                    onChange={this.handleChange}
//                    type="text"
//                    id="zip"
//                    name="zip"
//                    label={i18n.t('zip')}
//                    required
//                  >
//                  </MDBInput>
//                  <SelectState onChange={this.handleSelectCity} onChangeState={this.handleSelectState} />
//                  <br />
//                  <MDBBtn
//                     color="primary"
//                     onClick={this.toggleCollapse("basicCollapse")}
//                     style={{ marginBottom: "1rem" }}
//                   >
//                   Escuela
//                   </MDBBtn>
//                   <MDBBtn
//                    color="primary"
//                    onClick={this.toggleCollapse("academiasCollapse")}
//                    style={{ marginBottom: "1rem" }}
//                    >
//                     Academias
//                   </MDBBtn>
//                  <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
//                  <div class="input-group is-invalid">
//
//                    <MDBInput label={i18n.t('maternal')}
//                       name="a"
//                       filled
//                       type="checkbox"
//                       id="nMaternal"
//                       onChange={this.handleChangeCheckbox}
//                       checked={this.state.nMaternal}
//                       validate
//                       className={this.state.nMaternal||this.state.nKinder||this.state.nPrimaria||this.state.nSecundaria||this.state.nBachillerato ? "is-valid" : "is-invalid"}
//                        >
//                    </MDBInput>
//                    <MDBInput name="a" label={i18n.t('preescolar')} filled type="checkbox" id="nKinder"
//                     onChange={this.handleChangeCheckbox} checked={this.state.nKinder}
//                     className={this.state.nMaternal||this.state.nKinder||this.state.nPrimaria||this.state.nSecundaria||this.state.nBachillerato ? "is-valid" : "is-invalid"}  />
//                    <MDBInput name="a" label={i18n.t('primaria')} filled type="checkbox" id="nPrimaria"
//                    className={this.state.nMaternal||this.state.nKinder||this.state.nPrimaria||this.state.nSecundaria||this.state.nBachillerato ? "is-valid" : "is-invalid"}
//                    onChange={this.handleChangeCheckbox} checked={this.state.nPrimaria} />
//                    <MDBInput name="a" label={i18n.t('secundaria')} filled type="checkbox" id="nSecundaria"
//                    className={this.state.nMaternal||this.state.nKinder||this.state.nPrimaria||this.state.nSecundaria||this.state.nBachillerato ? "is-valid" : "is-invalid"}
//                    onChange={this.handleChangeCheckbox} checked={this.state.nSecundaria} />
//                    <MDBInput name="a" label={i18n.t('bachillerato')} filled type="checkbox"
//                     id="nBachillerato"
//                     onChange={this.handleChangeCheckbox}
//                     className={this.state.nMaternal||this.state.nKinder||this.state.nPrimaria||this.state.nSecundaria||this.state.nBachillerato ? "is-valid" : "is-invalid"}
//                     checked={this.state.nBachillerato} >
//                    {!(this.state.nMaternal||this.state.nKinder||this.state.nPrimaria||this.state.nSecundaria||this.state.nBachillerato) ? (<div className="invalid-feedback">
//                      {i18n.t('proveNivel')}
//                    </div>):null}
//                    </MDBInput>
//                  </div>
//                  </MDBCollapse>
//                  <MDBCollapse id="academiasCollapse" isOpen={this.state.collapseID}>
//                   <div class="input-group is-invalid">
//
//                      <MDBInput label={i18n.t('academiaCultural')}
//                         name="b"
//                         filled
//                         type="checkbox"
//                         id="aCultural"
//                         onChange={this.handleChangeCheckbox}
//                         checked={this.state.aCultural}
//                         validate
//                         className={this.state.aCultural||this.state.aDeportiva||this.state.aTecnologica||this.state.aOtra ? "is-valid" : "is-invalid"}
//                          >
//                      </MDBInput>
//                      <MDBInput name="b" label={i18n.t('academiaDeportiva')} filled type="checkbox" id="aDeportiva"
//                       onChange={this.handleChangeCheckbox} checked={this.state.aDeportiva}
//                       className={this.state.aCultural||this.state.aDeportiva||this.state.aTecnologica||this.state.aOtra ? "is-valid" : "is-invalid"}  />
//                      <MDBInput name="b" label={i18n.t('academiaTecno')} filled type="checkbox" id="aTecnologica"
//                       className={this.state.aCultural||this.state.aDeportiva||this.state.aTecnologica||this.state.aOtra ? "is-valid" : "is-invalid"}
//                       onChange={this.handleChangeCheckbox} checked={this.state.aTecnologica} />
//                       <MDBInput name="b" label={i18n.t('academiaOtra')} filled type="checkbox"
//                        id="aOtra"
//                        onChange={this.handleChangeCheckbox}
//                        className={this.state.aCultural||this.state.aDeportiva||this.state.aTecnologica||this.state.aOtra ? "is-valid" : "is-invalid"}
//                        checked={this.state.aOtra} >
//                       {!(this.state.aCultural||this.state.aDeportiva||this.state.aTecnologica||this.state.aOtra) ? (<div className="invalid-feedback">
//                         {i18n.t('proveAcademia')}
//                       </div>):null}
//                       </MDBInput>
//                    </div>
//                  </MDBCollapse>
//
//               </div>
//             </MDBCol>
//             </MDBRow>
//             <MDBRow>
//              <MDBCol md="10">
//
//              <MDBInput
//                   label={<a href='https://b44e4152-aeb6-446b-9ff9-db89f0a2ad28.filesusr.com/ugd/850e72_7a5399b364184a9d8fe7fb21bcfa66f5.pdf' target='_blank'>Aviso Privacidad</a>}
//                   type="checkbox"
//                   group
//                   validate
//                   id="invalidCheck"
//                   required
//                   onChange={this.handleChangeCheckbox}
//               >
//               <div className="invalid-feedback">
//                   Debe estar de acuerdo antes de aceptar.
//               </div>
//               <div className="valid-feedback">{i18n.t('Correcto')}</div>
//               </MDBInput>
//
//            <div className="text-center">
//              <MDBBtn color="primary" type="submit">Registrar</MDBBtn>
//              <div className="center red-text">
//              { dataError ? dataError.map((x) => {return (<p>{x.message}</p>)}) : null }
//              { authError ? (<p>{authError}</p>) : null }
//              </div>
//            </div>
//            </MDBCol>
//      </MDBRow>
//      </form>
//      </MDBCol>
//      </MDBRow>
//      </MDBContainer>
//     )
//   }
 }

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    dataError: state.auth.dataError,
  }
}

const mapDispatchToProps = (dispatch)=> {
  return {
    createOrden: (ordencompra) => dispatch(createOrden(ordencompra)),
    signUp: (creds) => dispatch(signUp(creds)),
  }
}

export default compose(
  withFirestore,
	connect(mapStateToProps, mapDispatchToProps),)(SignUpSteps);
