import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { createOrden } from '../../store/actions/ocCreate'
import { uploadFile } from '../../store/actions/authActions'
import { getAUser, getAGoal, updateGoal } from '../../store/actions/createGoal'
import ImagePage from './ImageFile';
import ModalPage from './ModalPage';
import ModalEditor from './ModalEditor';
import Nivel from './Nivel';
import { Redirect } from 'react-router-dom'
import i18n from 'i18next'

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBBtnGroup,
  MDBFormInline,
  MDBStepper,
  MDBStep,
  MDBCardBody,
  MDBCard,
  MDBRangeInput
} from "mdbreact";

// const initialContent =
//     `<br>
//     <h1 style="text-align: center;">Misión, Visión e Historia</h1>
//     <p style="text-align: center;">WYSIWYG Editor</p>
//     <p style="text-align: center;"><a href="https://mdbootstrap.com" target="_blank" contenteditable="false" style="font-size: 1rem; text-align: left;">MDBootstrap.com</a>&nbsp;© 2018</p>
//     <p><b>Features:</b></p>
//     <ul>
//     <li>Changing block type</li>
//     <li>Text formatting (bold, italic, strikethrough, underline)</li>
//     <li>Setting text color</li>
//     <li>Text aligning</li>
//     <li>Inserting links</li>
//     <li>Inserting pictures</li>
//     <li>Creating a list (bulled or numbered)</li>
//     </ul>
//     <p><b>Options:</b></p>
//     <ul>
//     <li>Translations</li>
//     <li>Using your own color palette</li>
//     <li>Disabling/enabling tooltips</li>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</ul>
//     `;
function createMarkup(data) {
  return {__html: data};
}
function TextComponent(props) {
  return <div dangerouslySetInnerHTML={createMarkup(props.data)} />;
}
function enhance(WrappedNivel, handleChange) {
  return class extends React.Component {
    render() {
      const extraProp = 'maternal';
      return (
        <div className="Wrapper">
          <WrappedNivel
            {...this.props}
            extraProp={extraProp}
          />
        </div>
      );
    }
  }
}
const NivelWrapper = enhance(Nivel);
class CreateOC extends Component {
  constructor(props) {
    super(props);
    this.setFilter = this.setFilter.bind(this);
    this.copyValues = this.copyValues.bind(this);
  }
  state = {
    isModalOpen: false,
    isEditorOpen: false,
    isCreated: false,
    maternal_idiomas: [
        {id: 1, value: "Francés", isChecked: false},
        {id: 2, value: "Italiano", isChecked: false},
        {id: 3, value: "Alemán", isChecked: false},
        {id: 4, value: "Japonés", isChecked: false},
        {id: 5, value: "Mandarín", isChecked: false},
        {id: 6, value: "Portugués", isChecked: false},
        {id: 7, value: "Coreano", isChecked: false},
        {id: 8, value: "Ruso", isChecked: false}
    ],
    kinder_idiomas: [
        {id: 1, value: "Francés", isChecked: false},
        {id: 2, value: "Italiano", isChecked: false},
        {id: 3, value: "Alemán", isChecked: false},
        {id: 4, value: "Japonés", isChecked: false},
        {id: 5, value: "Mandarín", isChecked: false},
        {id: 6, value: "Portugués", isChecked: false},
        {id: 7, value: "Coreano", isChecked: false},
        {id: 8, value: "Ruso", isChecked: false}
    ],
    primaria_idiomas: [
        {id: 1, value: "Francés", isChecked: false},
        {id: 2, value: "Italiano", isChecked: false},
        {id: 3, value: "Alemán", isChecked: false},
        {id: 4, value: "Japonés", isChecked: false},
        {id: 5, value: "Mandarín", isChecked: false},
        {id: 6, value: "Portugués", isChecked: false},
        {id: 7, value: "Coreano", isChecked: false},
        {id: 8, value: "Ruso", isChecked: false}
    ],
    secundaria_idiomas: [
        {id: 1, value: "Francés", isChecked: false},
        {id: 2, value: "Italiano", isChecked: false},
        {id: 3, value: "Alemán", isChecked: false},
        {id: 4, value: "Japonés", isChecked: false},
        {id: 5, value: "Mandarín", isChecked: false},
        {id: 6, value: "Portugués", isChecked: false},
        {id: 7, value: "Coreano", isChecked: false},
        {id: 8, value: "Ruso", isChecked: false}
    ],
    bachillerato_idiomas: [
        {id: 1, value: "Francés", isChecked: false},
        {id: 2, value: "Italiano", isChecked: false},
        {id: 3, value: "Alemán", isChecked: false},
        {id: 4, value: "Japonés", isChecked: false},
        {id: 5, value: "Mandarín", isChecked: false},
        {id: 6, value: "Portugués", isChecked: false},
        {id: 7, value: "Coreano", isChecked: false},
        {id: 8, value: "Ruso", isChecked: false}
    ],
    infraestructura: [
        {id: "canchas_basquet", name: "Básquetbol", value: "0"},
        {id: "canchas_volei", name: "Vóleibol", value: "0"},
        {id: "canchas_futbol", name: "Futbol", value: "0"},
        {id: "canchas_soccer", name: "Soccer", value: "0"},
        {id: "canchas_tenis", name: "Tenis", value: "0"},
        {id: "canchas_alberca", name: "Alberca", value: "0"},
        {id: "canchas_arenero", name: "Arenero", value: "0"},
        {id: "canchas_beisbol", name: "Béisbol", value: "0"},
        {id: "canchas_areajuegos", name: "Área de Juegos", value: "0"},
        {id: "canchas_otros", name: "Otros", value: "0"},
        {id: "lab_quimica", name: "Química", value: "0"},
        {id: "lab_cocina", name: "Cocina", value: "0"},
        {id: "lab_electricidad", name: "Electricidad", value: "0"},
        {id: "lab_dibujo", name: "Dibujo", value: "0"},
        {id: "lab_musica", name: "Música", value: "0"},
        {id: "lab_computacion", name: "Computación", value: "0"},
        {id: "lab_huertos", name: "Huertos", value: "0"},
        {id: "lab_biblioteca", name: "Biblioteca", value: "0"},
        {id: "lab_otras", name: "Otras", value: "0"},
        {id: "instalaciones_oficina", name: "Oficinas", value: "0"},
        {id: "instalaciones_comedor", name: "Comedor", value: "0"},
        {id: "instalaciones_banios", name: "Baños", value: "0"},
        {id: "instalaciones_vestidores", name: "Vestidores", value: "0"},
        {id: "instalaciones_abiente_digital", name: "Ambiente digital", value: "0"},
        {id: "instalaciones_auditorio", name: "Auditorio", value: "0"},
        {id: "instalaciones_bodega", name: "Bodega", value: "0"},
        {id: "instalaciones_jardines", name: "Jardines", value: "0"},
        {id: "instalaciones_cajones", name: "Cajones", value: "0"},
        {id: "instalaciones_otras", name: "Otras", value: "0"}
    ],
    extracur: [
      {id: "deportivas_basketbol", name: "Básquetbol", desc:"", value:"0"},
      {id: "deportivas_volei", name: "Vóleibol", desc:"", value: "0"},
      {id: "deportivas_futbol", name: "Futbol", desc:"", value: "0"},
      {id: "deportivas_soccer", name: "Soccer", desc:"", value: "0"},
      {id: "deportivas_beisbol", name: "Béisbol", desc:"", value: "0"},
      {id: "deportivas_atletismo", name: "Atletismo", desc:"", value: "0"},
      {id: "deportivas_porristas", name: "Porristas", desc:"", value: "0"},
      {id: "deportivas_natacion", name: "Natación", desc:"", value: "0"},
      {id: "deportivas_tae_kwon_do", name: "Tae Kwon Do", desc:"", value: "0"},
      {id: "deportivas_otros", name: "Otros", desc:"", value: "0"},
      {id: "cultural_ajedrez", name: "Ajedrez", desc: "", value: "0"},
      {id: "cultural_oratoria", name: "Oratoria", desc: "", value: "0"},
      {id: "cultural_debate", name: "Debate", desc: "", value: "0"},
      {id: "cultural_pintura", name: "Pintura", desc: "", value: "0"},
      {id: "cultural_danza", name: "Danza", desc: "", value: "0"},
      {id: "cultural_teatro", name: "Teatro", desc: "", value: "0"},
      {id: "cultural_cocina", name: "Cocina", desc: "", value: "0"},
      {id: "cultural_banda",name: "Banda", desc: "", value: "0"},
      {id: "cultural_otros", name: "Otros", desc: "", value: "0"},
      {id: "academica_matematicas", name: "Matemáticas", desc: "", value: "0"},
      {id: "academica_entomologia", name: "Entomología", desc: "", value: "0"},
      {id: "academica_botanica", name: "Botánica", desc: "", value: "0"},
      {id: "academica_fisica", name: "Física", desc: "", value: "0"},
      {id: "academica_literatura", name: "Literatura", desc: "", value: "0"},
      {id: "academica_otros", name: "Otros", desc: "", value: "0"},
      {id: "tecnologicas_huerto", name: "Huerto", desc: "", value: "0"},
      {id: "tecnologicas_robotica", name: "Robótica", desc: "", value: "0"},
      {id: "tecnologicas_programacion", name: "Programación", desc: "", value: "0"},
      {id: "tecnologicas_edicion", name: "Edición de foto o Video", desc: "", value: "0"},
      {id: "tecnologicas_radio", name: "Rádio", desc: "", value: "0"},
      {id: "tecnologicas_electricidad", name: "Electricidad", desc: "", value: "0"},
      {id: "tecnologicas_otros", name: "Otros", desc: "", value: "0"}
    ],
    servicios: [
      {id: "academicos_lms", name: "LMS", desc: "", value: "0"},
      {id: "academicos_apps", name: "App’s", desc: "", value: "0"},
      {id: "academicos_cert_ingles", name: "Certificación docente Inglés", desc: "", value: "0"},
      {id: "academicos_cert_metodo", name: "Certificación docente Metodológica", desc: "", value: "0"},
      {id: "academicos_cert_iso", name: "Certificación docente ISO", desc: "", value: "0"},
      {id: "academicos_otros", name: "Otros", desc: "", value: "0"},
      {id: "administracion_plataforma", name: "Plataforma de pagos", desc: "", value: "0"},
      {id: "administracion_domiciliado", name: "Acepta Pago Domiciliado", desc: "", value: "0"},
      {id: "administracion_tdtc", name: "Acepta Pago TD/TC", desc: "", value: "0"},
      {id: "administracion_anual", name: "Acepta Pago Anual", desc: "", value: "0"},
      {id: "administracion_cheque", name: "Acepta Pago Cheque", desc: "", value: "0"},
      {id: "administracion_descuento", name: "Acepta Pago Descuento de nómina", desc: "", value: "0"},
      {id: "administracion_otros", name: "Otros", desc: "", value: "0"},
      {id: "comunicacion_web", name: "Página Web", desc: "", value: "0"},
      {id: "comunicacion_apps", name: "App´s", desc: "", value: "0"},
      {id: "comunicacion_facebook", name: "Facebook", desc: "", value: "0"},
      {id: "comunicacion_twiter", name: "Twiter", desc: "", value: "0"},
      {id: "comunicacion_whatsapp", name: "Whatsapp", desc: "", value: "0"},
      {id: "comunicacion_youtube", name: "Youtube", desc: "", value: "0"},
      {id: "comunicacion_webinarios", name: "Webinarios", desc: "", value: "0"},
      {id: "comunicacion_otros", name: "Otros", desc: "", value: "0"},
      {id: "seguridad_enfermera", name: "Enfermera", desc: "", value: "0"},
      {id: "seguridad_psicologo", name: "Psicólogo", desc: "", value: "0"},
      {id: "seguridad_guardia", name: "Guardia", desc: "", value: "0"},
      {id: "seguridad_cctv", name: "CCTV", desc: "", value: "0"},
      {id: "seguridad_primerrespondiente", name: "Certificación Primer Respondiente", desc: "", value: "0"},
      {id: "seguridad_ambulancia", name: "Ambulancia", desc: "", value: "0"},
      {id: "seguridad_otros", name: "Otros", desc: "", value: "0"},
      {id: "adicional_transporte", name: "Transporte", desc: "", value: "0"},
      {id: "adicional_alimentacion", name: "Programa de alimentación", desc: "", value: "0"},
      {id: "adicional_menu", name: "Menú Nutritivo", desc: "", value: "0"},
      {id: "adicional_cafeteria", name: "Cafetería de padres", desc: "", value: "0"},
      {id: "adicional_otros", name: "Otros", desc: "", value: "0"},
      {id: "alianzas_otros", name: "Otros", desc: "", value: "0"}
    ],
    imagenes: [
      {id: "foto_num1", name: "Escudo", desc: "", value: ""},
      {id: "foto_num2", name: "Institucional", desc: "", value: ""},
      {id: "foto_num3", name: "Foto 1", desc: "", value: ""},
      {id: "foto_num4", name: "Foto 2", desc: "", value: ""},
      {id: "foto_num5", name: "Foto 3", desc: "", value: ""},
      {id: "foto_num6", name: "Foto 4", desc: "", value: ""},
      {id: "foto_num7", name: "Foto 5", desc: "", value: ""},
      {id: "foto_num8", name: "Foto 6", desc: "", value: ""},
      {id: "foto_num9", name: "Foto 7", desc: "", value: ""},
      {id: "foto_num10", name: "Foto 8", desc: "", value: ""},
      {id: "foto_num11", name: "Foto 9", desc: "", value: ""},
      {id: "foto_num12", name: "Foto 10", desc: "", value: ""},
      {id: "foto_num13", name: "Foto 11", desc: "", value: ""},
      {id: "foto_num14", name: "Foto 12", desc: "", value: ""},
      {id: "foto_num15", name: "Foto 13", desc: "", value: ""},
      {id: "foto_num16", name: "Foto 14", desc: "", value: ""},
      {id: "foto_num17", name: "Foto 15", desc: "", value: ""},
      {id: "foto_num18", name: "Foto 16", desc: "", value: ""},
      {id: "foto_num19", name: "Foto 17", desc: "", value: ""},
      {id: "foto_num20", name: "Foto 18", desc: "", value: ""}
    ],
    currentNivel: '',
    activeItem: '0',
    name: '',
    invalidCheck: false,
    city: '',
    state: '',
    street: '',
    numExt: '',
    numInt: '',
    colonia: '',
    zip: '',
    municipio: '',
    entreCalles: '',
    phone: '',
    webpage: '',
    url: '',
    rsFacebook: '',
    rsTwitter: '',
    rsInstagram: '',
    rsLinkedIn: '',
    rsYouTube: '',
    rsGoogle: '',
    directorName: '',
    directorEmail: '',
    directorPhone: '',
    enlaceName: '',
    enlaceEmail: '',
    enlacePhone: '',
    admisionesName: '',
    admisionesEmail: '',
    admisionesPhone: '',
    recepcionName: '',
    recepcionEmail: '',
    recepcionPhone: '',
    rfc: '',
    nombreFiscal: '',
    usoCFDI: '',
    tipoPago: '',
    condicionesPago: '',
    observacionesPago: '',
    representanteLegal: '',
    fechaInicio: '',
    historia: '<br>Historia, Visión y Misión</br>',
    lema: '',
    CCT: '',
    uploadImages: {
      foto_num1: {desc: '', url: ''},
      foto_num2: {desc: '', url: ''}
    },
    imagen_promocional: "",
    imagen_promocional_desc: "",
    video_institucional: "",
    video1: "",
    video2: "",
    video3: "",
    url_entrevista_dir: "",
    url_articulos_publicados: "",
    url_reportajes_y_mas: "",
    location: {lat: 0, lng: 0},
    maternal_encuestaSatisfaccion: 0,
    maternal_montoAnualizado: "0",
    maternal_metodologia_laica: true,
    maternal_redEscuelas: false,
    maternal_constructivista: 0,
    maternal_conductista: 0,
    maternal_metacademico:0,
    maternal_humanista:0,
    maternal_tics:0,
    maternal_porproyectos:0,
    maternal_tradicional: 0,
    maternal_flipped: 0,
    maternal_designthinking: 0,
    maternal_ibo: 0,
    maternal_methodology: [],
    maternal_element_clase_tareas: 0,
    maternal_element_clase_valores: 0,
    maternal_element_clase_religion: 0,
    maternal_element_clase_computacion: 0,
    maternal_element_clase_arte: 0,
    maternal_element_clase_sustentabilidad: 0,
    maternal_element_clase_intelig_emocional: 0,
    maternal_element_clase_otros: 0,
    maternal_idioma_horas: "",
    maternal_idioma_modelo: "",
    maternal_idioma_mcer: "",
    maternal_idioma_extracurr: [],
    maternal_idioma_otros: "",
    maternal_total_alumnos_capacidad: 0,
    maternal_total_alumnos_actual: 0,
    maternal_capacidad_alumnos_por_aula: 0,
    maternal_promedio_alumnos_por_aula: 0,
    maternal_canchas_basquet: 0,
    maternal_canchas_volei: 0,
    maternal_canchas_futbol: 0,
    maternal_canchas_soccer: 0,
    maternal_canchas_tenis: 0,
    maternal_canchas_alberca: 0,
    maternal_canchas_arenero: 0,
    maternal_canchas_beisbol: 0,
    maternal_canchas_areajuegos: 0,
    maternal_canchas_otros: 0,
    maternal_lab_quimica: 0,
    maternal_lab_cocina: 0,
    maternal_lab_electricidad: 0,
    maternal_lab_dibujo: 0,
    maternal_lab_musica: 0,
    maternal_lab_computacion: 0,
    maternal_lab_huertos: 0,
    maternal_lab_biblioteca: 0,
    maternal_lab_otras: 0,
    maternal_instalaciones_oficina: 0,
    maternal_instalaciones_comedor: 0,
    maternal_instalaciones_banios: 0,
    maternal_instalaciones_vestidores: 0,
    maternal_instalaciones_abiente_digital: 0,
    maternal_instalaciones_auditorio: 0,
    maternal_instalaciones_bodega: 0,
    maternal_instalaciones_jardines: 0,
    maternal_instalaciones_cajones: 0,
    maternal_instalaciones_otras: 0,
    maternal_deportivas_basketbol: 0,
    maternal_deportivas_basketbol_desc: "",
    maternal_deportivas_volei: 0,
    maternal_deportivas_volei_desc: "",
    maternal_deportivas_futbol: 0,
    maternal_deportivas_futbol_desc: "",
    maternal_deportivas_soccer: 0,
    maternal_deportivas_soccer_desc: "",
    maternal_deportivas_beisbol: 0,
    maternal_deportivas_beisbol_desc: "",
    maternal_deportivas_atletismo: 0,
    maternal_deportivas_atletismo_desc: "",
    maternal_deportivas_porristas: 0,
    maternal_deportivas_porristas_desc: "",
    maternal_deportivas_natacion: 0,
    maternal_deportivas_natacion_desc: "",
    maternal_deportivas_tae_kwon_do: 0,
    maternal_deportivas_tae_kwon_do_desc: "",
    maternal_deportivas_otros: 0,
    maternal_deportivas_otros_desc: "",
    maternal_cultural_ajedrez: 0,
    maternal_cultural_oratoria: 0,
    maternal_cultural_debate: 0,
    maternal_cultural_pintura: 0,
    maternal_cultural_danza: 0,
    maternal_cultural_teatro: 0,
    maternal_cultural_cocina: 0,
    maternal_cultural_banda: 0,
    maternal_cultural_otros: 0,
    maternal_academica_matematicas: 0,
    maternal_academica_entomologia: 0,
    maternal_academica_botanica: 0,
    maternal_academica_fisica: 0,
    maternal_academica_literatura: 0,
    maternal_academica_otros: 0,
    maternal_tecnologicas_huerto: 0,
    maternal_tecnologicas_robotica: 0,
    maternal_tecnologicas_programacion: 0,
    maternal_tecnologicas_edicion: 0,
    maternal_tecnologicas_radio: 0,
    maternal_tecnologicas_electricidad: 0,
    maternal_tecnologicas_otros: 0,
    maternal_cultural_ajedrez_desc: "",
    maternal_cultural_oratoria_desc: "",
    maternal_cultural_debate_desc: "",
    maternal_cultural_pintura_desc: "",
    maternal_cultural_danza_desc: "",
    maternal_cultural_teatro_desc: "",
    maternal_cultural_cocina_desc: "",
    maternal_cultural_banda_desc: "",
    maternal_cultural_otros_desc: "",
    maternal_academica_matematicas_desc: "",
    maternal_academica_entomologia_desc: "",
    maternal_academica_botanica_desc: "",
    maternal_academica_fisica_desc: "",
    maternal_academica_literatura_desc: "",
    maternal_academica_otros_desc: "",
    maternal_tecnologicas_huerto_desc: "",
    maternal_tecnologicas_robotica_desc: "",
    maternal_tecnologicas_programacion_desc: "",
    maternal_tecnologicas_edicion_desc: "",
    maternal_tecnologicas_radio_desc: "",
    maternal_tecnologicas_electricidad_desc: "",
    maternal_tecnologicas_otros_desc: "",
    maternal_academicos_lms: 0,
    maternal_academicos_apps: 0,
    maternal_academicos_cert_ingles: 0,
    maternal_academicos_cert_metodo: 0,
    maternal_academicos_cert_iso: 0,
    maternal_academicos_otros: 0,
    maternal_administracion_plataforma: 0,
    maternal_administracion_domiciliado: 0,
    maternal_administracion_tdtc: 0,
    maternal_administracion_anual: 0,
    maternal_administracion_cheque: 0,
    maternal_administracion_descuento: 0,
    maternal_administracion_otros: 0,
    maternal_comunicacion_web: 0,
    maternal_comunicacion_apps: 0,
    maternal_comunicacion_facebook: 0,
    maternal_comunicacion_twiter: 0,
    maternal_comunicacion_whatsapp: 0,
    maternal_comunicacion_youtube: 0,
    maternal_comunicacion_webinarios: 0,
    maternal_comunicacion_otros: 0,
    maternal_seguridad_enfermera: 0,
    maternal_seguridad_psicologo: 0,
    maternal_seguridad_guardia: 0,
    maternal_seguridad_cctv: 0,
    maternal_seguridad_primerrespondiente: 0,
    maternal_seguridad_ambulancia: 0,
    maternal_seguridad_otros: 0,
    maternal_adicional_transporte: 0,
    maternal_adicional_alimentacion: 0,
    maternal_adicional_menu: 0,
    maternal_adicional_cafeteria: 0,
    maternal_adicional_otros: 0,
    maternal_alianzas_otros: 0,
    maternal_academicos_lms_desc: "",
    maternal_academicos_apps_desc: "",
    maternal_academicos_cert_ingles_desc: "",
    maternal_academicos_cert_metodo_desc: "",
    maternal_academicos_cert_iso_desc: "",
    maternal_academicos_otros_desc: "",
    maternal_administracion_plataforma_desc: "",
    maternal_administracion_domiciliado_desc: "",
    maternal_administracion_tdtc_desc: "",
    maternal_administracion_anual_desc: "",
    maternal_administracion_cheque_desc: "",
    maternal_administracion_descuento_desc: "",
    maternal_administracion_otros_desc: "",
    maternal_comunicacion_web_desc: "",
    maternal_comunicacion_apps_desc: "",
    maternal_comunicacion_facebook_desc: "",
    maternal_comunicacion_twiter_desc: "",
    maternal_comunicacion_whatsapp_desc: "",
    maternal_comunicacion_youtube_desc: "",
    maternal_comunicacion_webinarios_desc: "",
    maternal_comunicacion_otros_desc: "",
    maternal_seguridad_enfermera_desc: "",
    maternal_seguridad_psicologo_desc: "",
    maternal_seguridad_guardia_desc: "",
    maternal_seguridad_cctv_desc: "",
    maternal_seguridad_primerrespondiente_desc: "",
    maternal_seguridad_ambulancia_desc: "",
    maternal_seguridad_otros_desc: "",
    maternal_adicional_transporte_desc: "",
    maternal_adicional_alimentacion_desc: "",
    maternal_adicional_menu_desc: "",
    maternal_adicional_cafeteria_desc: "",
    maternal_adicional_otros_desc: "",
    maternal_alianzas_otros_desc: "",
    kinder_encuestaSatisfaccion: 0,
    kinder_montoAnualizado: "0",
    kinder_metodologia_laica: true,
    kinder_redEscuelas: false,
    kinder_constructivista: 0,
    kinder_conductista: 0,
    kinder_metacademico:0,
    kinder_humanista:0,
    kinder_tics:0,
    kinder_porproyectos:0,
    kinder_tradicional: 0,
    kinder_flipped: 0,
    kinder_designthinking: 0,
    kinder_ibo: 0,
    kinder_methodology: [],
    kinder_element_clase_tareas: 0,
    kinder_element_clase_valores: 0,
    kinder_element_clase_religion: 0,
    kinder_element_clase_computacion: 0,
    kinder_element_clase_arte: 0,
    kinder_element_clase_sustentabilidad: 0,
    kinder_element_clase_intelig_emocional: 0,
    kinder_element_clase_otros: 0,
    kinder_idioma_horas: "",
    kinder_idioma_modelo: "",
    kinder_idioma_mcer: "",
    kinder_idioma_extracurr: [],
    kinder_idioma_otros: "",
    kinder_total_alumnos_capacidad: 0,
    kinder_total_alumnos_actual: 0,
    kinder_capacidad_alumnos_por_aula: 0,
    kinder_promedio_alumnos_por_aula: 0,
    kinder_canchas_basquet: 0,
    kinder_canchas_volei: 0,
    kinder_canchas_futbol: 0,
    kinder_canchas_soccer: 0,
    kinder_canchas_tenis: 0,
    kinder_canchas_alberca: 0,
    kinder_canchas_arenero: 0,
    kinder_canchas_beisbol: 0,
    kinder_canchas_areajuegos: 0,
    kinder_canchas_otros: 0,
    kinder_lab_quimica: 0,
    kinder_lab_cocina: 0,
    kinder_lab_electricidad: 0,
    kinder_lab_dibujo: 0,
    kinder_lab_musica: 0,
    kinder_lab_computacion: 0,
    kinder_lab_huertos: 0,
    kinder_lab_biblioteca: 0,
    kinder_lab_otras: 0,
    kinder_instalaciones_oficina: 0,
    kinder_instalaciones_comedor: 0,
    kinder_instalaciones_banios: 0,
    kinder_instalaciones_vestidores: 0,
    kinder_instalaciones_abiente_digital: 0,
    kinder_instalaciones_auditorio: 0,
    kinder_instalaciones_bodega: 0,
    kinder_instalaciones_jardines: 0,
    kinder_instalaciones_cajones: 0,
    kinder_instalaciones_otras: 0,
    kinder_deportivas_basketbol: 0,
    kinder_deportivas_basketbol_desc: "",
    kinder_deportivas_volei: 0,
    kinder_deportivas_volei_desc: "",
    kinder_deportivas_futbol: 0,
    kinder_deportivas_futbol_desc: "",
    kinder_deportivas_soccer: 0,
    kinder_deportivas_soccer_desc: "",
    kinder_deportivas_beisbol: 0,
    kinder_deportivas_beisbol_desc: "",
    kinder_deportivas_atletismo: 0,
    kinder_deportivas_atletismo_desc: "",
    kinder_deportivas_porristas: 0,
    kinder_deportivas_porristas_desc: "",
    kinder_deportivas_natacion: 0,
    kinder_deportivas_natacion_desc: "",
    kinder_deportivas_tae_kwon_do: 0,
    kinder_deportivas_tae_kwon_do_desc: "",
    kinder_deportivas_otros: 0,
    kinder_deportivas_otros_desc: "",
    kinder_cultural_ajedrez: 0,
    kinder_cultural_oratoria: 0,
    kinder_cultural_debate: 0,
    kinder_cultural_pintura: 0,
    kinder_cultural_danza: 0,
    kinder_cultural_teatro: 0,
    kinder_cultural_cocina: 0,
    kinder_cultural_banda: 0,
    kinder_cultural_otros: 0,
    kinder_academica_matematicas: 0,
    kinder_academica_entomologia: 0,
    kinder_academica_botanica: 0,
    kinder_academica_fisica: 0,
    kinder_academica_literatura: 0,
    kinder_academica_otros: 0,
    kinder_tecnologicas_huerto: 0,
    kinder_tecnologicas_robotica: 0,
    kinder_tecnologicas_programacion: 0,
    kinder_tecnologicas_edicion: 0,
    kinder_tecnologicas_radio: 0,
    kinder_tecnologicas_electricidad: 0,
    kinder_tecnologicas_otros: 0,
    kinder_cultural_ajedrez_desc: "",
    kinder_cultural_oratoria_desc: "",
    kinder_cultural_debate_desc: "",
    kinder_cultural_pintura_desc: "",
    kinder_cultural_danza_desc: "",
    kinder_cultural_teatro_desc: "",
    kinder_cultural_cocina_desc: "",
    kinder_cultural_banda_desc: "",
    kinder_cultural_otros_desc: "",
    kinder_academica_matematicas_desc: "",
    kinder_academica_entomologia_desc: "",
    kinder_academica_botanica_desc: "",
    kinder_academica_fisica_desc: "",
    kinder_academica_literatura_desc: "",
    kinder_academica_otros_desc: "",
    kinder_tecnologicas_huerto_desc: "",
    kinder_tecnologicas_robotica_desc: "",
    kinder_tecnologicas_programacion_desc: "",
    kinder_tecnologicas_edicion_desc: "",
    kinder_tecnologicas_radio_desc: "",
    kinder_tecnologicas_electricidad_desc: "",
    kinder_tecnologicas_otros_desc: "",
    kinder_academicos_lms: 0,
    kinder_academicos_apps: 0,
    kinder_academicos_cert_ingles: 0,
    kinder_academicos_cert_metodo: 0,
    kinder_academicos_cert_iso: 0,
    kinder_academicos_otros: 0,
    kinder_administracion_plataforma: 0,
    kinder_administracion_domiciliado: 0,
    kinder_administracion_tdtc: 0,
    kinder_administracion_anual: 0,
    kinder_administracion_cheque: 0,
    kinder_administracion_descuento: 0,
    kinder_administracion_otros: 0,
    kinder_comunicacion_web: 0,
    kinder_comunicacion_apps: 0,
    kinder_comunicacion_facebook: 0,
    kinder_comunicacion_twiter: 0,
    kinder_comunicacion_whatsapp: 0,
    kinder_comunicacion_youtube: 0,
    kinder_comunicacion_webinarios: 0,
    kinder_comunicacion_otros: 0,
    kinder_seguridad_enfermera: 0,
    kinder_seguridad_psicologo: 0,
    kinder_seguridad_guardia: 0,
    kinder_seguridad_cctv: 0,
    kinder_seguridad_primerrespondiente: 0,
    kinder_seguridad_ambulancia: 0,
    kinder_seguridad_otros: 0,
    kinder_adicional_transporte: 0,
    kinder_adicional_alimentacion: 0,
    kinder_adicional_menu: 0,
    kinder_adicional_cafeteria: 0,
    kinder_adicional_otros: 0,
    kinder_alianzas_otros: 0,
    kinder_academicos_lms_desc: "",
    kinder_academicos_apps_desc: "",
    kinder_academicos_cert_ingles_desc: "",
    kinder_academicos_cert_metodo_desc: "",
    kinder_academicos_cert_iso_desc: "",
    kinder_academicos_otros_desc: "",
    kinder_administracion_plataforma_desc: "",
    kinder_administracion_domiciliado_desc: "",
    kinder_administracion_tdtc_desc: "",
    kinder_administracion_anual_desc: "",
    kinder_administracion_cheque_desc: "",
    kinder_administracion_descuento_desc: "",
    kinder_administracion_otros_desc: "",
    kinder_comunicacion_web_desc: "",
    kinder_comunicacion_apps_desc: "",
    kinder_comunicacion_facebook_desc: "",
    kinder_comunicacion_twiter_desc: "",
    kinder_comunicacion_whatsapp_desc: "",
    kinder_comunicacion_youtube_desc: "",
    kinder_comunicacion_webinarios_desc: "",
    kinder_comunicacion_otros_desc: "",
    kinder_seguridad_enfermera_desc: "",
    kinder_seguridad_psicologo_desc: "",
    kinder_seguridad_guardia_desc: "",
    kinder_seguridad_cctv_desc: "",
    kinder_seguridad_primerrespondiente_desc: "",
    kinder_seguridad_ambulancia_desc: "",
    kinder_seguridad_otros_desc: "",
    kinder_adicional_transporte_desc: "",
    kinder_adicional_alimentacion_desc: "",
    kinder_adicional_menu_desc: "",
    kinder_adicional_cafeteria_desc: "",
    kinder_adicional_otros_desc: "",
    kinder_alianzas_otros_desc: "",
    primaria_encuestaSatisfaccion: 0,
    primaria_montoAnualizado: "0",
    primaria_metodologia_laica: true,
    primaria_redEscuelas: false,
    primaria_constructivista: 0,
    primaria_conductista: 0,
    primaria_metacademico:0,
    primaria_humanista:0,
    primaria_tics:0,
    primaria_porproyectos:0,
    primaria_tradicional: 0,
    primaria_flipped: 0,
    primaria_designthinking: 0,
    primaria_ibo: 0,
    primaria_methodology: [],
    primaria_element_clase_tareas: 0,
    primaria_element_clase_valores: 0,
    primaria_element_clase_religion: 0,
    primaria_element_clase_computacion: 0,
    primaria_element_clase_arte: 0,
    primaria_element_clase_sustentabilidad: 0,
    primaria_element_clase_intelig_emocional: 0,
    primaria_element_clase_otros: 0,
    primaria_idioma_horas: "",
    primaria_idioma_modelo: "",
    primaria_idioma_mcer: "",
    primaria_idioma_extracurr: [],
    primaria_idioma_otros: "",
    primaria_total_alumnos_capacidad: 0,
    primaria_total_alumnos_actual: 0,
    primaria_capacidad_alumnos_por_aula: 0,
    primaria_promedio_alumnos_por_aula: 0,
    primaria_canchas_basquet: 0,
    primaria_canchas_volei: 0,
    primaria_canchas_futbol: 0,
    primaria_canchas_soccer: 0,
    primaria_canchas_tenis: 0,
    primaria_canchas_alberca: 0,
    primaria_canchas_arenero: 0,
    primaria_canchas_beisbol: 0,
    primaria_canchas_areajuegos: 0,
    primaria_canchas_otros: 0,
    primaria_lab_quimica: 0,
    primaria_lab_cocina: 0,
    primaria_lab_electricidad: 0,
    primaria_lab_dibujo: 0,
    primaria_lab_musica: 0,
    primaria_lab_computacion: 0,
    primaria_lab_huertos: 0,
    primaria_lab_biblioteca: 0,
    primaria_lab_otras: 0,
    primaria_instalaciones_oficina: 0,
    primaria_instalaciones_comedor: 0,
    primaria_instalaciones_banios: 0,
    primaria_instalaciones_vestidores: 0,
    primaria_instalaciones_abiente_digital: 0,
    primaria_instalaciones_auditorio: 0,
    primaria_instalaciones_bodega: 0,
    primaria_instalaciones_jardines: 0,
    primaria_instalaciones_cajones: 0,
    primaria_instalaciones_otras: 0,
    primaria_deportivas_basketbol: 0,
    primaria_deportivas_basketbol_desc: "",
    primaria_deportivas_volei: 0,
    primaria_deportivas_volei_desc: "",
    primaria_deportivas_futbol: 0,
    primaria_deportivas_futbol_desc: "",
    primaria_deportivas_soccer: 0,
    primaria_deportivas_soccer_desc: "",
    primaria_deportivas_beisbol: 0,
    primaria_deportivas_beisbol_desc: "",
    primaria_deportivas_atletismo: 0,
    primaria_deportivas_atletismo_desc: "",
    primaria_deportivas_porristas: 0,
    primaria_deportivas_porristas_desc: "",
    primaria_deportivas_natacion: 0,
    primaria_deportivas_natacion_desc: "",
    primaria_deportivas_tae_kwon_do: 0,
    primaria_deportivas_tae_kwon_do_desc: "",
    primaria_deportivas_otros: 0,
    primaria_deportivas_otros_desc: "",
    primaria_cultural_ajedrez: 0,
    primaria_cultural_oratoria: 0,
    primaria_cultural_debate: 0,
    primaria_cultural_pintura: 0,
    primaria_cultural_danza: 0,
    primaria_cultural_teatro: 0,
    primaria_cultural_cocina: 0,
    primaria_cultural_banda: 0,
    primaria_cultural_otros: 0,
    primaria_academica_matematicas: 0,
    primaria_academica_entomologia: 0,
    primaria_academica_botanica: 0,
    primaria_academica_fisica: 0,
    primaria_academica_literatura: 0,
    primaria_academica_otros: 0,
    primaria_tecnologicas_huerto: 0,
    primaria_tecnologicas_robotica: 0,
    primaria_tecnologicas_programacion: 0,
    primaria_tecnologicas_edicion: 0,
    primaria_tecnologicas_radio: 0,
    primaria_tecnologicas_electricidad: 0,
    primaria_tecnologicas_otros: 0,
    primaria_cultural_ajedrez_desc: "",
    primaria_cultural_oratoria_desc: "",
    primaria_cultural_debate_desc: "",
    primaria_cultural_pintura_desc: "",
    primaria_cultural_danza_desc: "",
    primaria_cultural_teatro_desc: "",
    primaria_cultural_cocina_desc: "",
    primaria_cultural_banda_desc: "",
    primaria_cultural_otros_desc: "",
    primaria_academica_matematicas_desc: "",
    primaria_academica_entomologia_desc: "",
    primaria_academica_botanica_desc: "",
    primaria_academica_fisica_desc: "",
    primaria_academica_literatura_desc: "",
    primaria_academica_otros_desc: "",
    primaria_tecnologicas_huerto_desc: "",
    primaria_tecnologicas_robotica_desc: "",
    primaria_tecnologicas_programacion_desc: "",
    primaria_tecnologicas_edicion_desc: "",
    primaria_tecnologicas_radio_desc: "",
    primaria_tecnologicas_electricidad_desc: "",
    primaria_tecnologicas_otros_desc: "",
    primaria_academicos_lms: 0,
    primaria_academicos_apps: 0,
    primaria_academicos_cert_ingles: 0,
    primaria_academicos_cert_metodo: 0,
    primaria_academicos_cert_iso: 0,
    primaria_academicos_otros: 0,
    primaria_administracion_plataforma: 0,
    primaria_administracion_domiciliado: 0,
    primaria_administracion_tdtc: 0,
    primaria_administracion_anual: 0,
    primaria_administracion_cheque: 0,
    primaria_administracion_descuento: 0,
    primaria_administracion_otros: 0,
    primaria_comunicacion_web: 0,
    primaria_comunicacion_apps: 0,
    primaria_comunicacion_facebook: 0,
    primaria_comunicacion_twiter: 0,
    primaria_comunicacion_whatsapp: 0,
    primaria_comunicacion_youtube: 0,
    primaria_comunicacion_webinarios: 0,
    primaria_comunicacion_otros: 0,
    primaria_seguridad_enfermera: 0,
    primaria_seguridad_psicologo: 0,
    primaria_seguridad_guardia: 0,
    primaria_seguridad_cctv: 0,
    primaria_seguridad_primerrespondiente: 0,
    primaria_seguridad_ambulancia: 0,
    primaria_seguridad_otros: 0,
    primaria_adicional_transporte: 0,
    primaria_adicional_alimentacion: 0,
    primaria_adicional_menu: 0,
    primaria_adicional_cafeteria: 0,
    primaria_adicional_otros: 0,
    primaria_alianzas_otros: 0,
    primaria_academicos_lms_desc: "",
    primaria_academicos_apps_desc: "",
    primaria_academicos_cert_ingles_desc: "",
    primaria_academicos_cert_metodo_desc: "",
    primaria_academicos_cert_iso_desc: "",
    primaria_academicos_otros_desc: "",
    primaria_administracion_plataforma_desc: "",
    primaria_administracion_domiciliado_desc: "",
    primaria_administracion_tdtc_desc: "",
    primaria_administracion_anual_desc: "",
    primaria_administracion_cheque_desc: "",
    primaria_administracion_descuento_desc: "",
    primaria_administracion_otros_desc: "",
    primaria_comunicacion_web_desc: "",
    primaria_comunicacion_apps_desc: "",
    primaria_comunicacion_facebook_desc: "",
    primaria_comunicacion_twiter_desc: "",
    primaria_comunicacion_whatsapp_desc: "",
    primaria_comunicacion_youtube_desc: "",
    primaria_comunicacion_webinarios_desc: "",
    primaria_comunicacion_otros_desc: "",
    primaria_seguridad_enfermera_desc: "",
    primaria_seguridad_psicologo_desc: "",
    primaria_seguridad_guardia_desc: "",
    primaria_seguridad_cctv_desc: "",
    primaria_seguridad_primerrespondiente_desc: "",
    primaria_seguridad_ambulancia_desc: "",
    primaria_seguridad_otros_desc: "",
    primaria_adicional_transporte_desc: "",
    primaria_adicional_alimentacion_desc: "",
    primaria_adicional_menu_desc: "",
    primaria_adicional_cafeteria_desc: "",
    primaria_adicional_otros_desc: "",
    primaria_alianzas_otros_desc: "",
    secundaria_encuestaSatisfaccion: 0,
    secundaria_montoAnualizado: "0",
    secundaria_metodologia_laica: true,
    secundaria_redEscuelas: false,
    secundaria_constructivista: 0,
    secundaria_conductista: 0,
    secundaria_metacademico:0,
    secundaria_humanista:0,
    secundaria_tics:0,
    secundaria_porproyectos:0,
    secundaria_tradicional: 0,
    secundaria_flipped: 0,
    secundaria_designthinking: 0,
    secundaria_ibo: 0,
    secundaria_methodology: [],
    secundaria_element_clase_tareas: 0,
    secundaria_element_clase_valores: 0,
    secundaria_element_clase_religion: 0,
    secundaria_element_clase_computacion: 0,
    secundaria_element_clase_arte: 0,
    secundaria_element_clase_sustentabilidad: 0,
    secundaria_element_clase_intelig_emocional: 0,
    secundaria_element_clase_otros: 0,
    secundaria_idioma_horas: "",
    secundaria_idioma_modelo: "",
    secundaria_idioma_mcer: "",
    secundaria_idioma_extracurr: [],
    secundaria_idioma_otros: "",
    secundaria_total_alumnos_capacidad: 0,
    secundaria_total_alumnos_actual: 0,
    secundaria_capacidad_alumnos_por_aula: 0,
    secundaria_promedio_alumnos_por_aula: 0,
    secundaria_canchas_basquet: 0,
    secundaria_canchas_volei: 0,
    secundaria_canchas_futbol: 0,
    secundaria_canchas_soccer: 0,
    secundaria_canchas_tenis: 0,
    secundaria_canchas_alberca: 0,
    secundaria_canchas_arenero: 0,
    secundaria_canchas_beisbol: 0,
    secundaria_canchas_areajuegos: 0,
    secundaria_canchas_otros: 0,
    secundaria_lab_quimica: 0,
    secundaria_lab_cocina: 0,
    secundaria_lab_electricidad: 0,
    secundaria_lab_dibujo: 0,
    secundaria_lab_musica: 0,
    secundaria_lab_computacion: 0,
    secundaria_lab_huertos: 0,
    secundaria_lab_biblioteca: 0,
    secundaria_lab_otras: 0,
    secundaria_instalaciones_oficina: 0,
    secundaria_instalaciones_comedor: 0,
    secundaria_instalaciones_banios: 0,
    secundaria_instalaciones_vestidores: 0,
    secundaria_instalaciones_abiente_digital: 0,
    secundaria_instalaciones_auditorio: 0,
    secundaria_instalaciones_bodega: 0,
    secundaria_instalaciones_jardines: 0,
    secundaria_instalaciones_cajones: 0,
    secundaria_instalaciones_otras: 0,
    secundaria_deportivas_basketbol: 0,
    secundaria_deportivas_basketbol_desc: "",
    secundaria_deportivas_volei: 0,
    secundaria_deportivas_volei_desc: "",
    secundaria_deportivas_futbol: 0,
    secundaria_deportivas_futbol_desc: "",
    secundaria_deportivas_soccer: 0,
    secundaria_deportivas_soccer_desc: "",
    secundaria_deportivas_beisbol: 0,
    secundaria_deportivas_beisbol_desc: "",
    secundaria_deportivas_atletismo: 0,
    secundaria_deportivas_atletismo_desc: "",
    secundaria_deportivas_porristas: 0,
    secundaria_deportivas_porristas_desc: "",
    secundaria_deportivas_natacion: 0,
    secundaria_deportivas_natacion_desc: "",
    secundaria_deportivas_tae_kwon_do: 0,
    secundaria_deportivas_tae_kwon_do_desc: "",
    secundaria_deportivas_otros: 0,
    secundaria_deportivas_otros_desc: "",
    secundaria_cultural_ajedrez: 0,
    secundaria_cultural_oratoria: 0,
    secundaria_cultural_debate: 0,
    secundaria_cultural_pintura: 0,
    secundaria_cultural_danza: 0,
    secundaria_cultural_teatro: 0,
    secundaria_cultural_cocina: 0,
    secundaria_cultural_banda: 0,
    secundaria_cultural_otros: 0,
    secundaria_academica_matematicas: 0,
    secundaria_academica_entomologia: 0,
    secundaria_academica_botanica: 0,
    secundaria_academica_fisica: 0,
    secundaria_academica_literatura: 0,
    secundaria_academica_otros: 0,
    secundaria_tecnologicas_huerto: 0,
    secundaria_tecnologicas_robotica: 0,
    secundaria_tecnologicas_programacion: 0,
    secundaria_tecnologicas_edicion: 0,
    secundaria_tecnologicas_radio: 0,
    secundaria_tecnologicas_electricidad: 0,
    secundaria_tecnologicas_otros: 0,
    secundaria_cultural_ajedrez_desc: "",
    secundaria_cultural_oratoria_desc: "",
    secundaria_cultural_debate_desc: "",
    secundaria_cultural_pintura_desc: "",
    secundaria_cultural_danza_desc: "",
    secundaria_cultural_teatro_desc: "",
    secundaria_cultural_cocina_desc: "",
    secundaria_cultural_banda_desc: "",
    secundaria_cultural_otros_desc: "",
    secundaria_academica_matematicas_desc: "",
    secundaria_academica_entomologia_desc: "",
    secundaria_academica_botanica_desc: "",
    secundaria_academica_fisica_desc: "",
    secundaria_academica_literatura_desc: "",
    secundaria_academica_otros_desc: "",
    secundaria_tecnologicas_huerto_desc: "",
    secundaria_tecnologicas_robotica_desc: "",
    secundaria_tecnologicas_programacion_desc: "",
    secundaria_tecnologicas_edicion_desc: "",
    secundaria_tecnologicas_radio_desc: "",
    secundaria_tecnologicas_electricidad_desc: "",
    secundaria_tecnologicas_otros_desc: "",
    secundaria_academicos_lms: 0,
    secundaria_academicos_apps: 0,
    secundaria_academicos_cert_ingles: 0,
    secundaria_academicos_cert_metodo: 0,
    secundaria_academicos_cert_iso: 0,
    secundaria_academicos_otros: 0,
    secundaria_administracion_plataforma: 0,
    secundaria_administracion_domiciliado: 0,
    secundaria_administracion_tdtc: 0,
    secundaria_administracion_anual: 0,
    secundaria_administracion_cheque: 0,
    secundaria_administracion_descuento: 0,
    secundaria_administracion_otros: 0,
    secundaria_comunicacion_web: 0,
    secundaria_comunicacion_apps: 0,
    secundaria_comunicacion_facebook: 0,
    secundaria_comunicacion_twiter: 0,
    secundaria_comunicacion_whatsapp: 0,
    secundaria_comunicacion_youtube: 0,
    secundaria_comunicacion_webinarios: 0,
    secundaria_comunicacion_otros: 0,
    secundaria_seguridad_enfermera: 0,
    secundaria_seguridad_psicologo: 0,
    secundaria_seguridad_guardia: 0,
    secundaria_seguridad_cctv: 0,
    secundaria_seguridad_primerrespondiente: 0,
    secundaria_seguridad_ambulancia: 0,
    secundaria_seguridad_otros: 0,
    secundaria_adicional_transporte: 0,
    secundaria_adicional_alimentacion: 0,
    secundaria_adicional_menu: 0,
    secundaria_adicional_cafeteria: 0,
    secundaria_adicional_otros: 0,
    secundaria_alianzas_otros: 0,
    secundaria_academicos_lms_desc: "",
    secundaria_academicos_apps_desc: "",
    secundaria_academicos_cert_ingles_desc: "",
    secundaria_academicos_cert_metodo_desc: "",
    secundaria_academicos_cert_iso_desc: "",
    secundaria_academicos_otros_desc: "",
    secundaria_administracion_plataforma_desc: "",
    secundaria_administracion_domiciliado_desc: "",
    secundaria_administracion_tdtc_desc: "",
    secundaria_administracion_anual_desc: "",
    secundaria_administracion_cheque_desc: "",
    secundaria_administracion_descuento_desc: "",
    secundaria_administracion_otros_desc: "",
    secundaria_comunicacion_web_desc: "",
    secundaria_comunicacion_apps_desc: "",
    secundaria_comunicacion_facebook_desc: "",
    secundaria_comunicacion_twiter_desc: "",
    secundaria_comunicacion_whatsapp_desc: "",
    secundaria_comunicacion_youtube_desc: "",
    secundaria_comunicacion_webinarios_desc: "",
    secundaria_comunicacion_otros_desc: "",
    secundaria_seguridad_enfermera_desc: "",
    secundaria_seguridad_psicologo_desc: "",
    secundaria_seguridad_guardia_desc: "",
    secundaria_seguridad_cctv_desc: "",
    secundaria_seguridad_primerrespondiente_desc: "",
    secundaria_seguridad_ambulancia_desc: "",
    secundaria_seguridad_otros_desc: "",
    secundaria_adicional_transporte_desc: "",
    secundaria_adicional_alimentacion_desc: "",
    secundaria_adicional_menu_desc: "",
    secundaria_adicional_cafeteria_desc: "",
    secundaria_adicional_otros_desc: "",
    secundaria_alianzas_otros_desc: "",
    bachillerato_encuestaSatisfaccion: 0,
    bachillerato_montoAnualizado: "0",
    bachillerato_metodologia_laica: true,
    bachillerato_redEscuelas: false,
    bachillerato_constructivista: 0,
    bachillerato_conductista: 0,
    bachillerato_metacademico:0,
    bachillerato_humanista:0,
    bachillerato_tics:0,
    bachillerato_porproyectos:0,
    bachillerato_tradicional: 0,
    bachillerato_flipped: 0,
    bachillerato_designthinking: 0,
    bachillerato_ibo: 0,
    bachillerato_methodology: [],
    bachillerato_element_clase_tareas: 0,
    bachillerato_element_clase_valores: 0,
    bachillerato_element_clase_religion: 0,
    bachillerato_element_clase_computacion: 0,
    bachillerato_element_clase_arte: 0,
    bachillerato_element_clase_sustentabilidad: 0,
    bachillerato_element_clase_intelig_emocional: 0,
    bachillerato_element_clase_otros: 0,
    bachillerato_idioma_horas: "",
    bachillerato_idioma_modelo: "",
    bachillerato_idioma_mcer: "",
    bachillerato_idioma_extracurr: [],
    bachillerato_idioma_otros: "",
    bachillerato_total_alumnos_capacidad: 0,
    bachillerato_total_alumnos_actual: 0,
    bachillerato_capacidad_alumnos_por_aula: 0,
    bachillerato_promedio_alumnos_por_aula: 0,
    bachillerato_canchas_basquet: 0,
    bachillerato_canchas_volei: 0,
    bachillerato_canchas_futbol: 0,
    bachillerato_canchas_soccer: 0,
    bachillerato_canchas_tenis: 0,
    bachillerato_canchas_alberca: 0,
    bachillerato_canchas_arenero: 0,
    bachillerato_canchas_beisbol: 0,
    bachillerato_canchas_areajuegos: 0,
    bachillerato_canchas_otros: 0,
    bachillerato_lab_quimica: 0,
    bachillerato_lab_cocina: 0,
    bachillerato_lab_electricidad: 0,
    bachillerato_lab_dibujo: 0,
    bachillerato_lab_musica: 0,
    bachillerato_lab_computacion: 0,
    bachillerato_lab_huertos: 0,
    bachillerato_lab_biblioteca: 0,
    bachillerato_lab_otras: 0,
    bachillerato_instalaciones_oficina: 0,
    bachillerato_instalaciones_comedor: 0,
    bachillerato_instalaciones_banios: 0,
    bachillerato_instalaciones_vestidores: 0,
    bachillerato_instalaciones_abiente_digital: 0,
    bachillerato_instalaciones_auditorio: 0,
    bachillerato_instalaciones_bodega: 0,
    bachillerato_instalaciones_jardines: 0,
    bachillerato_instalaciones_cajones: 0,
    bachillerato_instalaciones_otras: 0,
    bachillerato_deportivas_basketbol: 0,
    bachillerato_deportivas_basketbol_desc: "",
    bachillerato_deportivas_volei: 0,
    bachillerato_deportivas_volei_desc: "",
    bachillerato_deportivas_futbol: 0,
    bachillerato_deportivas_futbol_desc: "",
    bachillerato_deportivas_soccer: 0,
    bachillerato_deportivas_soccer_desc: "",
    bachillerato_deportivas_beisbol: 0,
    bachillerato_deportivas_beisbol_desc: "",
    bachillerato_deportivas_atletismo: 0,
    bachillerato_deportivas_atletismo_desc: "",
    bachillerato_deportivas_porristas: 0,
    bachillerato_deportivas_porristas_desc: "",
    bachillerato_deportivas_natacion: 0,
    bachillerato_deportivas_natacion_desc: "",
    bachillerato_deportivas_tae_kwon_do: 0,
    bachillerato_deportivas_tae_kwon_do_desc: "",
    bachillerato_deportivas_otros: 0,
    bachillerato_deportivas_otros_desc: "",
    bachillerato_cultural_ajedrez: 0,
    bachillerato_cultural_oratoria: 0,
    bachillerato_cultural_debate: 0,
    bachillerato_cultural_pintura: 0,
    bachillerato_cultural_danza: 0,
    bachillerato_cultural_teatro: 0,
    bachillerato_cultural_cocina: 0,
    bachillerato_cultural_banda: 0,
    bachillerato_cultural_otros: 0,
    bachillerato_academica_matematicas: 0,
    bachillerato_academica_entomologia: 0,
    bachillerato_academica_botanica: 0,
    bachillerato_academica_fisica: 0,
    bachillerato_academica_literatura: 0,
    bachillerato_academica_otros: 0,
    bachillerato_tecnologicas_huerto: 0,
    bachillerato_tecnologicas_robotica: 0,
    bachillerato_tecnologicas_programacion: 0,
    bachillerato_tecnologicas_edicion: 0,
    bachillerato_tecnologicas_radio: 0,
    bachillerato_tecnologicas_electricidad: 0,
    bachillerato_tecnologicas_otros: 0,
    bachillerato_cultural_ajedrez_desc: "",
    bachillerato_cultural_oratoria_desc: "",
    bachillerato_cultural_debate_desc: "",
    bachillerato_cultural_pintura_desc: "",
    bachillerato_cultural_danza_desc: "",
    bachillerato_cultural_teatro_desc: "",
    bachillerato_cultural_cocina_desc: "",
    bachillerato_cultural_banda_desc: "",
    bachillerato_cultural_otros_desc: "",
    bachillerato_academica_matematicas_desc: "",
    bachillerato_academica_entomologia_desc: "",
    bachillerato_academica_botanica_desc: "",
    bachillerato_academica_fisica_desc: "",
    bachillerato_academica_literatura_desc: "",
    bachillerato_academica_otros_desc: "",
    bachillerato_tecnologicas_huerto_desc: "",
    bachillerato_tecnologicas_robotica_desc: "",
    bachillerato_tecnologicas_programacion_desc: "",
    bachillerato_tecnologicas_edicion_desc: "",
    bachillerato_tecnologicas_radio_desc: "",
    bachillerato_tecnologicas_electricidad_desc: "",
    bachillerato_tecnologicas_otros_desc: "",
    bachillerato_academicos_lms: 0,
    bachillerato_academicos_apps: 0,
    bachillerato_academicos_cert_ingles: 0,
    bachillerato_academicos_cert_metodo: 0,
    bachillerato_academicos_cert_iso: 0,
    bachillerato_academicos_otros: 0,
    bachillerato_administracion_plataforma: 0,
    bachillerato_administracion_domiciliado: 0,
    bachillerato_administracion_tdtc: 0,
    bachillerato_administracion_anual: 0,
    bachillerato_administracion_cheque: 0,
    bachillerato_administracion_descuento: 0,
    bachillerato_administracion_otros: 0,
    bachillerato_comunicacion_web: 0,
    bachillerato_comunicacion_apps: 0,
    bachillerato_comunicacion_facebook: 0,
    bachillerato_comunicacion_twiter: 0,
    bachillerato_comunicacion_whatsapp: 0,
    bachillerato_comunicacion_youtube: 0,
    bachillerato_comunicacion_webinarios: 0,
    bachillerato_comunicacion_otros: 0,
    bachillerato_seguridad_enfermera: 0,
    bachillerato_seguridad_psicologo: 0,
    bachillerato_seguridad_guardia: 0,
    bachillerato_seguridad_cctv: 0,
    bachillerato_seguridad_primerrespondiente: 0,
    bachillerato_seguridad_ambulancia: 0,
    bachillerato_seguridad_otros: 0,
    bachillerato_adicional_transporte: 0,
    bachillerato_adicional_alimentacion: 0,
    bachillerato_adicional_menu: 0,
    bachillerato_adicional_cafeteria: 0,
    bachillerato_adicional_otros: 0,
    bachillerato_alianzas_otros: 0,
    bachillerato_academicos_lms_desc: "",
    bachillerato_academicos_apps_desc: "",
    bachillerato_academicos_cert_ingles_desc: "",
    bachillerato_academicos_cert_metodo_desc: "",
    bachillerato_academicos_cert_iso_desc: "",
    bachillerato_academicos_otros_desc: "",
    bachillerato_administracion_plataforma_desc: "",
    bachillerato_administracion_domiciliado_desc: "",
    bachillerato_administracion_tdtc_desc: "",
    bachillerato_administracion_anual_desc: "",
    bachillerato_administracion_cheque_desc: "",
    bachillerato_administracion_descuento_desc: "",
    bachillerato_administracion_otros_desc: "",
    bachillerato_comunicacion_web_desc: "",
    bachillerato_comunicacion_apps_desc: "",
    bachillerato_comunicacion_facebook_desc: "",
    bachillerato_comunicacion_twiter_desc: "",
    bachillerato_comunicacion_whatsapp_desc: "",
    bachillerato_comunicacion_youtube_desc: "",
    bachillerato_comunicacion_webinarios_desc: "",
    bachillerato_comunicacion_otros_desc: "",
    bachillerato_seguridad_enfermera_desc: "",
    bachillerato_seguridad_psicologo_desc: "",
    bachillerato_seguridad_guardia_desc: "",
    bachillerato_seguridad_cctv_desc: "",
    bachillerato_seguridad_primerrespondiente_desc: "",
    bachillerato_seguridad_ambulancia_desc: "",
    bachillerato_seguridad_otros_desc: "",
    bachillerato_adicional_transporte_desc: "",
    bachillerato_adicional_alimentacion_desc: "",
    bachillerato_adicional_menu_desc: "",
    bachillerato_adicional_cafeteria_desc: "",
    bachillerato_adicional_otros_desc: "",
    bachillerato_alianzas_otros_desc: "",
    niveles: [],
    paquete: "",
    nMaternal: false,
    nKinder: false,
    nPrimaria: false,
    nSecundaria: false,
    nBachillerato: false,
    formActivePanel3: 1,
    formActivePanel1Changed: false,
    isEdit: false,
  }
  componentDidMount(){
  		let {paquete} = this.props.match.params
      const uid = this.props.auth.uid
      console.log(uid);
      if (paquete !== 'despegue' &&
          paquete !== 'plataforma' &&
          paquete !== 'descubre' &&
          paquete !== 'explora') {
  		        this.props.getAGoal(paquete)
      }
      if (paquete === 'despegue' ||
          paquete === 'plataforma' ||
          paquete === 'descubre' ||
          paquete === 'explora') {
            this.setState({paquete: paquete});
          }
      this.props.getAUser(uid);

  }
  componentDidUpdate(prevProps) {
	  // Typical usage (don't forget to compare props):
	  if (this.props.getAGoalData !== prevProps.getAGoalData) {
      if (this.props.getAGoalData.data)
	    {
        const goal = this.props.getAGoalData.data
        console.log(goal);
        const newState = Object.assign(this.state, goal);
        //this.textInput.current.props.initialContent = goal.historia;
  	    this.setState(newState);
        const newImages = this.state.imagenes.map((img, idx) => {
          if (goal.uploadImages[img.id]) {
            console.log(goal.uploadImages[img.id].url);
            return {
              id: img.id,
              name: img.name,
              desc: goal.uploadImages[img.id].desc,
              value: goal.uploadImages[img.id].url
            }
          }
          else {
            return {
              id: img.id,
              name: img.name,
              desc: '',
              value: ''
            }
          }
        });

        console.log(newImages);
        this.setState({
          imagenes: newImages,
          isEdit: true
        });
      }
      if (this.props.getAGoalData.userData){
        const user = this.props.getAGoalData.userData;
        console.log(user);
        this.setState({
          niveles: user.levels,
          nMaternal: user.levels.includes('maternal'),
          nKinder: user.levels.includes('kinder'),
          nPrimaria: user.levels.includes('primaria'),
          nSecundaria: user.levels.includes('secundaria'),
          nBachillerato: user.levels.includes('bachillerato')
      });
      }
      //console.log(this.textInput.current.props.initialContent);
	  }
    console.log(this.props);
	}

  focusDiv() {
    console.log('Focus')
    const divElement = ReactDOM.findDOMNode(this.refs.theDiv);
    if (divElement) {
      divElement.focus();
    }
  }
  copyValues = (filter) => {
    console.log('Copy Infraestructura');
    const cn = filter
    console.log(cn);
    const datosInfra = this.state.infraestructura.map((item) => {
      item.value= this.state[`${cn}_${item.id}`]
      return item;
    });
    const datosExtracur = this.state.extracur.map((item) => {
      item.value= this.state[`${cn}_${item.id}`]
      item.desc= this.state[`${cn}_${item.id}_desc`]
      return item;
    });
    const datosServ = this.state.servicios.map((item) => {
      item.value= this.state[`${cn}_${item.id}`]
      item.desc= this.state[`${cn}_${item.id}_desc`]
      return item;
    });
    this.setState({
          infraestructura: datosInfra,
          extracur: datosExtracur,
          servicios: datosServ
        });
  }
  toggle = tab => () => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  }
  handleChangeNested = (obj) => e => {
    let x = this.state[obj];
    x[e.target.id] = e.target.value;
    this.setState({ [obj]: x });
  };
  handleChange = (e) => {
    console.log(e.target.name)
    console.log(e.target.value)
    this.setState({
      [e.target.id]: e.target.value
    })
    //e.preventDefault();
    //e.target.className += " was-validated";
  }
  removeTag = (i, event)  => {
    console.log(event.target.name)
    this.setState({historia: i});
  }
  handleChangeMethods = (e) => {
    console.log(e)
    const methods=['constructivista','conductista','metacademico', 'humanista', 'tics', 'porproyectos', 'tradicional', 'flipped','designthinking', 'ibo']
    const nivelKey = e.target.id.split('_')[0] +"_methodology";
    methods.map(key => {
        if (e.target.id.includes(key)){
          //console.log(key)
          //console.log(e.target.id)
          if (!this.state[nivelKey].includes(key)) {
            this.setState({
              [nivelKey]: [
                ...this.state[nivelKey],
                key
              ],
              [e.target.id]: e.target.value
            })
          }
          else {
            this.setState({
              [e.target.id]: e.target.value
            })
          }
        }
    })
  }
  showEditor = () => {
    console.log('Show Editor');
    this.setState({isEditorOpen : true});
  }
  handleHistoria = (data) => {
    console.log('handleHistoria ' , data);

    this.setState({
      isEditorOpen : false,
      historia: data
    });
    let z = document.querySelector('.menana');
    z.parentNode.replaceChild(z.cloneNode(1), z);
    console.log(this.state.historia)
  }
  handleChangeCheckbox = (e) => {
    this.setState({
      [e.target.id]: e.target.checked
    })
  }
  handleChangeDesc = (e) => {
    //console.log('handleChangeDesc')
    //console.log(e)
    Object.keys(this.state.uploadImages).map(key => {
      const refKey = Object.keys(e[key]||{}).map(key => {
        return key
      })
      if(refKey.length!==0){
        //console.log(refKey)
        this.setState((prevState)=>({uploadImages: {
          ...prevState.uploadImages,
          [e.key]: {
            ...prevState.uploadImages[e.key],
            desc: e[e.key]
          }
        }}));
        } else {
            //console.log(e.key)
            this.setState((prevState)=>({uploadImages: {
              ...prevState.uploadImages,
              [e.key]: {
                ...prevState.uploadImages[e.key],
                desc: e[e.key]
              }
            }}));
        }
    })
  }
  handleChangePromoDesc = (e) => {
    //console.log(e.key)
    //console.log(e[e.key])
    this.setState({
      imagen_promocional_desc: e[e.key]
    })
  }
  handleChangePromo = (e) => {
    //console.log(e.key)
    //console.log(e[e.key])
    this.setState({
      imagen_promocional: e[e.key][e.key]
    })
  }
  handleChangeFile = (e) => {
    //console.log('handleChangeFile')
    //console.log(e)
    if (e) {
      const { imagenes } = this.state;
      const idx = e.key.substr("foto_num".length)-1;
      //console.log(idx);
      //console.log(this.state.imagenes[idx].value);
      const imgRec = imagenes[idx];
      imgRec.value =  e[e.key][e.key];
      imagenes.splice(idx,1, imgRec);
      Object.keys(this.state.uploadImages).map(key => {
        const refKey = Object.keys(e[key]||{}).map(key => {
          return key
        })
        if(refKey.length!==0){
          //console.log(refKey)
          this.setState((prevState)=>({uploadImages: {
            ...prevState.uploadImages,
            [e.key]: {
              ...prevState.uploadImages[e.key],
              url: e[e.key][e.key]
            }
          },
        imagenes: [...imagenes]}));
        }
        else {
          //console.log(e.key)
          this.setState((prevState)=>({uploadImages: {
            ...prevState.uploadImages,
            [e.key]: {
              ...prevState.uploadImages[e.key],
              url: e[e.key][e.key]
            }
          },
          imagenes: [...imagenes]}));
        }
      })

    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let niveles = [];
    if (this.state.nMaternal) {
      niveles.push("maternal")
    }
    if (this.state.nKinder) {
      niveles.push("kinder")
    }
    if (this.state.nPrimaria) {
      niveles.push("primaria")
    }
    if (this.state.nSecundaria) {
      niveles.push("secundaria")
    }
    if (this.state.nBachillerato) {
      niveles.push("bachillerato")
    }
    this.state["niveles"] = niveles;
    let {paquete} = this.props.match.params
    //console.log(paquete);
    if (paquete === 'despegue' ||
        paquete === 'plataforma' ||
        paquete === 'descubre' ||
        paquete === 'explora') {
          console.log(this.state);
          this.props.createOrden(this.state);
          this.setState({isCreated: true});

    } else {
      this.props.updateGoal( this.state, paquete);
    }


    // if (this.state.escudo) {
    //     this.props.uploadFile(this.state);
    // }
    //this.props.history.push('/');
  }
  setFilter = (filter, paq) => () => {
    const niveles = ["none","maternal","kinder","primaria","secundaria","bachillerato"]
    let noModal = false
    const cn = niveles[filter]
    if (!this.state.currentNivel) {
      this.setState({currentNivel: cn});
      console.log(cn)
    } else {
      this.setState({currentNivel: cn});
      noModal = true;
    }
    console.log(`copy from ${this.state.activeItem}`)
    this.copyValues(cn)
    this.setState({activeItem  : filter, isModalOpen : noModal, paquete: paq});

    //console.log(this.state.activeItem);
  }
  isActive = value => () => {
    return value===this.state.activeItem;
  }
  closeModalEditor = () => this.setState({ isEditorOpen: false });
  closeModal = nivel => () => {
    console.log(`Copiar ${nivel}` )
    this.setState({isModalOpen : false});
    const refKey = Object.keys(this.state).filter(key => key.includes(nivel)).map(key=> {return key.substring(nivel.length)});
    console.log(refKey)
    refKey.forEach(item => {
      console.log(this.state.currentNivel+item)
      console.log(this.state[`${nivel}`+item])
      this.setState({[this.state.currentNivel+item] : this.state[`${nivel}`+item]});
    })
  }
  onClickMet = (nr, item) => () => {
    if (item === 'maternal') {
      this.setState({
        maternal_metodologia_laica: nr
      });
    }
    if (item ==='kinder') {
      this.setState({
        kinder_metodologia_laica: nr
      });
    }
    if (item ==='primaria') {
      this.setState({
        primaria_metodologia_laica: nr
      });
    }
    if (item ==='secundaria') {
      this.setState({
        secundaria_metodologia_laica: nr
      });
    }
    if (item ==='bachillerato') {
      this.setState({
        bachillerato_metodologia_laica: nr
      });
    }
  }
  handleCheckChildElement = (event) => {
    console.log(event.target.value)
    console.log(event.target.name)
    let idiomasState = this.state.currentNivel+"_idiomas"
    let idiomas = this.state[idiomasState];
    idiomas.forEach(idioma => {
       if (event.target.name.includes(idioma.id)) {
          idioma.isChecked =  event.target.value
       }
    })
    this.setState({[idiomasState]: idiomas})
  }
  handleInfraChange = (evt, idx) => {
    console.log("handleInfraChange")
    console.log(evt.target.id)
    console.log(evt.target)
    console.log(evt.target.value)
    const newShareholders = this.state.infraestructura.map((shareholder) => {
      if (!evt.target.id.includes(shareholder.id)) return shareholder;
      return { ...shareholder, value: evt.target.value };
    });
    this.setState({
        [evt.target.id]: evt.target.value,
        infraestructura: newShareholders
      });
  };
  decrease = (evt) => {
    console.log("decrease: " )
    console.log(evt.target.id)
    console.log((this.state[evt.target.id]))
    const newShareholders = this.state.infraestructura.map((shareholder) => {
      if (!evt.target.id.includes(shareholder.id)) return shareholder;
      return { ...shareholder, value: shareholder.value <= 0 ? 0 : parseInt(shareholder.value) - 1 };
    });

    this.setState({ [evt.target.id] : this.state[evt.target.id] - 1,
                    infraestructura: newShareholders});
  }
  increase = (evt) => {
    console.log("increase: ")
    console.log(evt.target.id)
    const newShareholders = this.state.infraestructura.map((shareholder) => {
      if (!evt.target.id.includes(shareholder.id)) return shareholder;
      return { ...shareholder, value: parseInt(shareholder.value) + 1  };
    });
    this.setState({[evt.target.id] : this.state[evt.target.id] + 1,
                    infraestructura: newShareholders });
  }
  swapFormActive = (a) => (param) => (e) => {
  this.setState({
    ['formActivePanel' + a]: param,
    ['formActivePanel' + a + 'Changed']: true
  });
  console.log(param);
  this.focusDiv();
  if (param !== 5) {
    console.log(e.target);
  }
  }

  handleNextPrevClick = (a) => (param) => (e) => {
    this.setState({
      ['formActivePanel' + a]: param,
      ['formActivePanel' + a + 'Changed']: true
    });
  }

  handleSubmission = () => {
    alert('Form submitted!');
  }

  calculateAutofocus = (a) => {
    if (this.state['formActivePanel' + a + 'Changed']) {
    return true
    }
  }
  render() {
    const imagesData = {despegue: 8, plataforma: 2, descubre:20, explora:20};
    const {pathname} = this.props.location;
    const paquete  = pathname.split('/')[2];
    const maxImage = imagesData[paquete];
    const { auth, authError } = this.props;
    if (!auth.uid) return <Redirect to='/signin' />
    if (this.state.isCreated) return <Redirect to='/admin' />
    return (
      <MDBContainer>
        <MDBRow className="pt-5 justify-content-center">
          <p className="h1 text-center mb-12">Registro en Línea</p>
        </MDBRow>
        <MDBRow className="pt-5 justify-content-center">
          <div ref='theDiv' tabIndex='0'>
            <p className="h3 text-center mb-12">Paquete: <b>{this.state.isEdit ? this.state.paquete : paquete}</b></p>
          </div>
        </MDBRow>
        <MDBRow className="pt-5 justify-content-center">
          <MDBCol md="2">
          <MDBStepper icon vertical className="h-auto">
            <MDBStep id="1" far icon="folder-open" stepName="Información Básica" onClick={this.swapFormActive(3)(1)} vertical />
            <MDBStep id="2" icon="globe" stepName="Redes Sociales" onClick={this.swapFormActive(3)(2)} vertical />
            <MDBStep id="3" icon="user" stepName="Datos de Contacto" onClick={this.swapFormActive(3)(3)} vertical />
            <MDBStep id="4" icon="money-bill" stepName="Facturación" onClick={this.swapFormActive(3)(4)} vertical />
            <MDBStep id="5" far icon="pencil" stepName="Historia de la Escuela" onClick={this.swapFormActive(3)(5)} vertical />
            <MDBStep id="6" far icon="image" stepName="Fotografías" onClick={this.swapFormActive(3)(6)} vertical />
            <MDBStep id="7" icon="graduation-cap" stepName="Niveles" onClick={this.swapFormActive(3)(7)} vertical />
            <MDBStep id="8" icon="check" stepName="Terminar" onClick={this.swapFormActive(3)(8)} vertical />
          </MDBStepper>
          </MDBCol>
          <MDBCol md="7" xs="12">
          <ModalPage nivel={this.state.activeItem} isOpen={this.state.isModalOpen} close={this.closeModal}/>
          <ModalEditor  historia={this.state.historia} isOpen={this.state.isEditorOpen} close={this.handleHistoria} onHide={this.closeModalEditor}/>

        {this.state.formActivePanel3 === 1 && (
          <>
            <MDBInput
              label="Nombre comercial"
              icon="home"
              group
              type="text"
              error="wrong"
              success="right"
              id="comercialName"
              name="comercialName"
              value={this.state.comercialName}
              onChange={this.handleChange}
              required
            >
              <div className="valid-feedback">{i18n.t('Correcto')}</div>
            </MDBInput>
            <MDBInput
              label="Nombre Oficial"
              icon="user"
              group
              type="text"
              validate
              error="wrong"
              success="right"
              id="oficialName"
              name="oficialName"
              value={this.state.oficialName}
              onChange={this.handleChange}
            >
              <div className="valid-feedback">{i18n.t('Correcto')}</div>
            </MDBInput>
            <MDBInput
              validate
              value={this.state.street}
              onChange={this.handleChange}
              type="text"
              id="street"
              name="street"
              label="Calle"
              required
            >
            </MDBInput>
            <MDBInput
              validate
              value={this.state.numExt}
              onChange={this.handleChange}
              type="text"
              id="numExt"
              name="numExt"
              label="Número Exterior"
              required
            >
            </MDBInput>
            <MDBInput
              validate
              value={this.state.numInt}
              onChange={this.handleChange}
              type="text"
              id="numInt"
              name="numInt"
              label="Número Interior"
            >
            </MDBInput>
            <MDBInput
              validate
              value={this.state.city}
              onChange={this.handleChange}
              type="text"
              id="city"
              name="city"
              label="Ciudad"
              required
            >
            </MDBInput>
            <div className="invalid-feedback">
              Please provide a valid city.
            </div>
            <div className="valid-feedback">Looks good!</div>
            <MDBInput
              validate
              value={this.state.municipio}
              onChange={this.handleChange}
              type="text"
              id="municipio"
              name="municipio"
              label="Municipio"
              required
            >
            </MDBInput>
            <MDBInput
              validate
              value={this.state.entreCalles}
              onChange={this.handleChange}
              type="text"
              id="entreCalles"
              name="entreCalles"
              label="Entre Calles"
              required
            >
            </MDBInput>
            <MDBInput
              value={this.state.state}
              onChange={this.handleChange}
              type="text"
              id="state"
              name="state"
              label="Estado"
              required
            >
            </MDBInput>
            <MDBInput
              value={this.state.zip}
              onChange={this.handleChange}
              type="text"
              id="zip"
              name="zip"
              label="Código Postal"
              required
            >
            </MDBInput>
            <MDBInput
              value={this.state.phone}
              onChange={this.handleChange}
              type="tel"
              id="phone"
              name="phone"
              label="Teléfono"
              required
            >
            </MDBInput>
            <MDBInput
              value={this.state.webpage}
              onChange={this.handleChange}
              type="url"
              id="webpage"
              name="webpage"
              label="Página Web"
              required
            >
            </MDBInput>
            <MDBInput
              value={this.state.location.lat}
              onChange={this.handleChangeNested('location')}
              type="number"
              id="lat"
              name="lat"
              label="Ubicación Geografica - Latitud"
              required
            >
            </MDBInput>
            <MDBInput
              value={this.state.location.lng}
              onChange={this.handleChangeNested('location')}
              type="number"
              id="lng"
              name="lng"
              label="Ubicación Geografica - Longitud"
              required
            >
            </MDBInput>
            <MDBBtn color="mdb-color" rounded className="float-right" type="submit" onClick={this.handleNextPrevClick(3)(2)}>
                  siguiente
            </MDBBtn>
          </>
        )}
        {this.state.formActivePanel3 === 2 && (
        <>
          <MDBRow>
            <p className="h4 text-center"><b>Redes Sociales</b></p>
          </MDBRow>
          <MDBRow>
            <MDBInput
              value={this.state.rsFacebook}
              onChange={this.handleChange}
              type="url"
              id="rsFacebook"
              name="rsFacebook"
              label="Facebook"
              icon="facebook"
              required
            >
            </MDBInput>
            <MDBInput
              value={this.state.rsTwitter}
              onChange={this.handleChange}
              type="url"
              id="rsTwitter"
              name="rsTwitter"
              label="Twitter"
              icon="twitter"
              required
            >
            </MDBInput>
            <MDBInput
              value={this.state.rsInstagram}
              onChange={this.handleChange}
              type="url"
              id="rsInstagram"
              name="rsInstagram"
              label="Instagram"
              icon="instagram"
              required
            >
            </MDBInput>
            <MDBInput
              value={this.state.rsLinkedIn}
              onChange={this.handleChange}
              type="url"
              id="rsLinkedIn"
              name="rsLinkedIn"
              label="LinkedIn"
              icon="linkedin"
              required
            >
            </MDBInput>
            <MDBInput
              value={this.state.rsYouTube}
              onChange={this.handleChange}
              type="url"
              id="rsYouTube"
              name="rsYouTube"
              label="YouTube"
              icon="youtube"
              required
            >
            </MDBInput>
            <MDBInput
              value={this.state.rsGoogle}
              onChange={this.handleChange}
              type="url"
              id="rsGoogle"
              name="rsGoogle"
              label="Google +"
              icon="google"
              required
            >
            </MDBInput>
          </MDBRow>
          <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.handleNextPrevClick(3)(1)}>
              anterior
          </MDBBtn>
          <MDBBtn color="mdb-color" rounded className="float-right" onClick={this.handleNextPrevClick(3)(3)}>
              siguiente
          </MDBBtn>
        </>
      )}
      {this.state.formActivePanel3 === 3 && (
        <>
        <MDBRow>
          <h4><b>Datos de contacto</b></h4>
        </MDBRow>
        <MDBRow>
            <p className="h6 text-center mb-12">Director General</p>
            <MDBRow>
            <MDBInput
              value={this.state.directorName}
              onChange={this.handleChange}
              type="text"
              id="directorName"
              name="directorName"
              label="Nombre"
              icon="user"
              required
            >
            </MDBInput>
            <MDBInput
              value={this.state.directorEmail}
              onChange={this.handleChange}
              type="email"
              id="directorEmail"
              name="directorEmail"
              label="Correo"
              icon="envelope"
              required
            >
            </MDBInput>
            <MDBInput
              value={this.state.directorPhone}
              onChange={this.handleChange}
              type="tel"
              id="directorPhone"
              name="directorPhone"
              label="Celular"
              icon="phone"
              required
            >
            </MDBInput>
          </MDBRow>
          <MDBRow>
            <p className="h6 text-center mb-12">Enlace Eduk2</p>
          </MDBRow>
          <MDBRow className="d-flex flex-row">
              <MDBInput
                value={this.state.enlaceName}
                onChange={this.handleChange}
                type="text"
                id="enlaceName"
                name="enlaceName"
                label="Nombre"
                icon="user"
                required
              >
              </MDBInput>
              <MDBInput
                value={this.state.enlaceEmail}
                onChange={this.handleChange}
                type="email"
                id="enlaceEmail"
                name="enlaceEmail"
                label="Correo"
                icon="envelope"
                required
              >
              </MDBInput>
              <MDBInput
                value={this.state.enlacePhone}
                onChange={this.handleChange}
                type="tel"
                id="enlacePhone"
                name="enlacePhone"
                label="Celular"
                icon="phone"
                required
              >
              </MDBInput>

            </MDBRow>
            <MDBRow>
              <p className="h6 text-center mb-12">Admisiones</p>
            </MDBRow>
            <MDBRow className="d-flex flex-row">
                <MDBInput
                  value={this.state.admisionesName}
                  onChange={this.handleChange}
                  type="text"
                  id="admisionesName"
                  name="admisionesName"
                  label="Nombre"
                  icon="user"
                  required
                >
                </MDBInput>
                <MDBInput
                  value={this.state.admisionesEmail}
                  onChange={this.handleChange}
                  type="email"
                  id="admisionesEmail"
                  name="admisionesEmail"
                  label="Correo"
                  icon="envelope"
                  required
                >
                </MDBInput>
                <MDBInput
                  value={this.state.admisionesPhone}
                  onChange={this.handleChange}
                  type="tel"
                  id="admisionesPhone"
                  name="admisionesPhone"
                  label="Celular"
                  icon="phone"
                  required
                >
                </MDBInput>

              </MDBRow>
              <MDBRow>
                <p className="h6 text-center mb-12">Recepción</p>
              </MDBRow>
              <MDBRow>
                  <MDBInput
                    value={this.state.recepcionName}
                    onChange={this.handleChange}
                    type="text"
                    id="recepcionName"
                    name="recepcionName"
                    label="Nombre"
                    icon="user"
                    required
                  >
                  </MDBInput>
                  <MDBInput
                    value={this.state.recepcionEmail}
                    onChange={this.handleChange}
                    type="email"
                    id="recepcionEmail"
                    name="recepcionEmail"
                    label="Correo"
                    icon="envelope"
                    required
                  >
                  </MDBInput>
                  <MDBInput
                    value={this.state.recepcionPhone}
                    onChange={this.handleChange}
                    type="tel"
                    id="recepcionPhone"
                    name="recepcionPhone"
                    label="Celular"
                    icon="phone"
                    required
                  >
                  </MDBInput>
                  </MDBRow>
                </MDBRow>
                <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.handleNextPrevClick(3)(2)}>
                    anterior
                </MDBBtn>
                <MDBBtn color="mdb-color" rounded className="float-right" onClick={this.handleNextPrevClick(3)(4)}>
                    siguiente
                </MDBBtn>
              </>
              )}
              {this.state.formActivePanel3 === 4 && (
                <>
                  <MDBRow>
                    <p className="h4 text-center">Datos Facturación</p>
                  </MDBRow>
                  <MDBRow>
                      <MDBInput
                        value={this.state.rfc}
                        onChange={this.handleChange}
                        type="text"
                        id="rfc"
                        name="rfc"
                        label="RFC"
                        required
                      >
                      </MDBInput>
                      <MDBInput
                        value={this.state.nombreFiscal}
                        onChange={this.handleChange}
                        type="text"
                        id="nombreFiscal"
                        name="nombreFiscal"
                        label="Nombre Fiscal"
                        required
                      >
                      </MDBInput>
                      <MDBInput
                        value={this.state.usoCFDI}
                        onChange={this.handleChange}
                        type="text"
                        id="usoCFDI"
                        name="usoCFDI"
                        label="Uso CFDI"
                        required
                      >
                      </MDBInput>
                      <MDBInput
                        value={this.state.tipoPago}
                        onChange={this.handleChange}
                        type="text"
                        id="tipoPago"
                        name="tipoPago"
                        label="Tipo de Pago"
                        required
                      >
                      </MDBInput>
                      <MDBInput
                        value={this.state.condicionesPago}
                        onChange={this.handleChange}
                        type="text"
                        id="condicionesPago"
                        name="condicionesPago"
                        label="Condiciones de Pago"
                        required
                      >
                      </MDBInput>
                      <MDBInput
                        value={this.state.observacionesPago}
                        onChange={this.handleChange}
                        type="text"
                        id="observacionesPago"
                        name="observacionesPago"
                        label="Observaciones"
                        required
                      >
                      </MDBInput>
                      <MDBInput
                        value={this.state.representanteLegal}
                        onChange={this.handleChange}
                        type="text"
                        id="representanteLegal"
                        name="representanteLegal"
                        label="Representante Legal"
                        required
                      >
                      </MDBInput>
                      <MDBInput
                        value={this.state.fechaInicio}
                        onChange={this.handleChange}
                        type="date"
                        id="fechaInicio"
                        name="fechaInicio"
                        hint="Fecha de Inicio"
                        required
                      />

                  </MDBRow>
                  <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.handleNextPrevClick(3)(3)}>
                      anterior
                  </MDBBtn>
                  <MDBBtn color="mdb-color" rounded className="float-right" onClick={this.handleNextPrevClick(3)(5)}>
                      siguiente
                  </MDBBtn>
                </>
              )}
              {this.state.formActivePanel3 === 5 && (
                <>
                  <MDBRow>
                    <p className="h4 text-center">Información de la Escuela</p>
                  </MDBRow>
                  <MDBRow>
                      <TextComponent data={this.state.historia} />
                  </MDBRow>
                  <MDBRow>
                      <MDBBtn  onClick={this.showEditor}>
                        Actualizar
                      </MDBBtn>
                  </MDBRow>
                  <MDBRow>
                      <MDBInput
                        containerClassName="flex-nowrap"
                        value={this.state.lema}
                        onChange={this.handleChange}
                        type="text"
                        id="lema"
                        name="lema"
                        label="Lema de la Escuela"
                        required
                      >
                      </MDBInput>
                  </MDBRow>
                  { this.state.paquete === 'descubre' &&
                  <MDBRow>
                          <MDBInput
                            containerClassName="flex-nowrap"
                            value={this.state.url_entrevista_dir}
                            onChange={this.handleChange}
                            type="url"
                            id="url_entrevista_dir"
                            name="url_entrevista_dir"
                            label="Entrevista al Director"
                          >
                          </MDBInput>
                          <MDBInput
                            containerClassName="flex-nowrap"
                            value={this.state.url_articulos_publicados}
                            onChange={this.handleChange}
                            type="url"
                            id="url_articulos_publicados"
                            name="url_articulos_publicados"
                            label="Artículos publicados"
                          >
                          </MDBInput>
                          <MDBInput
                            value={this.state.url_reportajes_y_mas}
                            onChange={this.handleChange}
                            type="url"
                            id="url_reportajes_y_mas"
                            name="url_reportajes_y_mas"
                            label="Entrevistas y Reportajes"
                          >
                          </MDBInput>
                      </MDBRow>

                    }
                  <MDBRow>
                    <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.swapFormActive(3)(4)}>
                        anterior
                    </MDBBtn>
                    <MDBBtn color="mdb-color" rounded className="float-right" onClick={this.swapFormActive(3)(6)}>
                        siguiente
                    </MDBBtn>
                  </MDBRow>
                </>
              )}
              {this.state.formActivePanel3 === 6 && (
                <MDBRow>
                  <MDBRow>
                    <p className="h4 text-center mb-12">Fotografías</p>
                  </MDBRow>
                  <MDBRow>
                  {
                      this.state.imagenes.slice(0,maxImage).map((img, idx) => {

                      return (
                        <>
                          <ImagePage id={img.id} key={img.id} texto={img.name} handleChangeFile={this.handleChangeFile} handleChangeDesc={this.handleChangeDesc} auth={auth.uid}
                          edit={this.state.isEdit}
                          url={img.value}
                          desc={img.desc}
                          />

                        </>
                        )
                      }
                      )
                  }
                  {
                    this.state.paquete === 'explora' ||
                    this.state.paquete === 'descubre' ? (
                    <div>
                        <p className="h6 text-center mb-12">Imagen Promocional</p>
                        <ImagePage id="imagen_promocional" texto="Promocional"
                          handleChangeFile={this.handleChangePromo}
                          handleChangeDesc={this.handleChangePromoDesc}
                          auth={auth.uid}
                        />
                        <p className="h6 text-center mb-12">Videos</p>
                        <MDBInput
                          value={this.state.video_institucional}
                          onChange={this.handleChange}
                          type="text"
                          id="video_institucional"
                          name="video_institucional"
                          label="Video Institucional"
                          required
                        />
                    </div>
                    ):(<div></div>)
                  }
                  </MDBRow>
                  <MDBRow>
                  <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.handleNextPrevClick(3)(5)}>
                      anterior
                  </MDBBtn>
                  <MDBBtn color="mdb-color" rounded className="float-right" onClick={this.handleNextPrevClick(3)(7)}>
                      siguiente
                  </MDBBtn>
                  </MDBRow>
                </MDBRow>
              )
              }
              {this.state.formActivePanel3 === 7 && (
        <MDBRow>
          <MDBRow>
            <MDBCol md="12">
              <h2>Niveles</h2>
              <MDBCol md='12' className="mb-4">
                   <MDBBtnGroup>
                     {this.state.nMaternal && <MDBBtn color="primary" active={this.state.activeItem === '1'?'active':''} onClick={this.setFilter('1', paquete)}>Maternal</MDBBtn>}
                     {this.state.nKinder && <MDBBtn color="primary" active={this.state.activeItem === '2'?'active':''} onClick={this.setFilter('2', paquete)}>Preescolar</MDBBtn>}
                     {this.state.nPrimaria && <MDBBtn color="primary" active={this.state.activeItem === '3'?'active':''} onClick={this.setFilter('3', paquete)}>Primaria</MDBBtn>}
                     {this.state.nSecundaria && <MDBBtn color="primary" active={this.state.activeItem === '4'?'active':''} onClick={this.setFilter('4', paquete)}>Secundaria</MDBBtn>}
                     {this.state.nBachillerato && <MDBBtn color="primary" active={this.state.activeItem === '5'?'active':''} onClick={this.setFilter('5', paquete)}>Bachillerato</MDBBtn>}
                   </MDBBtnGroup>
                 </MDBCol>

            </MDBCol>
          </MDBRow>
{
  this.state.activeItem === '1' ? (
    <MDBRow>
        <NivelWrapper
          handleChange={this.handleChange}
          onClickMet={this.onClickMet}
          handleChangeCheckbox={this.handleChangeCheckbox}
          handleChangeMethods={this.handleChangeMethods.bind(this)}
          handleCheckChildElement={this.handleCheckChildElement}
          handleInfraChange={this.handleInfraChange}
          decrease={this.decrease}
          increase={this.increase}
          nivel="maternal" {...this.state} />
    </MDBRow>


  ): this.state.activeItem === '2' ? (
    <MDBRow>
      <NivelWrapper
        handleChange={this.handleChange}
        onClickMet={this.onClickMet}
        handleChangeCheckbox={this.handleChangeCheckbox}
        handleChangeMethods={this.handleChangeMethods.bind(this)}
        handleCheckChildElement={this.handleCheckChildElement}
        handleInfraChange={this.handleInfraChange}
        decrease={this.decrease}
        increase={this.increase}
        nivel="kinder" {...this.state} />
    </MDBRow>


  ): this.state.activeItem === '3' ? (
    <MDBRow>
      <MDBCol md="12">
      <NivelWrapper
        handleChange={this.handleChange}
        onClickMet={this.onClickMet}
        handleChangeCheckbox={this.handleChangeCheckbox}
        handleChangeMethods={this.handleChangeMethods}
        handleCheckChildElement={this.handleCheckChildElement}
        handleInfraChange={this.handleInfraChange}
        decrease={this.decrease}
        increase={this.increase}
        nivel="primaria" {...this.state} />
      </MDBCol>
    </MDBRow>

  ): this.state.activeItem === '4' ? (
    <MDBRow>
      <MDBCol md="12">
      <NivelWrapper
        handleChange={this.handleChange}
        onClickMet={this.onClickMet}
        handleChangeCheckbox={this.handleChangeCheckbox}
        handleChangeMethods={this.handleChangeMethods}
        handleCheckChildElement={this.handleCheckChildElement}
        handleInfraChange={this.handleInfraChange}
        decrease={this.decrease}
        increase={this.increase}
        nivel="secundaria" {...this.state} />
      </MDBCol>
    </MDBRow>
  ): this.state.activeItem === '5' ? (
    <MDBRow>
      <MDBCol md="12">
      <NivelWrapper
        handleChange={this.handleChange}
        onClickMet={this.onClickMet}
        handleChangeCheckbox={this.handleChangeCheckbox}
        handleChangeMethods={this.handleChangeMethods}
        handleCheckChildElement={this.handleCheckChildElement}
        handleInfraChange={this.handleInfraChange}
        decrease={this.decrease}
        increase={this.increase}
        nivel="bachillerato" {...this.state} />
      </MDBCol>
    </MDBRow>
  ) :(<div></div>)
}
</MDBRow>
)}
{this.state.formActivePanel3 === 8 && (
      <form  onSubmit={this.handleSubmit} className="validate" noValidate >
        <MDBRow>
          <MDBCol md="12">
                <h2 className="text-center font-weight-bold my-4">
                  Registro completo!
                </h2>
                <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.handleNextPrevClick(3)(6)}>
                  Anterior
                </MDBBtn>
                <MDBBtn color="success" rounded className="float-right" onClick={this.handleSubmit}>
                  Guardar
                </MDBBtn>
          </MDBCol>
          <div className="text-center">
            <div className="center red-text">
              { authError ? <p>{authError}</p> : null }
            </div>
          </div>
        </MDBRow>
      </form>
      )}
        </MDBCol>
        </MDBRow>
        </MDBContainer>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    getAGoalData: state.getGoal,
    userData: state.getUser
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createOrden: (ordencompra) => dispatch(createOrden(ordencompra)),
    uploadFile: (creds) => dispatch(uploadFile(creds)),
    getAGoal: (id) => { dispatch(getAGoal(id))},
		updateGoal: (updatedGoal, id) => { dispatch(updateGoal(updatedGoal, id)) },
    getAUser: (id) => {dispatch(getAUser(id))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOC)
