import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { getRegistry } from '../../store/actions/paypalActions'
import { createOrden } from '../../store/actions/ocCreate'
import { getAUser } from '../../store/actions/createGoal'
import i18n from 'i18next';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIframe,
  MDBInput,
  MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBCollapse,
} from "mdbreact";
import InvoiceService from '../../config/services/api/invoice';

const BotonPlataforma = () => {
  return (
  <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
    <input type="hidden" name="cmd" value="_s-xclick"/>
    <input type="hidden" name="hosted_button_id" value="44ZZTMP3Q8G56"/>
    <input type="image" src="https://www.paypalobjects.com/es_XC/MX/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal, la forma más segura y rápida de pagar en línea."/>
    <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1"/>
  </form>
)}

const BotonDespegue = () => {
  return (
    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
      <input type="hidden" name="cmd" value="_s-xclick"/>
      <input type="hidden" name="hosted_button_id" value="UMYU8BF97Y8Y6"/>
      <input type="image" src="https://www.paypalobjects.com/es_XC/MX/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal, la forma más segura y rápida de pagar en línea."/>
      <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1"/>
    </form>
  )
}

const BotonExplora = () => {
  return (
    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
      <input type="hidden" name="cmd" value="_s-xclick"/>
      <input type="hidden" name="hosted_button_id" value="KEECXU4HFZMZC"/>
      <input type="image" src="https://www.paypalobjects.com/es_XC/MX/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal, la forma más segura y rápida de pagar en línea."/>
      <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1"/>
    </form>
  )
}
const BotonDescubre = () => {
  return (
    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
      <input type="hidden" name="cmd" value="_s-xclick"/>
      <input type="hidden" name="hosted_button_id" value="GG3ENJLZTTJ86"/>
      <input type="image" src="https://www.paypalobjects.com/es_XC/MX/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal, la forma más segura y rápida de pagar en línea."/>
      <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1"/>
    </form>
  )
}
const BotonEjemplo = () => {
  return (
    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
      <input type="hidden" name="cmd" value="_s-xclick"/>
      <input type="hidden" name="hosted_button_id" value="YJFSL4PQP9DM8"/>
      <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif" border="0" name="submit" alt="PayPal, la forma más segura y rápida de pagar en línea."/>
      <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1"/>
    </form>
  )
}
const EmbedsPage = () => {

  return (

          <MDBIframe id="header" src="https://b44e4152-aeb6-446b-9ff9-db89f0a2ad28.filesusr.com/ugd/850e72_7a5399b364184a9d8fe7fb21bcfa66f5.pdf" />

      //<object data="https://b44e4152-aeb6-446b-9ff9-db89f0a2ad28.filesusr.com/ugd/850e72_7a5399b364184a9d8fe7fb21bcfa66f5.pdf" type="application/pdf" >
      //  <embed height="100%" width="100%" src="https://b44e4152-aeb6-446b-9ff9-db89f0a2ad28.filesusr.com/ugd/850e72_7a5399b364184a9d8fe7fb21bcfa66f5.pdf"></embed>
      //</object>

  )
}
class PagoRegistro extends Component {
  constructor(props) {
    super(props);
  }
  state = {
     rfc: '',
     nombreFiscal: '',
     usoCFDI: '',
     tipoPago: '',
     condicionesPago: '',
     observacionesPago: '',
     emailPago: '',
     aceptado: false,
     mostrar: false,
     paquete: '',
     authorId: '',
     correoFacturacion: '',
  }
  handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) { console.log("bottom") }
  }

  componentDidMount(){
    console.log('componentDidMount')
    const { auth } = this.props;
    const {paquete} = this.props.match.params
    this.setState({
      paquete,
      authorId: auth.uid
    })
    this.props.getData(auth.uid);
    this.props.getAUser(auth.uid);
  }
  componentDidUpdate(prevProps) {
    console.log(prevProps);
    // Typical usage (don't forget to compare props):
    if (this.props.getData !== prevProps.getData) {
      if (prevProps.getData)
      {
        const data = this.props.getData.data
        console.log(data);
      }
    }
    if (this.props.getAGoalData){
      const user = this.props.getAGoalData.userData;
      console.log(user);
      this.setState({
        niveles: user.levels,
        nMaternal: user.levels.includes('maternal'),
        nKinder: user.levels.includes('kinder'),
        nPrimaria: user.levels.includes('primaria'),
        nSecundaria: user.levels.includes('secundaria'),
        nBachillerato: user.levels.includes('bachillerato')
      });
    }
  }


  handleSubmit = (e) => {
    console.log(e);
    e.preventDefault();
    e.target.className += " was-validated";

    if (this.state.rfc && this.state.rfc.length > 1 &&
        this.state.nombreFiscal && this.state.nombreFiscal.length > 1 &&
        this.state.correoFacturacion && this.state.correoFacturacion.length > 1) {

      let x = this.props.createOrden(this.state);
      console.log(x);
      this.setState({
        mostrar: true
      })
    }
    else {
      alert("Debe capturar todos los datos y aceptar");
    }
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleChangeCheckbox = (e) => {
    console.log('handleChangeCheckbox');
    this.setState({
      aceptado: e.target.checked
    })

  }

  handleInvoice = (data) => {
    console.log(data);
    InvoiceService.getNumber(data).then((response) => {
      console.log(response);
      const number = response.data.invoice_number;
      const sendBody= {
        "send_to_invoicer": true
      }
      var body = data.content;
      body.detail.invoice_number = number;
      InvoiceService.generateInvoice(data, body).then((response) => {
        console.log(response);
        const invoiceId = response.data.href.split("/").pop();
        console.log(invoiceId);
        InvoiceService.sendInvoice(data, sendBody, invoiceId).then((response) => {
          console.log(response);
          this.setState(() => ({
            goInvoice: true,
            href: response.data.href
          }))
        })
      })

      alert(number);
    })
  }


  render() {
    const {auth, authError, dataError, data } = this.props;
    //let {paquete} = this.props.match.params
    console.log(data);
    var user = auth.uid;
    if (user != null) {

    }
    if (this.state.aceptado && this.state.mostrar ) {
      return (
        <MDBContainer>
          <h1>Realizar Pago de Inscripción</h1>
          <MDBRow>
            {this.state.paquete === 'plataforma' && <BotonPlataforma />}
            {this.state.paquete === 'despegue' && <BotonDespegue />}
            {this.state.paquete === 'explora' && <BotonExplora />}
            {this.state.paquete === 'descubre' && <BotonDescubre />}
            {this.state.paquete === 'test' && <BotonEjemplo />}
          </MDBRow>
        </MDBContainer>
      )
    }
    return (
      <MDBContainer>
        <h1>Pago Inscripción</h1>
        <MDBRow>
          <p className="h4 text-center">Datos Facturación</p>
        </MDBRow>
        <form className="needs-validation" onSubmit={this.handleSubmit.bind(this)} noValidate>
        <div class="d-flex flex-column col-md-8 item-center">
            <MDBInput
              value={this.state.rfc}
              onChange={this.handleChange}
              type="text"
              id="rfc"
              name="rfc"
              label="RFC"
              required
            >
              <div className="invalid-feedback">
                 {i18n.t('proveeDatosValidos',{s: 'RFC'})}
              </div>
              <div className="valid-feedback">{i18n.t('Correcto')}</div>
            </MDBInput>
            <MDBInput
              value={this.state.nombreFiscal}
              onChange={this.handleChange}
              type="text"
              id="nombreFiscal"
              name="nombreFiscal"
              label="Nombre Fiscal"
              required
            >
              <div className="invalid-feedback">
                {i18n.t('proveeDatosValidos',{s: 'Nombre Fiscal'})}
               </div>
              <div className="valid-feedback">{i18n.t('Correcto')}</div>
            </MDBInput>
            <MDBInput
              value={this.state.usoCFDI}
              onChange={this.handleChange}
              type="text"
              id="usoCFDI"
              name="usoCFDI"
              label="Uso CFDI"
            >
            </MDBInput>
            <MDBInput
              value={this.state.tipoPago}
              onChange={this.handleChange}
              type="text"
              id="tipoPago"
              name="tipoPago"
              label="Tipo de Pago"
            >
            </MDBInput>
            <MDBInput
              value={this.state.condicionesPago}
              onChange={this.handleChange}
              type="text"
              id="condicionesPago"
              name="condicionesPago"
              label="Condiciones de Pago"
            >
            </MDBInput>
            <MDBInput
              value={this.state.observacionesPago}
              onChange={this.handleChange}
              type="text"
              id="observacionesPago"
              name="observacionesPago"
              label="Observaciones"
            >
            </MDBInput>
            <MDBInput
              value={this.state.correoFacturacion}
              onChange={this.handleChange}
              type="email"
              id="correoFacturacion"
              name="correoFacturacion"
              label="Correo para Facturación"
              required
            >
              <div className="invalid-feedback">
               {i18n.t('proveeDatosValidos',{s: '$t(correo)'})}
               </div>
               <div className="valid-feedback">{i18n.t('Correcto')}</div>
            </MDBInput>
            <MDBInput
                 label='Contrato de Adehesión'
                 type="checkbox"
                 group
                 validate
                 id="invalidCheck"
                 required
                 onChange={this.handleChangeCheckbox}
             >
             <div className="invalid-feedback">
                 Debe estar de acuerdo.
             </div>
             <div className="valid-feedback">{i18n.t('Correcto')}</div>
             </MDBInput>
             {this.state.aceptado &&   <MDBContainer >
                 <h1>Contrato de Adehesión</h1>
                 <EmbedsPage onScroll={this.handleScroll}/>
               </MDBContainer>}
        <MDBBtn rounded className="float-left"  type="submit">
            Continuar Pago
        </MDBBtn>
        </div>
        </form>

      </MDBContainer>
     )
  }
}

const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth,
      authError: state.auth.authError,
      dataError: state.auth.dataError,
      data: state.getData,
      userData: state.getUser,
    }
  }

const mapDispatchToProps = (dispatch)=> {
    return {
      createOrden: (ordencompra) => dispatch(createOrden(ordencompra)),
      getData: (uid) => dispatch(getRegistry(uid)),
      getAUser: (id) => {dispatch(getAUser(id))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(PagoRegistro)
