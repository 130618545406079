/* eslint-disable import/no-dynamic-require,global-require */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from './messages';
import moment from 'moment';
import { getLocaleFromUserAgent } from './services';
import 'moment/locale/es';
import 'moment/locale/pt';

const lngDetector = new LanguageDetector();
lngDetector.addDetector({
  name: 'LanguageDetector',
  // in both methods there's the parameter options available
  lookup() {
    const languageSettings = getLocaleFromUserAgent(navigator);
    console.log(languageSettings);
    // try {
    //   if (languageSettings.locale !== 'en')
    //     require(`../node_modules/moment/locale/${languageSettings.locale}.js`);
    // } catch (e) {
    //   Logger.error(e);
    // }
    moment.locale(languageSettings.locale);
    return languageSettings.locale;
  },
  cacheUserLanguage(lng) {
    localStorage.setItem('i18nextLng', lng);
  },
});

// const locale = window.navigator.userLanguage || window.navigator.language;
// if (locale !== 'en')
//   import(`../node_modules/moment/locale/${locale}.js`).then(() => moment.locale(locale));
// if locale doesnt exist, we could split - take first part

async function acceptMessages() {
  const res = await import('./messages').then(m => m.default);
  Object.keys(res).forEach(lang => {
    Object.keys(res[lang]).forEach(namespace => {
      i18n.addResourceBundle(lang, namespace, res[lang][namespace], true, true);
    });
  });

  i18n.emit('loaded');
}
i18n.use(lngDetector).init({
  // we init with resources
  resources,
  fallbackLng: 'en',
  debug: process.env.NODE_ENV !== 'production',
  wait: true,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    wait: true,
  },

  detection: {
    order: [
      'navigator',
      'LanguageDetector',
      'localStorage',
      'querystring',
      'cookie',
      'htmlTag',
      'path',
      'subdomain',
    ],
    caches: ['localStorage', 'cookie'],
  },
});

if (module.hot) {
  module.hot.accept('./messages', acceptMessages);
}

export default i18n;
