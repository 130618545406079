import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ApmRoute } from '@elastic/apm-rum-react'
import Home from './components/layout/Home'
import Navbar from './components/layout/Navbar'
import Header from './components/layout/Header'
import Dashboard from './components/dashboard/Dashboard'
import SchoolDetails from './components/projects/ProjectDetails'
import SignIn from './components/auth/SignIn'
import SignUpFree from './components/auth/SignUpVideo'
import SignUp from './components/auth/SignUp'
import SignUpSteps from './components/auth/SignUpSteps'
import CreateSchool from './components/escuelas/Registro'
import BlogPage from './components/dashboard/Blogs'
import BlogFirst from './components/dashboard/Blogs1'
import OrdenCompraPage from './components/ordenes/ordencompra'
import PagoRegistro from './components/ordenes/PagoRegistro'
import PagoForm from './components/pagos/PagoOpenpay'
import OrdenCompraForm from './components/ordenes/FormaOC'
import TablaPage from './components/ordenes/tabla'
import OrdenesList from './components/ordenes/List'
import Listado from './components/escuelas/Listado'
import CreaText from './components/ordenes/creaText'
import Call from './components/call/Call'
import CancelPago from './components/pagos/Cancel'
import SuccessPago from './components/pagos/Success'

import './App.css';

class App extends Component {
  render() {
    return (

      <BrowserRouter>
        <div className="App">
          <Header />
          <Navbar />
          <Switch>
            <ApmRoute exact path='/' component={Dashboard} />
            <ApmRoute exact path='/demo'component={Dashboard} />
            <ApmRoute path='/escuela/:id' component={SchoolDetails} />
            <ApmRoute path='/escuelas/registro' component={CreateSchool} />
            <ApmRoute path='/signin' component={SignIn} />
            <ApmRoute path='/signup/base' component={SignUpFree} />
            <ApmRoute path='/signup/plataforma' component={SignUp} />
            <ApmRoute path='/signup/despegue' component={SignUp} />
            <ApmRoute path='/signup/descubre' component={SignUp} />
            <ApmRoute path='/signup/explora' component={SignUp} />
            <ApmRoute path='/registro' component={SignUpSteps}/>
            <ApmRoute path='/create/:paquete' component={OrdenCompraForm} />
            <ApmRoute path='/blogs' component={BlogPage} />
            <ApmRoute path='/blog1' component={BlogFirst} />
            <ApmRoute path='/orden' component={OrdenCompraPage} />
            <ApmRoute path='/pago/:paquete' component={PagoForm} />
            <ApmRoute path='/tabla' component={TablaPage}/>
            <ApmRoute path='/escuelas' component={Listado}/>
            <Route path='/admin' component={OrdenesList}/>
            <Route path='/editor' component={CreaText}/>
            <Route path='/call' component={Call}/>
            <Route path='/cancel' component={CancelPago}/>
            <Route path='/success' component={SuccessPago}/>
          </Switch>
        </div>
      </BrowserRouter>

    );
  }
}
export default App;
