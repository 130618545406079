import React, { useEffect, useState, useRef } from 'react';
import Peer from "simple-peer";
import styled from "styled-components";
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const Video = styled.video`
  border: 1px solid blue;
  width: 50%;
  height: 50%;
`;

function Call(props) {
  console.log(props);
  const { auth, firestore, location } = props;
  if (auth.uid) {console.log(auth.uid);
    addUserToCalls(auth.uid, auth.displayName);
  } else if (location.state) {
    console.log(location.state.callUser);
    console.log(props);

  }
  const [yourID, setYourID] = useState("");
  const [users, setUsers] = useState({});
  const [stream, setStream] = useState();
  const [receivingCall, setReceivingCall] = useState(false);
  const [caller, setCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState();
  const [callAccepted, setCallAccepted] = useState(false);

  const userVideo = useRef();
  const partnerVideo = useRef();

  function addUserToCalls(userId, userName) {
    console.log("addUserToCalls");
    return firestore.update(
        { collection: "calls", doc: userId },
        {
          userId: userId,
          name: userName
        }
    ).then()
    .catch((error) =>
      {console.log(error);}
    );
  };
  function addCaller(userId) {
    console.log("addCaller");
    if (!yourID && yourID.length > 0) {
      console.log(yourID);
      return null;
    } else {
     return firestore.add(
        { collection: "calls", doc: userId, subcollections: [{collection : "calling", doc: null}] },
        {
          calling: true,
          accepted: false
        }
    ).then((data) => {
      console.log(data.id);
      setYourID(data.id);
      //callPeer(userId);
    })
    .catch((error) =>
      {console.log(error);}
    );
  }
  };
  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
      setStream(stream);
      if (userVideo.current) {
        userVideo.current.srcObject = stream;
      }
    })
    if (auth.uid) {
      console.log(auth.uid);
      setYourID(auth.uid);
      //addUserToCalls(auth.uid, auth.displayName);
    }
    firestore.onSnapshot({ collection: "calls", doc: auth.uid },
    function(doc) {
        var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        console.log(source, " data: ", doc.data());
    });
    //socket.current.on("yourID", (id) => {
    //  setYourID(id);
    //})
    //socket.current.on("allUsers", (users) => {
    //  setUsers(users);
    //})
    if (auth.uid) {
      firestore.onSnapshot({ collection: "calls", doc: auth.uid, subcollections: [{collection : "calling" }] },
      function(doc) {
          var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
          console.log(source, " data: ", doc.data());
      });
    }
    //socket.current.on("hey", (data) => {
    //  setReceivingCall(true);
    //  setCaller(data.from);
    //  setCallerSignal(data.signal);
    //})
  }, []);

  function callPeer(id) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      config: {

        iceServers: [
            {
                urls: "stun:numb.viagenie.ca",
                username: "sultan1640@gmail.com",
                credential: "98376683"
            },
            {
                urls: "turn:numb.viagenie.ca",
                username: "sultan1640@gmail.com",
                credential: "98376683"
            }
        ]
    },
      stream: stream,
    });

    peer.on("signal", data => {
      console.log("llamando");
      //socket.current.emit("callUser", { userToCall: id, signalData: data, from: yourID })
      firestore.update(
          { collection: "calls", doc: id, subcollections: [{collection : "calling", doc: yourID}] },
          {
            userToCall: id,
            signalData: data,
            from: yourID
          }
      ).then()
      .catch((error) =>
        {console.log(error);}
      );
    })

    peer.on("stream", stream => {
      if (partnerVideo.current) {
        partnerVideo.current.srcObject = stream;
      }
    });

    //socket.current.on("callAccepted", signal => {
    //  setCallAccepted(true);
    //  peer.signal(signal);
    //})

  }

  function acceptCall() {
    setCallAccepted(true);
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: stream,
    });
    peer.on("signal", data => {
      console.log("AceptarLlamada");
      //socket.current.emit("acceptCall", { signal: data, to: caller })
    })

    peer.on("stream", stream => {
      partnerVideo.current.srcObject = stream;
    });

    peer.signal(callerSignal);
  }

  let UserVideo;
  if (stream) {
    UserVideo = (
      <Video playsInline muted ref={userVideo} autoPlay />
    );
  }

  let PartnerVideo;
  if (callAccepted) {
    PartnerVideo = (
      <Video playsInline ref={partnerVideo} autoPlay />
    );
  }

  let incomingCall;
  if (receivingCall) {
    incomingCall = (
      <div>
        <h1>{caller} is calling you</h1>
        <button onClick={acceptCall}>Accept</button>
      </div>
    )
  }
  return (
    <Container>
      <Row>
        {UserVideo}
        {PartnerVideo}
      </Row>
      <Row>
        {Object.keys(users).map(key => {
          if (key === yourID) {
            return null;
          }
          return (
            <button onClick={() => callPeer(key)}>Call {key}</button>
          );
        })}
      </Row>
      <Row>
        {incomingCall}
      </Row>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}


const mapStateToProps = (state) => {
	console.log(state)
	return {
		auth: state.firebase.auth
	}
}
export default compose(
  withFirestore,
	connect(mapStateToProps, mapDispatchToProps),)(Call);
