import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { MDBContainer, MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import base from './base.png';
import plataforma from './plataforma.png';
import despegue from './despegue.png';
import explora from './explora.png';
import descubre from './descubre.png';
import aventura from './aventura.png';
import conquista from './conquista.png';

const OrdenCompra = (props) => {
  const {auth, onSelect, paquete} = props;
  const link = auth.uid ? 'pago':'signup';
  return (
    <>
      <div className="black-text">
      <MDBRow>
        <MDBCol lg="1">
        <p>{paquete}</p>
        </MDBCol>
      <MDBCol lg="2">
        <MDBCard pricing style={{ height: "35rem" }}>
            <MDBCardImage className="card-img-top" src={base} waves/>
          <MDBCardBody>
            <MDBCardTitle>Base</MDBCardTitle>
            <MDBCardText className="black-text">

                <ul style={{fontSize:"10px", paddingInlineStart:"15px"}}>
                  <li>Registro</li>
                  <li>Información Comercial</li>
                  <li>Logotipo</li>
                  <li>Fotografía Institucional</li>
                  <li>Back-end de captura</li>
                </ul>
                <br></br>
                &nbsp;
                <strong>Gratis</strong>
            </MDBCardText>
            {!auth.uid ? (
            <MDBBtn onClick={() => {onSelect('base')}}
            class="btn btn-primary">Elegir</MDBBtn>) : (null)}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
          <MDBCol lg="2">
            <MDBCard pricing style={{ height: "35rem" }}>
                <MDBCardImage className="card-img-top" src={plataforma} waves/>
              <MDBCardBody>
                <MDBCardTitle>Plataforma</MDBCardTitle>
                <MDBCardText className="black-text">
                  Base +
                    <ul style={{fontSize:"10px", paddingInlineStart:"15px"}}>
                      <li>Suscripción Eduk2</li>
                      <li>Ubicación por Filtros</li>
                      <li>Botón reactivo en URL</li>
                      <li>Despliegue "Conoce la Escuela"</li>
                      <li>Carga informativa inicial</li>
                      <li>Datos Analíticos</li>
                    </ul>
                    <br></br>
                    &nbsp;
                    <strong>$950.ºº</strong><small> x mes*</small>
                </MDBCardText>
                <MDBBtn onClick={() => {onSelect('plataforma')}}
                  class="btn btn-primary">Elegir</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="2">
            <MDBCard pricing style={{ height: "35rem" }}>
              <MDBCardImage className="card-img-top" src={despegue} waves />
              <MDBCardBody>
                <MDBCardTitle>Despegue</MDBCardTitle>
                <MDBCardText className="black-text">
                  Plataforma +
                  <ul style={{fontSize:"10px", paddingInlineStart:"15px"}}>
                    <li>Galeria Fotográfica (8)</li>
                    <li>Botones reactivos </li>
                    <ul>
                      <li>Booking *</li>
                      <li>Contacto</li>
                      <li>Redes Sociales</li>
                    </ul>
                    <li>Despliegue de servicios</li>
                    <li>Entrevista para Blog</li>
                  </ul>
                  <strong>$1,250.ºº</strong><small> x mes*</small>
                </MDBCardText>
                <MDBBtn onClick={() => {onSelect('despegue')}} class="btn btn-primary">Elegir</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="2">
            <MDBCard pricing style={{ height: "35rem" }}>
              <MDBCardImage className="card-img-top" src={explora} waves />
              <MDBCardBody>
                <MDBCardTitle>Explora</MDBCardTitle>
                <MDBCardText className="black-text">
                  Despegue +
                  <ul style={{fontSize:"10px", paddingInlineStart:"15px"}}>
                    <li>Galeria Fotográfica (20)</li>
                    <li>Video Institucional</li>
                    <li>Botones reactivos </li>
                    <ul>
                      <li>Promociones</li>
                    </ul>
                    <li>Pop Up’s descriptivo de servicios escolares</li>
                    <li>Artículo anual en blog Eduk2</li>
                  </ul>
                  <strong>$1,550.ºº</strong><small> x mes*</small>
                </MDBCardText>
                <MDBBtn onClick={() => {onSelect('explora')}} class="btn btn-primary">Elegir</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="2">
            <MDBCard pricing style={{ height: "35rem" }}>
              <MDBCardImage className="card-img-top" src={descubre} waves />
              <MDBCardBody>
                <MDBCardTitle>Descubre</MDBCardTitle>
                <MDBCardText className="black-text">
                Explora +
                <ul style={{fontSize:"10px", paddingInlineStart:"15px"}}>
                  <li>Video por Nivel</li>
                  <li>Botones reactivos </li>
                  <ul>
                    <li>Servicios</li>
                  </ul>
                  <li>Pop Up’s con video (3)</li>
                  <li>Entrevista anual Vlog Eduk2</li>
                  <li>Link a recorrido 3D</li>
                </ul>
                <strong>$1,950.ºº</strong><small> x mes*</small>
                </MDBCardText>
                <MDBBtn onClick={() => {onSelect('descubre')}} class="btn btn-primary">Elegir</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        </div>
        <MDBRow center>
        <p className='mt-2'> <MDBIcon icon='asterisk' /> Precios más IVA;
          Por cada campus;
          Cuota de Inscripción = 2 mensualidades al momento de activación;
          Niveles adicionales $200.ºº c/u
        </p>
        </MDBRow>
      </>
  )
}
const mapDispatchToProps = (dispatch) => {
	return {
	}
}


const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth
	}
}
export default compose(
	connect(mapStateToProps, mapDispatchToProps),)(OrdenCompra);
