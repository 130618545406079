const initState = {
  authError: null
}

const authReducer = (state = initState, action) => {
  switch(action.type){
    case 'LOGIN_ERROR':
      console.log('login error');
      return {
        ...state,
        authError: 'Login failed'
      }

    case 'LOGIN_SUCCESS':
      console.log('login success');
      return {
        ...state,
        authError: null
      }

    case 'SIGNOUT_SUCCESS':
      console.log('signout success');
      return state;

    case 'SIGNUP_SUCCESS':
      console.log('signup success')
      return {
        ...state,
        authError: null,
        dataError: null
      }

    case 'SIGNUP_ERROR':
      console.log('signup error')
      console.log(action)
      return {
        ...state,
        authError: action.err ? action.err.message: null,
        dataError: action.error
      }
    case 'UPLOAD_SUCCESS':
      console.log('upload sucess')
      return {
        ...state,
        authError: null
      }
    default:
      return state
  }
};

export default authReducer;
