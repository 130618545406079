import React from "react";
import plataforma from '../ordenes/plataforma.png';
import {
  MDBContainer, MDBRow,MDBCol, MDBCard,MDBCardUp,MDBAvatar,MDBCardBody,MDBIcon,
  MDBListGroup, MDBListGroupItem, MDBBadge
}  from "mdbreact";
import Openpay from 'openpay'

const openpay = new Openpay('mhge3mbbb33cckorof7y','sk_fc6f922dc4364c6490ad1979c54ff02e', false);
class SuccessPago extends React.Component {

  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
  }
  state = {
    payId: '',
    charge: {},
  }
  componentDidMount(){
    console.log('componentDidMount')
    let payId = (new URLSearchParams(this.props.location.search)).get("id");
    localStorage.setItem('lastPayment',payId);
    this.getData(payId);
    this.setState({
      payId
    })
  }
  getData(transactionId) {
    let data = null;
    openpay.charges.get(transactionId, (error, charge) => {
      console.log(charge);
      data = charge;
      if (data) {
        this.setState({charge: data});
      }
    });
  }

  render() {
    console.log(this.state.charge)
    //let payId = (new URLSearchParams(this.props.location.search)).get("id")

    return (
      <MDBContainer className="mt-5">
      <MDBRow>
      <MDBCol md='3'>
      </MDBCol>
      <MDBCol md='6'>
        <MDBCard testimonial>
          <MDBCardUp gradient='aqua' />
          <MDBAvatar className='mx-auto white'>
            <img
              src={plataforma}
              alt=''
            />
          </MDBAvatar>
          <MDBCardBody>
            <h2 className='card-title'><MDBIcon icon='check' className="green-text mx-3"/>Pago Aceptado</h2>
            <hr />
            <p>
              <MDBIcon icon='quote-left' /> Se ha registrado el pago con la siguiente información:{' '}
              <MDBIcon icon='quote-right' />
            </p>
            <MDBListGroup>
              <MDBListGroupItem className="d-flex justify-content-between align-items-center">Transacción:<MDBBadge
                  color="primary">{this.state.charge.id} </MDBBadge>
              </MDBListGroupItem>
              <MDBListGroupItem className="d-flex justify-content-between align-items-center">Cargo: <MDBBadge color="primary"
                >{this.state.charge && this.state.charge.description ? this.state.charge.description: ''}</MDBBadge>
              </MDBListGroupItem>
              <MDBListGroupItem className="d-flex justify-content-between align-items-center">Monto:<MDBBadge
                  color="primary">${this.state.charge.amount}.00 </MDBBadge>
              </MDBListGroupItem>
              <MDBListGroupItem className="d-flex justify-content-between align-items-center">Escuela: <MDBBadge color="primary"
                  pill> {this.state.charge.customer ? this.state.charge.customer.name  + ' ' : ''}</MDBBadge>
              </MDBListGroupItem>
              <MDBListGroupItem className="d-flex justify-content-between align-items-center">Titular Tarjeta:<MDBBadge
                  color="primary">{this.state.charge.card ? this.state.charge.card.holder_name  + ' ' : ''}</MDBBadge>
              </MDBListGroupItem>

            </MDBListGroup>
          </MDBCardBody>
        </MDBCard>
        </MDBCol>
      </MDBRow>

      </MDBContainer>
    )
  }
}
export default SuccessPago;
