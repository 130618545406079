import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import i18n from 'i18next';

import { signIn } from '../../store/actions/authActions'

class SignIn extends Component {
  state = {
    email: '',
    password: '',
    toAdmin: false,
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state)
    if (!this.props.authError) {
      this.setState(() => ({
        toAdmin: true
      }))
    }
  }
  render() {
    const { auth } = this.props;
    if (this.state.toAdmin === true) {
      if (auth.uid) return <Redirect to='/orden' />
    }
    const { authError } = this.props;
    //if (auth.uid) return <Redirect to='/' />

    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol md="6">
            <form onSubmit={this.handleSubmit}>
              <p className="h4 text-center mb-4">{i18n.t('Login')}</p>
              <label htmlFor="email" className="grey-text">
                {i18n.t('Username')}
              </label>
              <input type="email" id='email' onChange={this.handleChange} className="form-control" />
              <br />
              <label htmlFor="password" className="grey-text">
                {i18n.t('Password')}
              </label>
              <input type="password" id="password" onChange={this.handleChange} className="form-control" />
              <div className="text-center mt-4">
                <MDBBtn color="indigo" type="submit">{i18n.t('Login')}</MDBBtn>
                <div className="center red-text">
                  { authError ? <p>{authError}</p> : null }
                </div>
              </div>
            </form>
          </MDBCol>
        </MDBRow>
        <MDBRow>
        <MDBCol md="6" className="d-flex justify-content-center">
                <p className="font-small grey-text mt-3">
                  No estas registrado?
                  <a
                    href="/orden"
                    className="dark-grey-text ml-1 font-weight-bold"
                  >
                    Registro
                  </a>
                </p>
              </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return{
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
