export const getData = (uid) => {
  console.log('getData');
  return (dispatch, getState, {getFirebase}) => {
    try {
      const firebase = getFirebase();
      const functions = firebase.functions();
      console.log(uid);
      var addMessage = functions.httpsCallable('getMessage');
      addMessage({uid: uid}).then(function(result) {
        // Read result of the Cloud Function.
        console.log(result);
        // ...
        const data = result.data;
        console.log(data);
        dispatch({ type: 'GET_CONFIG', data });
      })
      .catch(error => {
        console.log(error);
      });

    }
    catch (Error) {
      console.log(Error);
    }

  }
}

export const getRegistry = (uid) => {
  console.log('getRegistry');
  return (dispatch, getState, {getFirebase}) => {
    try {
      const firebase = getFirebase();
      const functions = firebase.functions();
      console.log(uid);
      var addMessage = functions.httpsCallable('getRegistry');
      addMessage({uid: uid}).then(function(result) {
        // Read result of the Cloud Function.
        console.log(result);
        // ...
        const data = result.data;
        console.log(data);
        dispatch({ type: 'GET_REGISTRY', data });
      })
      .catch(error => {
        console.log(error);
      });

    }
    catch (Error) {
      console.log(Error);
    }

  }
}
