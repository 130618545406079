import React from 'react'
import { NavbarNav, NavItem, NavLink } from 'mdbreact'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'

const SignedInLinks = (props) => {
  return (
    <NavbarNav left>
    <NavItem>
      <NavLink to="/admin">Datos de tu escuela</NavLink>
    </NavItem>
    <NavItem>
      <NavLink to="/blogs">¿Sabias qué?</NavLink>
    </NavItem>
    <NavItem>
      <NavLink to="/" onClick={props.signOut}>	Cerrar	Sesión</NavLink>
    </NavItem>
    </NavbarNav>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(null, mapDispatchToProps)(SignedInLinks)
