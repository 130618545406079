import React from 'react'
import { NavbarNav, NavItem, NavLink} from 'mdbreact'

const SignedOutLinks = () => {
  return (
<NavbarNav left>
    <NavItem>
      <NavLink to="/blogs">¿Sabias qué?</NavLink>
    </NavItem>
    <NavItem>
      <NavLink to={{pathname:'https://www.youtube.com/channel/UCZiQeQJKnb8XD1JWoGeFgRg'}} target="_blank">
      Eduk2 TV
      </NavLink>
    </NavItem>
  </NavbarNav>
  )
}

export default SignedOutLinks
