import React from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBView, MDBBtn } from "mdbreact";
import { Link } from 'react-router-dom'
const BlogPage = () => {
  return (
    <MDBCard className="my-5 px-5 pb-5">
      <MDBCardBody>
        <h2 className="h1-responsive font-weight-bold text-center my-5">
          Blogs
        </h2>
        <p className="text-center w-responsive mx-auto mb-5">
          En <strong>eduk2</strong>, permea un ambiente de constante investigación para poder responder a los desafíos que los tiempos presentan, por lo que, contamos con un equipo de especialistas en diversas áreas de desarrollo en marketing educativo que día con día crean y desarrollan nuevos contextos de interacción para responder a tan importantes retos.
        </p>
        <MDBRow>
          <MDBCol lg="5" xl="4">
            <MDBView hover className="rounded z-depth-1-half mb-lg-0 mb-4">
              <img
                className="img-fluid"
                src="images/image001.jpg"
                alt=""
              />
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
          </MDBCol>
          <MDBCol lg="7" xl="8">
            <h3 className="font-weight-bold mb-3 p-0">
              <strong>Como crear hijos autónomos</strong>
            </h3>
            <p className="dark-grey-text">
            ¿Te gustaría que tus hijos fueran más autónomos? ¿Quieres saber cómo lograrlo?

            Si les inculcamos el sentido de la autonomía desde los primeros años, crecerán con una buena autoestima, con confianza en sí mismos, valientes, atrevidos, alegres, optimistas y preparados para perseguir sus sueños.

            </p>
            <p>
              por <a href="mailto://info@eduk2.mx" className="font-weight-bold">Eduk2</a>, 09/07/2021
            </p>
            <Link to='blog1'>
              <MDBBtn color="primary" size="md">
                Leer más
              </MDBBtn>
            </Link>
          </MDBCol>
        </MDBRow>
        <hr className="my-5" />
        <MDBRow>
          <MDBCol lg="5" xl="4">
            <MDBView hover className="rounded z-depth-1-half mb-lg-0 mb-4">
              <img
                className="img-fluid"
                src="https://static.wixstatic.com/media/nsplsh_52554a595558776a337330~mv2_d_4592_3448_s_4_2.jpg/v1/fill/w_568,h_426,fp_0.50_0.50,q_90/nsplsh_52554a595558776a337330~mv2_d_4592_3448_s_4_2.webp"
                alt=""
              />
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
          </MDBCol>
          <MDBCol lg="7" xl="8">
            <h3 className="font-weight-bold mb-3 p-0">
              <strong>Contexto del mercado educativo</strong>
            </h3>
            <p className="dark-grey-text">
            El contexto del mercado es para un equipo administrativo como un mapa para los exploradores. Nos permite entender el terreno y planear
            </p>
            <p>
              por <a href="mailto://info@eduk2.mx" className="font-weight-bold">Eduk2</a>, 30/10/2019
            </p>
            <MDBBtn color="primary" size="md" tag="a" href="https://www.eduk2.org/post/contexto-del-mercado-educativo">
              Leer más
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <hr className="my-5" />
        <MDBRow>
          <MDBCol lg="5" xl="4">
            <MDBView hover className="rounded z-depth-1-half mb-lg-0 mb-4">
              <img
                className="img-fluid"
                src="https://static.wixstatic.com/media/850e72_c50a1a011b0c453fa3d21b8138a0ab18~mv2.jpg/v1/fill/w_568,h_399,fp_0.50_0.50,q_90/850e72_c50a1a011b0c453fa3d21b8138a0ab18~mv2.webp"
                alt=""
              />
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
          </MDBCol>
          <MDBCol lg="7" xl="8">
            <h3 className="font-weight-bold mb-3 p-0">
              <strong>La nueva generación de padres que está llegando a la escuela</strong>
            </h3>
            <p className="dark-grey-text">
            Hablemos de la nueva generación de papás que está llegando a la escuela. Es disruptiva, diferente y fascinante, pero ¿tu escuela esta lista?

            </p>
            <p>
              por <a href="mailto://info@eduk2.mx" className="font-weight-bold">Eduk2</a>, 23/10/2019
            </p>
            <MDBBtn color="primary" size="md" tag="a" href="https://www.eduk2.org/post/una-nueva-generaci%C3%B3n-de-padres-que-est%C3%A1-llegando-a-la-escuela">
              Leer Más
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <hr className="my-5" />
        <MDBRow>
          <MDBCol lg="5" xl="4">
            <MDBView hover className="rounded z-depth-1-half mb-lg-0 mb-4">
              <img
                className="img-fluid"
                src="images/ApegoBlog.jpg"
                alt=""
              />
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
          </MDBCol>
          <MDBCol lg="7" xl="8">
            <h3 className="font-weight-bold mb-3 p-0">
              <strong>¿Qué es el apego? ¿Es bueno o es malo?</strong>
            </h3>
            <p className="dark-grey-text">
            El apego es ese vínculo psicoemocional que desarrollan los niños con las personas de las cuales depende su cuidado y desarrollo. Comúnmente las figuras de apego son ...

            </p>
            <p>
              por <a href="mailto://info@eduk2.mx" className="font-weight-bold">Eduk2</a>, 3/05/2021
            </p>
            <MDBBtn color="primary" size="md" tag="a" href="https://www.eduk2.org/blog">
              Leer Más
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
}

export default BlogPage;
