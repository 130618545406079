import React, { Component } from 'react'
import {
  MDBContainer,
  MDBRow,
  MDBCol
} from "mdbreact";

import { ReactiveBase, SingleDropdownList} from '@appbaseio/reactivesearch';
//import CouponPage from './Cupones'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import i18n from 'i18next'
import aws4 from 'aws4'
//import ondasAzul from '../../OndasAzules.svg'
import ondasNaranja from '../../OndaNaranja.svg'
import ninos from '../../niños.png'
//import ninosnaranjas from '../../NIÑOS MOVIL.png'
import buscar from '../../buscar.svg'
//const functions = require('firebase-functions');


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.signRequest = this.signRequest.bind(this);
  }
  state = {
    edad: 6,
    tipo: "",
    city: ""
  };

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  signRequest = (request) => {
      const hostUrl = 'https://search-eduk2-5n3hdjvuazesrqzv7b33aiag3e.us-east-2.es.amazonaws.com';
      const host = 'search-eduk2-5n3hdjvuazesrqzv7b33aiag3e.us-east-2.es.amazonaws.com';
      const app = 'educa2';

      const credentials = {
        accessKeyId: "AKIA5HCNKTSFFHCNQ2TM",
        secretAccessKey: "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",
        sessionToken: ""
      }
      if (credentials) {
        const { accessKeyId, secretAccessKey, sessionToken } = credentials;
        const opts = {
          method: 'POST',
          service: 'es',
          region: 'us-east-2',
          path: `/${app}/_msearch`,
          host: host,
          body: request.body,
          headers: request.headers,
          url: `${hostUrl}/${app}/_msearch`
        };
        const signedRequest = aws4.sign(opts, { accessKeyId, secretAccessKey, sessionToken });
        delete signedRequest.headers.Host;
        delete signedRequest.headers['Content-Length'];
        return signedRequest;
        //aws4.sign mutates the headers, adding the signature headers
      }
    };
  render() {
    //const { projects, auth, notifications } = this.props;
    //if (!auth.uid) return <Redirect to='/signin' />
//     <MDBView>
//   <img
//     src={ninos}
//     height="300"
//     className="img-fluid"
//     alt=""
//   />
//   <MDBMask className="d-flex flex-row-reverse justify-content-end fixed-bottom">
//       <img className="fixed-bottom" src={ondasNaranja}  alt=""/>
//     </MDBMask>
// </MDBView>
// <MDBRow>
//   <MDBCol size="12" md="5">
//     <img src={ondasAzul}  alt=""/>
//   </MDBCol>
// </MDBRow>
// <MDBRow>
//   <MDBCol size="12" md="5">
//
//     <img src={ninos} height="300" alt=""/>
//     <img src={ondasNaranja}  alt=""/>
//   </MDBCol>
// </MDBRow>
//

    return (
      <MDBContainer fluid className="bg min-vh-100">
        <MDBRow center flex className="bg-fondo-azul">
          <MDBCol size="12" lg="10" sm="12">
            <div className="text-white ">
              <p className="h3 text-center px-1 py-1 col-sm-8 col-sm-offset-2">{i18n.t('¡Encuentra tu escuela!')}</p>

              <ReactiveBase
                  app="educa2"
                  //app="escuelas"
                  //transformRequest={this.signRequest}
                  //url="https://search-eduk2-5n3hdjvuazesrqzv7b33aiag3e.us-east-2.es.amazonaws.com"
                  //credential="3slfiesotk:hs7hpzxkb2"
                  //url='https://elastic:r1hKakAnWxq0UUgKsMKyFdLf@e59eb1c9732b488d80314193d0afd09a.us-central1.gcp.cloud.es.io:9243/'
                  //url=functions.config().bonsai.url
                  //url='https://3slfiesotk:hs7hpzxkb2@eduk2-4709248868.us-west-2.bonsaisearch.net:443'
                  url='https://4xgg7rftje:q0vesrnzkv@eduk2-3683615528.us-east-1.bonsaisearch.net:443'
                  //credential="3slfiesotk:hs7hpzxkb2"
                  //url='http://10.204.243.160:9200'
                  //url='https://104.198.38.169:9243'
                  //url='http://13.58.236.83:9200'
                  //credentials='elastic:K7gCLuq48gGO9lhggRdaf5UW'
                  >
                  <MDBRow className="m-1">
                  <MDBCol size="10" md="4" className="m-1">
                    <SingleDropdownList
                      innerClass={{select: "rounded-select"}}
                      componentId="coloniaSearch"
                      dataField="city.keyword"
                      size={200}
                      //title="Ciudad"
                      placeholder="Ciudad"
                      URLParams={false}
                      showSearch={true}
                      searchPlaceholder='Teclee para buscar...'
                      showClear={true}
                      onValueChange={(value) => {
                          console.log("current value: ", value)
                          // set the state
                          // use the value with other js code
                          this.setState({
                            city: value,
                          })
                          window.ga('send', {
                            hitType: 'event',
                            eventCategory: 'FilterCity',
                            eventAction: 'click',
                            eventLabel: value
                          });
                        }
                      }
                    />
                    </MDBCol>
                    <MDBCol size="10" md="4" className="m-1">
                    <SingleDropdownList
                      componentId="actSearch"
                      dataField="nivel.keyword"
                      innerClass={{select: "rounded-select"}}
                      //title="Nivel"
                      placeholder="Nivel"
                      URLParams={false}
                      onValueChange={(value) => {
                          console.log("current value: ", value)
                          // set the state
                          // use the value with other js code
                          this.setState({
                            tipo: value,
                          })
                          window.ga('send', {
                            hitType: 'event',
                            eventCategory: 'FilterLevel',
                            eventAction: 'click',
                            eventLabel: value
                          });
                        }
                      }
                      react={{
                        and: ["coloniaSearch"]
                      }}
                    />
                    </MDBCol>
                    <MDBCol size="12" md="2" className="m-2">
                    <div className="float-right ">
                      <Link to={{pathname: "/escuelas", city: this.state.city, tipo: this.state.tipo }} >
                        <button className="btn py-0 z-depth-0 text-white" type="submit">
                          <img src={buscar} height="70"/>
                          <i src={buscar} className="fa fa-4x mr-1" size="5X"/>

                          <span className="px-2 center "></span>
                        </button>
                      </Link>
                    </div>
                    </MDBCol>

                    </MDBRow>
              </ReactiveBase>
              <br />
              <br />
              </div>
              <div className="mh-350 position-absolute text-center col-12 col-sm-6 col-md-7 my-n4 my-sm-4 my-md-2 my-lg-5 z-depth-0">
              <br />
              <br />
              <br />
              <p className="h-100 h5 py-4 my-n5">
                Explora	las	opciones	educativas	de	todo	tipo	en	tu	ciudad,	con	datos	confiables,	testimonios	de	padres	de	familia	y	alumnos,	fotografías	actualizadas	y	mucho	mas.
                <br/>
                Descubre	lo	fácil	que	puede	ser	encontrar	la	opción	educativa	para	ti.
              </p>

              </div>
            </MDBCol>
          </MDBRow>
      <MDBRow center className="position-relative my-n5 desktop">
      <MDBCol size="12" sm="12" className="d-md-none">
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      </MDBCol>
        <MDBCol size="12" md="12" className="pl-0 pr-0">

          <div className="float-right my-n5 my-sm-n5 mx-5 mx-sm-0 mx-md-5 z-depth-0">
            <img src={ninos} height="350" alt=""/>
          </div>
          <div className="w-100">
            <img src={ondasNaranja} alt=""/>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow center className="d-flex flex-column mobile">
        <MDBCol size="12" sm="12">
          <br/><br/><br/><br/><br/><br/><br/><br/>
        </MDBCol>
      </MDBRow>
    </MDBContainer>

    )
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    projects: state.firestore.ordered.projects,
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'projects' },
    { collection: 'notifications', limit: 3}
  ])
)(Dashboard)
