import React from 'react'

const EmbedsPage = (props) => {
  return (
    <div className="embed-responsive embed-responsive-16by9">
      <iframe title="Embeds Page" className="embed-responsive-item" src={props.url}
        allowfullscreen></iframe>
    </div>
  )
}

export default EmbedsPage;
