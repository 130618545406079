import React, { Component } from 'react';
import { MDBContainer, MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import MDBWysiwyg from 'mdb-react-wysiwyg';

class ModalEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      textData: ""
    }
  }


toggle = () => {
  console.log('toggle modal')
  this.props.onHide();
}
onChange = () => {
  var miData = document.querySelector(".mdb-wysiwyg-textarea").innerHTML;
  this.setState({
    textData: miData
  });
  this.props.close(miData)
}

render() {
  return (
    <MDBContainer>
      <MDBModal size="lg" isOpen={this.props.isOpen} toggle={this.toggle}>
        <MDBModalHeader toggle={this.toggle}>Información de la Escuela</MDBModalHeader>
        <MDBModalBody>
          <p className="mb-3">Historia, Misión, Visión</p>
          <hr />
          <div id='menana' class='menana'>
            <MDBWysiwyg initialContent={this.props.historia}
              value={this.props.historia}
            />
          </div>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="primary"  onClick={this.onChange}>Guardar</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
    );
  }
}

export default ModalEditor;
