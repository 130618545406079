import React from "react";
import {  MDBRow, MDBCol, MDBIcon } from "mdbreact";
import i18n from 'i18next';
import logo from '../../logo.jpg';

const WelcomePage = () => {
  return (
    <section className="my-5">
        <h2 className="h1-responsive font-weight-bold text-center my-5">
          {i18n.t('bienvenido')}
        </h2>
        <p className="lead grey-text w-responsive text-center mx-auto mb-5">
          {i18n.t('textoBienvenida')}
        </p>

        <MDBRow>
          <MDBCol lg="5" className="text-center text-lg-left">
            <img
              className="img-fluid"
              src={logo}
              alt=""
            />
          </MDBCol>
          <MDBCol lg="7">
            <MDBRow className="mb-3">
              <MDBCol size="1">
                <MDBIcon icon="share" size="lg" className="indigo-text" />
              </MDBCol>
              <MDBCol xl="10" md="11" size="10">
                <h5 className="font-weight-bold mb-3">{i18n.t('Seguridad')}</h5>
                <p className="grey-text">
                  {i18n.t('textoSeguridad')}
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
              <MDBCol size="1">
                <MDBIcon icon="share" size="lg" className="indigo-text" />
              </MDBCol>
              <MDBCol xl="10" md="11" size="10">
                <h5 className="font-weight-bold mb-3">{i18n.t('pasos')}</h5>
                <p className="grey-text">
                  {i18n.t('textoPasos')}
                </p>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </section>
  );
}

export default WelcomePage;
