import React, { useState }  from 'react'
import { Navbar,  NavbarNav, NavItem, NavLink, NavbarToggler, Collapse,  Dropdown, DropdownToggle, DropdownMenu,  DropdownItem } from "mdbreact";
import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import { connect } from 'react-redux'

const NavbarPage = (props) => {
  const [isOpen, setCollapsed] = useState(false);
  const { auth, profile } = props;
  console.log(auth);
  const links = auth.uid ? <SignedInLinks profile={profile} /> : <SignedOutLinks />;
  const toggleCollapse = () => setCollapsed(!isOpen);

  return (
          <Navbar expand="md" className="bg-company-orange"  dark >
              <NavbarToggler
                onClick={toggleCollapse}
              />
              <Collapse
                id="navbarCollapse3"
                isOpen={isOpen}
                navbar
              >
                <NavbarNav left>
                 {links}
                  <NavItem>
                    <Dropdown>
                      <DropdownToggle nav caret>
                        <div className="d-md-inline">Contacto</div>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem><a href="mailto://carolina.tovar@eduk2.mx?subject=Comentarios para Eduk2">Contacto</a></DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </NavItem>
                </NavbarNav>
                <NavbarNav right>
                  <NavItem>
                  <Dropdown>
                    <DropdownToggle nav caret>
                      <div className="d-md-inline">Soy escuela</div>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem><a href="/registro">Registro</a></DropdownItem>
                      <DropdownItem><a target="_blank" href="http://www.eduk2.org">eduk2.org</a></DropdownItem>
                      <DropdownItem><a href="/signin">Iniciar Sesión</a></DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  </NavItem>
                </NavbarNav>
              </Collapse>
          </Navbar>
  )
}

const mapStateToProps = (state) => {
  // console.log(state);
  return{
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default connect(mapStateToProps)(NavbarPage)
