const getGoal = (state = {}, action) => {

	const { data, userData } = action

	switch(action.type){
		case 'GET_GOAL':
			return {
				...state,
				data
			}
			break;
		case 'GET_USER':
				return {
					...state,
					userData
				};
				break;
		default:
			return state
	}
}

export default getGoal
