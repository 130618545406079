import React from "react";
import { Navbar, NavbarBrand, NavbarNav, NavItem, NavLink, NavbarToggler, Collapse, Fa, MDBRow, MDBCol} from "mdbreact";
import logo from '../../Eduk2.mx.gif';
import slogan from '../../slogan.gif';
import facebook from '../../I-FACEBOOK.svg';
import instagram from '../../I-INSTA.svg';
import youtube from '../../I-YOUTUBE.svg';
import redes from '../../I-RRSS.svg';
class NavbarPage extends React.Component {
  state = {
    isOpen: false
  };

  toggleCollapse = () => {this.setState({ isOpen: !this.state.isOpen })};

  render() {
    return (
      <Navbar className="bg-company-white" dark expand="sm" style={{marginTop: "0px"}}>
          <NavbarBrand href="/">
          <MDBRow>
            <MDBCol size="12" md="4"><img src={logo} height="50" alt=""/></MDBCol>
            <MDBCol size="10" md="6"><img src={slogan} height="40" alt="" /></MDBCol>
            <MDBCol size="2" md="2">
            <NavbarToggler
              right
              image={redes}
              onClick={this.toggleCollapse}
            />
            </MDBCol>
          </MDBRow>
          </NavbarBrand>
          <Collapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
            <NavbarNav right className="d-flex justify-content-end">
              <NavItem className="d-flex justify-content-end">
                <a className="black-text waves-effect waves-light px-2" href="https://www.instagram.com/eduk2mx/" target="_blank"><img src={instagram} height="20"/><Fa icon="instagram" className="d-none" /></a>
              </NavItem>
              <NavItem className="d-flex justify-content-end">
                <a className="black-text waves-effect waves-light px-2" href="https://www.youtube.com/channel/UCZiQeQJKnb8XD1JWoGeFgRg/featured" target="_blank"><img src={youtube} height="20"/><Fa icon="youtube" className="d-none"/></a>
              </NavItem>
              <NavItem className="d-flex justify-content-end">
                <a className="black-text waves-effect waves-light px-2" href="https://www.facebook.com/eduk2Mx/" target="_blank"><img src={facebook} height="20"/><Fa icon="facebook" className="d-none" /></a>
              </NavItem>
            </NavbarNav>
          </Collapse>
      </Navbar>
    );
  }
}

export default NavbarPage;
