export const signIn = (credentials) => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();

    firebase.auth().signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    ).then(() => {
      dispatch({ type: 'LOGIN_SUCCESS' });
    }).catch((err) => {
      dispatch({ type: 'LOGIN_ERROR', err });
    });

  }
}

export const signOut = () => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();

    firebase.auth().signOut().then(() => {
      dispatch({ type: 'SIGNOUT_SUCCESS' })
    });
  }
}

export const signUp = (newUser) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    console.log(newUser);
    firebase.auth().createUserWithEmailAndPassword(
        newUser.email,
        newUser.password
      ).then(resp => {
        resp.user.updateProfile({
          displayName: newUser.name
        });
        return firestore.collection('users').doc(resp.user.uid).set({
          name: newUser.name,
          email: newUser.email
        });
      }).then(() => {
        dispatch({ type: 'SIGNUP_SUCCESS' });
      }).catch((err) => {
        console.log(err);
        dispatch({ type: 'SIGNUP_ERROR', err });
      });
    }
}
export const signUpOld = (newUser) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    console.log(newUser);
    let error = [];
    if (!newUser.city) {
      error.push({message: 'Por favor provee una ciudad'})
    }
    if (!newUser.phone) {
      error.push({message: 'Por favor provee un teléfono'})
    }
    if (!newUser.uploadImages.foto_num1.url) {
      if (newUser.paquete === 'base') {
        error.push({message: 'Por favor provee el escudo de la escuela'})
      }
    }
    if (!newUser.comercialName) {
      error.push({message: 'Por favor provee el nombre de la escuela'})
    }
    if (!newUser.lema) {
      error.push({message: 'Por favor provee el lema de la escuela'})
    }

    if (error && error.length > 0) {
      dispatch({ type: 'SIGNUP_ERROR', error});
    } else {
      firebase.auth().createUserWithEmailAndPassword(
        newUser.email,
        newUser.password
      ).then(resp => {
        resp.user.updateProfile({
          displayName: newUser.name
        });
        return firestore.collection('users').doc(resp.user.uid).set({
          Name: newUser.name,
          comercialName: newUser.comercialName,
          initials: newUser.name[0] + newUser.comercialName[0],
          email: newUser.email,
          directorName: newUser.directorName,
          admisionesPhone: newUser.admisionesPhone,
          street: newUser.street,
          colonia: newUser.colonia,
          numExt: newUser.noExt,
          numInt: newUser.noInt,
          state: newUser.state,
          city: newUser.city,
          zip: newUser.zip,
          CCT: newUser.CCT,
          lema: newUser.lema,
          url: newUser.url,
          package: newUser.paquete,
          levels: newUser.niveles,
          phone: newUser.phone,
          whatsapp: newUser.whatsapp,
          email: newUser.email,
          uploadImages: newUser.uploadImages
        });
      }).then(() => {
        dispatch({ type: 'SIGNUP_SUCCESS' });
      }).catch((err) => {
        console.log(err);
        error.push({message: err.message})
        dispatch({ type: 'SIGNUP_ERROR', err });
      });
    }
  }
}

export const upload = (newUser) => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();
    const storage = firebase.storage();
    const {logo} = newUser;
    const uploadTask = storage.ref(`images/${logo.name}`).put(logo);
          uploadTask.on('state_changed',
          (snapshot) => {
            // progrss function ....

            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            //newUser({progress});
          },
          (error) => {
               // error function ....
            console.log(error);
          },
        () => {
            // complete function ....
            storage.ref('images').child(logo.name).getDownloadURL().then(url => {
                console.log(url);
                //newUser({url});
            })
            dispatch({ type: 'UPLOAD_SUCCESS' })
        });
  }
}
export const uploadFile = (newFile) => {
    return (dispatch, getState, {getFirebase}) => {
      const firebase = getFirebase();
      const authorId = getState().firebase.auth.uid;
      const storage = firebase.storage();
      const {escudo} = newFile;
      const uploadTask = storage.ref(`images/${authorId}/${escudo.name}`).put(escudo);
            uploadTask.on('state_changed',
            (snapshot) => {
              // progrss function ....

              const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              //newUser({progress});
            },
            (error) => {
                 // error function ....
              console.log(error);
            },
          () => {
              // complete function ....
              storage.ref(`images/${authorId}`).child(escudo.name).getDownloadURL().then(url => {
                  console.log(url);
                  //newUser({url});
              })
              dispatch({ type: 'UPLOAD_SUCCESS' })
          });


    }
}
