import React, { Component } from "react";
import Infraestructura from './Infraestructura';
import i18n from 'i18next'
import {
  MDBRow,
  MDBCol,
  MDBStepper,
  MDBStep,
  MDBBtn,
  MDBRangeInput,
  MDBIcon,
  MDBInput} from "mdbreact";

class Nivel extends Component {
    constructor(props) {
      super(props);
    }
    state = {
      formActivePanel1: 1,
      formActivePanel1Changed: false,
      idiomas: [
          {id: 1, value: "Francés", isChecked: false},
          {id: 2, value: "Italiano", isChecked: false},
          {id: 3, value: "Alemán", isChecked: false},
          {id: 4, value: "Japonés", isChecked: false},
          {id: 5, value: "Mandarín", isChecked: false},
          {id: 6, value: "Portugués", isChecked: false},
          {id: 7, value: "Coreano", isChecked: false},
          {id: 8, value: "Ruso", isChecked: false}
      ],
    }
    swapFormActive = (a) => (param) => (e) => {
      this.setState({
        ['formActivePanel' + a]: param,
        ['formActivePanel' + a + 'Changed']: true
      });
    }
    handleNextPrevClick = (a) => (param) => (e) => {
      this.setState({
        ['formActivePanel' + a]: param,
        ['formActivePanel' + a + 'Changed']: true
      });
    }
    calculateAutofocus = (a) => {
      if (this.state['formActivePanel' + a + 'Changed']) {
        return true
      }
    }
    render() {
      const { nivel } = this.props;
      console.log(nivel);
      return (
  <MDBRow>
        <h2 className="text-center font-weight-bold pt-4 pb-5 mb-2"><strong>Datos de Nivel</strong></h2>
        <MDBStepper icon>
          <MDBStep far icon="star text-red" stepName="Encuesta y Monto" onClick={this.swapFormActive(1)(1)}></MDBStep>
          <MDBStep icon="shield" stepName="Metodología" onClick={this.swapFormActive(1)(2)}></MDBStep>
          <MDBStep icon="comment" stepName="Idiomas" onClick={this.swapFormActive(1)(3)}></MDBStep>
          <MDBStep icon="map-signs" stepName="Infraestructura" onClick={this.swapFormActive(1)(4)}></MDBStep>
          <MDBStep icon="certificate" stepName="Extracurriculares" onClick={this.swapFormActive(1)(5)} className="active">
            <p className="mt-2">
              <MDBIcon icon="check" className="green-text pr-2" />
            </p>
          </MDBStep>
          <MDBStep icon="handshake" stepName="Servicios" onClick={this.swapFormActive(1)(6)}></MDBStep>
        </MDBStepper>
        <MDBRow>
            {this.state.formActivePanel1 == 1 &&
            (<MDBCol md="12">
                <MDBRow>
                  <p className="h6 text-center mb-12">{i18n.t('resultadoEncuesta')}</p>
                </MDBRow>
                <MDBInput
                  value={this.props[`${nivel}_encuestaSatisfaccion`]}
                  onChange={this.props.handleChange}
                  type="text"
                  id={`${nivel}_encuestaSatisfaccion`}
                  name={`${nivel}_encuestaSatisfaccion`}
                  label={i18n.t('encuestaSatisfaccion')}
                  required
                  className="mt-4"
                  autoFocus={this.calculateAutofocus(1)}
                >
                </MDBInput>
                <MDBRow>
                  <p className="h6 text-center mb-12">{i18n.t('iam')}</p>
                </MDBRow>

                <MDBInput
                  value={this.props[`${nivel}_montoAnualizado`]}
                  onChange={this.props.handleChange}
                  type="text"
                  id={`${nivel}_montoAnualizado`}
                  name={`${nivel}_montoAnualizado`}
                  label={i18n.t('iam')}
                  required
                >
                </MDBInput>

              <MDBBtn color="mdb-color" rounded className="float-right" onClick={this.handleNextPrevClick(1)(2)}>Siguiente</MDBBtn>
            </MDBCol>)}

            {this.state.formActivePanel1 == 2 &&
            (<MDBCol md="12">
            <MDBRow>
              <p className="h6 text-center mb-12">{i18n.t('formacion')}</p>
            </MDBRow>
              <MDBInput gap onClick={this.props.onClickMet(false,nivel)}
              checked={this.props[`${nivel}_metodologia_laica`]===false ? true : false}
              label={i18n.t('religiosa')} type="radio"
                id={`${nivel}_metodologia_religiosa`} />
              <MDBInput gap onClick={this.props.onClickMet(true,nivel)}
                checked={this.props[`${nivel}_metodologia_laica`]===true ? true : false}
                label={i18n.t('laica')} type="radio"
                id={`${nivel}_metodologia_laica`} />
              <MDBRow>
                <p className="h6 text-center mb-12">{i18n.t('redEscuelas')}</p>
              </MDBRow>
              <MDBInput label={i18n.t('redEscuelas')} filled
                type="checkbox"
                id={`${nivel}_redEscuelas`}
                name={`${nivel}_redEscuelas`}
                value={this.props[`${nivel}_redEscuelas`]}
                checked={this.props[`${nivel}_redEscuelas`]}
                onChange={this.props.handleChangeCheckbox} />
                <MDBRow>
                  <p className="h6 text-center mb-12">{i18n.t('Modelo')}</p>
                </MDBRow>
                <label htmlFor={`${nivel}_constructivista`}>{i18n.t('constructivista')}</label>
                <MDBRangeInput
                id={`${nivel}_constructivista`}
                min="1" max="5"
                value={this.props[`${nivel}_constructivista`]}
                getValue={(e) => {this.props.handleChangeMethods({
                  target: {
                    id: `${nivel}_constructivista`,
                    value: e
                  }
                })}} />
                <label htmlFor={`${nivel}_conductista`}>{i18n.t('conductista')}</label>
                <MDBRangeInput
                id={`${nivel}_conductista`}
                min="1" max="5"
                value={this.props[`${nivel}_conductista`]}
                getValue={(e) => {this.props.handleChangeMethods({
                  target: {
                    id: `${nivel}_conductista`,
                    value: e
                  }
                })}} />
                <label htmlFor={`${nivel}_metacademico`}>{i18n.t('metacademico')}</label>
                <MDBRangeInput
                id={`${nivel}_metacademico`} min="1" max="5"
                value={this.props[`${nivel}_metacademico`]}
                getValue={(e) => {this.props.handleChangeMethods({
                  target: {
                    id: `${nivel}_metacademico`,
                    value: e
                  }
                })}} />
                <label htmlFor={`${nivel}_humanista`}>{i18n.t('humanista')}</label>
                <MDBRangeInput
                id={`${nivel}_humanista`} min="1" max="5"
                value={this.props[`${nivel}_humanista`]}
                getValue={(e) => {this.props.handleChangeMethods({
                  target: {
                    id: `${nivel}_humanista`,
                    value: e
                  }
                })}} />
                <label htmlFor={`${nivel}_tics`}>{i18n.t('tics')}</label>
                <MDBRangeInput
                id={`${nivel}_tics`}
                min="1" max="5"
                value={this.props[`${nivel}_tics`]}
                getValue={(e) => {this.props.handleChangeMethods({
                  target: {
                    id: `${nivel}_tics`,
                    value: e
                  }
                })}} />
                <label htmlFor={`${nivel}_tradicional`}>{i18n.t('tradicional')}</label>
                <MDBRangeInput
                id={`${nivel}_tradicional`} min="1" max="5"
                value={this.props[`${nivel}_tradicional`]}
                getValue={(e) => {this.props.handleChangeMethods({
                  target: {
                    id: `${nivel}_tradicional`,
                    value: e
                  }
                })}} />
                <label htmlFor={`${nivel}_porproyectos`}>{i18n.t('porproyectos')}</label>
                <MDBRangeInput
                id={`${nivel}_porproyectos`} min="1" max="5"
                value={this.props[`${nivel}_porproyectos`]}
                getValue={(e) => {this.props.handleChangeMethods({
                  target: {
                    id: `${nivel}_porproyectos`,
                    value: e
                  }
                })}} />
                <label htmlFor={`${nivel}_flipped`}>{i18n.t('flipped')}</label>
                <MDBRangeInput
                id={`${nivel}_flipped`} min="1" max="5"
                value={this.props[`${nivel}_flipped`]}
                getValue={(e) => {this.props.handleChangeMethods({
                  target: {
                    id: `${nivel}_flipped`,
                    value: e
                  }
                })}} />
                <label htmlFor={`${nivel}_designthinking`}>{i18n.t('designthinking')}</label>
                <MDBRangeInput
                id={`${nivel}_designthinking`} min="1" max="5"
                value={this.props[`${nivel}_designthinking`]}
                getValue={(e) => {this.props.handleChangeMethods({
                  target: {
                    id: `${nivel}_designthinking`,
                    value: e
                  }
                })}} />

                <label htmlFor={`${nivel}_ibo`}>{i18n.t('ibo')}</label>
                <MDBRangeInput
                id={`${nivel}_ibo`}
                min="1" max="5"
                value={this.props[`${nivel}_ibo`]}
                getValue={(e) => {this.props.handleChangeMethods({
                  target: {
                    id: `${nivel}_ibo`,
                    value: e
                  }
                })}} />

                <MDBRow>
                  <p className="h6 text-center mb-12">{i18n.t('elementosMet')}</p>
                </MDBRow>

                <MDBInput label={i18n.t('tareas')} filled
                  type="checkbox"
                  id={`${nivel}_element_clase_tareas`}
                  name={`${nivel}_element_clase_tareas`}
                  checked={this.props[`${nivel}_element_clase_tareas`]}
                  onChange={(e) => {
                    this.props.handleChange({
                      target: {
                        id: e.target.name,
                        name: e.target.name,
                        value: e.target.checked,
                      }
                    });
                  }} />
                <MDBInput label={i18n.t('valores')} filled
                    type="checkbox"
                    id={`${nivel}_element_clase_valores`}
                    name={`${nivel}_element_clase_valores`}
                    checked={this.props[`${nivel}_element_clase_valores`]}
                    onChange={(e) => {
                      this.props.handleChange({
                        target: {
                          id: e.target.name,
                          name: e.target.name,
                          value: e.target.checked,
                        }
                      });
                    }} />
                <MDBInput label={i18n.t('religion')} filled
                    type="checkbox"
                    id={`${nivel}_element_clase_religion`}
                    name={`${nivel}_element_clase_religion`}
                    checked={this.props[`${nivel}_element_clase_religion`]}
                    onChange={(e) => {
                      this.props.handleChange({
                        target: {
                          id: e.target.name,
                          name: e.target.name,
                          value: e.target.checked,
                        }
                      });
                    }} />
                <MDBInput label={i18n.t('clasecomputacion')} filled
                    type="checkbox"
                    id={`${nivel}_element_clase_computacion`}
                    name={`${nivel}_element_clase_computacion`}
                    checked={this.props[`${nivel}_element_clase_computacion`]}
                    onChange={(e) => {
                      this.props.handleChange({
                        target: {
                          id: e.target.name,
                          name: e.target.name,
                          value: e.target.checked,
                        }
                      });
                    }} />
                <MDBInput label={i18n.t('arte')} filled
                    type="checkbox"
                    id={`${nivel}_element_clase_arte`}
                    name={`${nivel}_element_clase_arte`}
                    checked={this.props[`${nivel}_element_clase_arte`]}
                    onChange={(e) => {
                      this.props.handleChange({
                        target: {
                          id: e.target.name,
                          name: e.target.name,
                          value: e.target.checked,
                        }
                      });
                    }} />
                <MDBInput label={i18n.t('sustentabilidad')} filled
                    type="checkbox"
                    id={`${nivel}_element_clase_sustentabilidad`}
                    name={`${nivel}_element_clase_sustentabilidad`}
                    checked={this.props[`${nivel}_element_clase_sustentabilidad`]}
                    onChange={(e) => {
                      this.props.handleChange({
                        target: {
                          id: e.target.name,
                          name: e.target.name,
                          value: e.target.checked,
                        }
                      });
                    }} />
                <MDBInput label={i18n.t('intelig_emocional')} filled
                    type="checkbox"
                    id={`${nivel}_element_clase_intelig_emocional`}
                    name={`${nivel}_element_clase_intelig_emocional`}
                    checked={this.props[`${nivel}_element_clase_intelig_emocional`]}
                    onChange={(e) => {
                      this.props.handleChange({
                        target: {
                          id: e.target.name,
                          name: e.target.name,
                          value: e.target.checked,
                        }
                      });
                    }} />
                <MDBInput label={i18n.t('otros')} filled
                    type="text"
                    id={`${nivel}_element_clase_otros`}
                    name={`${nivel}_element_clase_otros`}
                    checked={this.props[`${nivel}_element_clase_otros`]}
                    onChange={(e) => {
                      this.props.handleChange({
                        target: {
                          id: e.target.name,
                          name: e.target.name,
                          value: e.target.checked,
                        }
                      });
                    }} />
          </MDBCol>)}
          {this.state.formActivePanel1 == 3 &&
          (<MDBCol md="12">
          <MDBRow>
              <p className="h6 text-center mb-12">{i18n.t('Idiomas')}</p>
          </MDBRow>
          <div>
            <label className="mdb-main-label">{i18n.t('numHorasIngles')} </label>
            <select id={`${nivel}_idioma_horas`}
            onChange={this.props.handleChange}
            className="browser-default custom-select"
            value={this.props[`${nivel}_idioma_horas`]}>
              <option value="">{i18n.t('numHoras')}</option>
              <option value="1">0</option>
              <option value="2">1-5</option>
              <option value="3">6-10</option>
              <option value="4">11-15</option>
              <option value="5">16-20</option>
              <option value="6">&gt;20</option>
            </select>
          </div>
          <div>
            <label className="mdb-main-label">{i18n.t('modeloIdioma')}</label>
            <select id={`${nivel}_idioma_modelo`}
            onChange={this.props.handleChange}
            className="browser-default custom-select"
            value={this.props[`${nivel}_idioma_modelo`]}>
              <option value="">{i18n.t('Modelo')}</option>
              <option value="Bilingue">{i18n.t('bilingue')}</option>
              <option value="Bicultural">{i18n.t('bicultural')}</option>
            </select>
          </div>
          <div>
            <label className="mdb-main-label">{i18n.t('perfil')}</label>
            <select id={`${nivel}_idioma_mcer`}
            onChange={this.props.handleChange}
            className="browser-default custom-select"
            value={this.props[`${nivel}_idioma_mcer`]}>
              <option value="">Nivel</option>
              <option value="1">A1</option>
              <option value="2">A2</option>
              <option value="3">B1</option>
              <option value="4">B2</option>
              <option value="5">C1</option>
              <option value="6">C2</option>
            </select>
          </div>
          <MDBRow>
            <p className="h6 text-center mb-12">{i18n.t('idiomasExtra')}</p>
          </MDBRow>
          <div>
              {
                this.props[`${nivel}_idiomas`].map((idioma) => {
                  return (
                    <MDBInput
                      containerClass="form-check checkbox-warning-filled"
                      label={idioma.value} filled
                      type="checkbox"
                      key={`${nivel}_idioma_`+idioma.id}
                      id={`${nivel}_idioma_`+idioma.id}
                      name={`${nivel}_idioma_`+idioma.id}
                      onChange={(e) => {this.props.handleCheckChildElement({target: {
                        name: e.target.name,
                        value: e.target.checked,
                      }})}}
                      checked={idioma.isChecked}
                    />
                  )
                })
              }
          </div>
          <MDBInput label={i18n.t('otros')} filled
              type="text"
              id={`${nivel}_idioma_otros`}
              name={`${nivel}_idioma_otros`}
              value={this.props[`${nivel}_idioma_otros`]}
              onChange={(e) => {
                this.props.handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  }
                });
              }} />
          </MDBCol>)}
          {this.state.formActivePanel1 == 4 &&
          (<MDBCol md="12">
          <MDBRow>
              <p className="h6 text-center mb-12">{i18n.t('Infraestructura')}</p>
          </MDBRow>
          <MDBInput label={i18n.t('numTotalAlumnos')} filled
              type="text"
              id={`${nivel}_total_alumnos_capacidad`}
              name={`${nivel}_total_alumnos_capacidad`}
              value={this.props[`${nivel}_total_alumnos_capacidad`]}
              onChange={this.props.handleChange} />
          <MDBInput label={i18n.t('numActualAlumnos')} filled
              type="text"
              id={`${nivel}_total_alumnos_actual`}
              name={`${nivel}_total_alumnos_actual`}
              value={this.props[`${nivel}_total_alumnos_actual`]}
              onChange={this.props.handleChange} />
          <MDBInput label={i18n.t('numAlumnosAula')} filled
              type="text"
              id={`${nivel}_capacidad_alumnos_por_aula`}
              name={`${nivel}_capacidad_alumnos_por_aula`}
              value={this.props[`${nivel}_capacidad_alumnos_por_aula`]}
              onChange={this.props.handleChange} />
          <MDBInput label={i18n.t('promAlumnoporAula')} filled
              type="text"
              id={`${nivel}_promedio_alumnos_por_aula`}
              name={`${nivel}_promedio_alumnos_por_aula`}
              value={this.props[`${nivel}_promedio_alumnos_por_aula`]}
              onChange={this.props.handleChange} />
              {
                this.props.infraestructura.map((canchas, idx) => {
                  return (<Infraestructura decrease={this.props.decrease}
                            increase={this.props.increase}
                            handleInfraChange={this.props.handleInfraChange}
                            nivel={this.props.nivel} {...canchas} />)
                })
              }
              </MDBCol>)}
          {this.state.formActivePanel1 == 5 &&
              (<MDBCol md="12">
              <MDBRow>
                  <p className="h6 text-center mb-12">{i18n.t('Extracurriculares')}</p>
              </MDBRow>
              {
                this.props.extracur.map((extras, idx) => {

                  return (
                    <MDBRow>
                      <MDBCol>
                          <MDBInput
                            label={extras.name} filled
                            type="checkbox"
                            id={nivel +"_"+extras.id}
                            name={nivel + "_"+extras.id}
                            checked={this.props[`${nivel}_${extras.id}`]}
                            onChange={this.props.handleChangeCheckbox}
                          />
                          </MDBCol>
                          <MDBCol>
                          <MDBInput
                              label={i18n.t('Descripcion')}
                              type="text"
                              id={nivel +"_"+extras.id+"_desc"}
                              name={nivel +"_"+extras.id+"_desc"}
                              value={this.props[`${nivel}_${extras.id}_desc`]}
                              onChange={this.props.handleChange}
                          />
                          </MDBCol>
                    </MDBRow>
                  )
                })
              }

          </MDBCol>)}
          {this.state.formActivePanel1 == 6 &&
              (<MDBCol md="12">
                <MDBRow>
                  <p className="h6 text-center mb-12">{i18n.t('Servicios')}</p>
                </MDBRow>
                {
                  this.props.servicios.map((servicio, idx) => {

                  return (
                    <MDBRow>
                      <MDBCol>
                          <MDBInput
                            label={servicio.name} filled
                            type="checkbox"
                            id={nivel +"_"+servicio.id}
                            name={nivel +"_"+servicio.id}
                            checked={this.props[`${nivel}_${servicio.id}`]}
                            onChange={this.props.handleChangeCheckbox}
                          />
                      </MDBCol>
                      <MDBCol>
                          <MDBInput
                              label={i18n.t('Descripcion')}
                              type="text"
                              id={nivel +"_"+servicio.id+"_desc"}
                              name={nivel +"_"+servicio.id+"_desc"}
                              value={this.props[`${nivel}_${servicio.id}_desc`]}
                              onChange={this.props.handleChange}
                          />
                      </MDBCol>
                    </MDBRow>
                  )
                })
              }
              </MDBCol>)}
        </MDBRow>
</MDBRow>
)
}
}

export default Nivel;
