const initState = {
    escuelas: [],
    total: 0,
    fetching: false,
    saving: false,
    error: null,
  }
const schoolReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LIST_SCHOOL_SUCCESS':
      console.log('list schools success');
      console.log( action.escuelas);
      return {
        ...state,
        escuelas: action.escuelas,
        total: action.total,
        featching: true
      };
    case 'LIST_SCHOOL_ERROR':
      console.log('list schools error');
      return {
        ...state,
        error: action.escuelas,
      };
    default:
      return state;
  }
};

export default schoolReducer;
