export default {
  translations: {
    // Name of the pages
    escuelas: 'Escolas',
    'Encuentra tu escuela': 'Encontre sua escola',
    Yes: 'Sim',
    No: 'Não',
    Name: 'Nome',
    Tags: 'Tags',
    Status: 'Status',
    Date: 'Date',
    Hour: 'Hour',
    Message: 'Message',
    Previous: 'Previous',
    Next: 'Next',
    Page: 'Page',
    of: 'of',
    Field: 'Field',
    Value: 'Value',
    Login: 'Login',
    Logout: 'Logout',
    Maintenance: 'Maintenance',
    Active: 'Active',
    Time: 'Time',
    Lost: 'Lost',
    lost: '$t(Lost)',
    Username: 'Username',
    Role: 'Role',
    Options: 'Options',
    Modify: 'Modify',
    Cancel: 'Cancel',
    Apply: 'Apply',
    Save: 'Save',
    Create: 'Create',
    Ok: 'Ok',
    Frances: "French",
    Coreano: "Corean",
    "basquet": "Basquete",
    "volei": "Voleibol",
    "futbol": "Futebol Americano",
    "soccer": "Futebol",
    "tenis": "Tenis",
    "alberca": "Piscina",
    "arenero": "Caixa de areia",
    "beisbol": "Beisebol",
    "areajuegos": "Área de Jogos",
    "otros": "Outros",
    "quimica": "Química",
    "cocina": "Cozinha",
    "electricidad": "Eletricidade",
    "dibujo": "Desenho",
    "musica": "Música",
    "computacion": "Computação",
    "huertos": "Pomares",
    "biblioteca": "Biblioteca",
    "otras": "Outras",
    "oficina": "Escritórios",
    "comedor": "Comedor",
    "banios": "Baños",
    "vestidores": "Vestidores",
    "abiente_digital": "Ambiente digital",
    "auditorio": "Auditorio",
    "bodega": "Bodega",
    "jardines": "Jardines",
    "cajones": "Cajones",
    "basketbol": "Básquetbol",
    "atletismo": "Atletismo",
    "porristas": "Porristas",
    "natacion": "Natación",
    "tae_kwon_do": "Tae Kwon Do",
    "ajedrez": "Ajedrez",
    "oratoria": "Oratoria",
    "debate": "Debate",
    "pintura": "Pintura",
    "danza": "Danza",
    "teatro": "Teatro",
    "banda": "Banda",
    "matematicas": "Matemáticas",
    "entomologia": "Entomología",
    "botanica": "Botánica",
    "fisica": "Física",
    "literatura": "Literatura",
    "huerto": "Huerto",
    "robotica": "Robótica",
    "programacion": "Programación",
    "edicion": "Edición de foto o Video",
    "radio": "Rádio",
    "lms": "LMS",
    "apps": "App’s",
    "cert_ingles": "Certificación docente Inglés",
    "cert_metodo": "Certificación docente Metodológica",
    "cert_iso": "Certificación docente ISO",
    "plataforma": "Plataforma de pagos",
    "domiciliado": "Acepta Pago Domiciliado",
    "tdtc": "Acepta Pago TD/TC",
    "anual": "Acepta Pago Anual",
    "cheque": "Acepta Pago Cheque",
    "descuento": "Acepta Pago Descuento de nómina",
    "web": "Página Web",
    "facebook": "Facebook",
    "twiter": "Twiter",
    "whatsapp": "Whatsapp",
    "youtube": "Youtube",
    "webinarios": "Webinarios",
    "enfermera": "Enfermera",
    "psicologo": "Psicólogo",
    "guardia": "Guardia",
    "cctv": "CCTV",
    "primerrespondiente": "Certificación Primer Respondiente",
    "ambulancia": "Ambulancia",
    "transporte": "Transporte",
    "alimentacion": "Programa de alimentación",
    "menu": "Menú Nutritivo",
    "cafeteria": "Cafetería de padres",
  },
};
