import authReducer from './authReducer'
import projectReducer from './projectReducer'
import schoolReducer from './schoolReducer'
import orderReducer from './ordenReducer'
import getGoal  from './createGoal'
import getData from './paypalReducer'
import loadingReducer from './loadingReducer'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'
import { reducer as formReducer } from 'redux-form'

const rootReducer = combineReducers({
  auth: authReducer,
  ordencompra: projectReducer,
  orders: orderReducer,
  loading: loadingReducer,
  elasticsearch: schoolReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
	getGoal: getGoal,
  getData: getData,
  form: formReducer
});

export default rootReducer

// the key name will be the data property on the state object
