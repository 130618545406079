import aws4 from 'aws4'
import elasticsearch from 'elasticsearch';

const client = new elasticsearch.Client({
  //cloud: { id: 'eduk2:dXMtY2VudHJhbDEuZ2NwLmNsb3VkLmVzLmlvJDlmNzFiMGFjNmM0ZDRhYTBhMTY1NDI3MTNjMDE3YzFiJGQzMmExZmYzMmNhYzRmMmQ4NWZlNzgxYjljNTJkZGM0', },
  //auth: {  username: 'elastic', password: 'o4pRFFj3j36XjcF3bzJQM07c'},
  //ssl: {secureProtocol: 'TLSv1_2_method',},
    //maxRetries: 5,
    //requestTimeout: 60000,
    //sniffOnStart: false,
    //host: 'http://10.204.243.160:9200'
    //host: 'https://3slfiesotk:hs7hpzxkb2@eduk2-4709248868.us-west-2.bonsaisearch.net:443'
    host: 'https://4xgg7rftje:q0vesrnzkv@eduk2-3683615528.us-east-1.bonsaisearch.net:443'
    // <a class="vglnk" href="http://root" rel="nofollow"><span>http</span><span>://</span><span>root</span></a>:12345@localhost:9200/
    // If you have set username and password

});

//const client = new elasticsearch.Client({
    //host: '<a class="vglnk" href="http://elastic" rel="nofollow"><span>http</span><span>://</span><span>elastic</span></a>:o4pRFFj3j36XjcF3bzJQM07c@9f71b0ac6c4d4aa0a16542713c017c1b.us-central1.gcp.cloud.es.io:9243'
    //host: 'https://elastic:r1hKakAnWxq0UUgKsMKyFdLf@e59eb1c9732b488d80314193d0afd09a.us-central1.gcp.cloud.es.io:9243/'
//    host: 'https://3slfiesotk:hs7hpzxkb2@eduk2-4709248868.us-west-2.bonsaisearch.net:443'
    //auth: { apiKey: 'eduk2:dXMtY2VudHJhbDEuZ2NwLmNsb3VkLmVzLmlvJDlmNzFiMGFjNmM0ZDRhYTBhMTY1NDI3MTNjMDE3YzFiJGQzMmExZmYzMmNhYzRmMmQ4NWZlNzgxYjljNTJkZGM0'}
    // <a class="vglnk" href="http://localhost:9200/" rel="nofollow"><span>http</span><span>://</span><span>localhost</span><span>:</span><span>9200</span><span>/</span></a>
    // <a class="vglnk" href="http://root" rel="nofollow"><span>http</span><span>://</span><span>root</span></a>:12345@localhost:9200/
    // If you have set username and password
//});

//const credentials = {
//  accessKeyId: "AKIA5HCNKTSFFHCNQ2TM",
//  secretAccessKey: "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",
//  sessionToken: ""
//};
//const client = new ElasticsearchServiceClient({ credentials: credentials, region: "us-east-2" });
//console.log(client);

//const endpoint = new Endpoint('https://search-eduk2-5n3hdjvuazesrqzv7b33aiag3e.us-east-2.es.amazonaws.com');


// Check if Connection is ok or not
 client.ping({
     // ping usually has a 3000ms timeout
     requestTimeout: Infinity,
 }, function (error) {
     if (error) {
         console.trace('elasticsearch cluster is down!');
     } else {
         console.log('All is well');
     }
 });
// export const listSchools = (query, from) => {
//   return async (dispatch, getState) => {
//     try {
      //const connection = client.CreateOutboundCrossClusterSearchConnection({ ConnectionAlias: 'eduk2'});
      // process data.
      //const params = {
      //  ConnectionAlias: 'eduk2',
      //  SourceDomainInfo: {DomainName: 'client', OwnerId: 'eduk2elastic', Region: 'us-east-2'},
      //  DestinationDomainInfo: {
      //    DomainName: 'eduk2',
      //    OwnerId: '908547759242',
      //    Region: 'us-east-2'
      //  }
      //};
      //const command = new CreateOutboundCrossClusterSearchConnectionCommand(params);
      //const connection = await client.send(command);
      //const connectionid = connection.CrossClusterSearchConnectionId;
      //console.log(connectionid);
      // let request = new HTTPRequest(endpoint, 'us-east-2');
      //
      // request.method = 'POST';
      // request.path += 'eduk2/_search';
      // request.body = JSON.stringify(query);
      // request.headers['host'] = 'https://search-eduk2-5n3hdjvuazesrqzv7b33aiag3e.us-east-2.es.amazonaws.com';
      // request.headers['Content-Type'] = 'application/json';
      // Content-Length is only needed for DELETE requests that include a request
      // body, but including it for all requests doesn't seem to hurt anything.
      // request.headers['Content-Length'] = Buffer.byteLength(request.body);
      //
      // var signer = new Signers.V4(request, 'es');
      // signer.addAuthorization(credentials, new Date());
      //
      // var client = new HttpHandler();
      // client.handleRequest(request, null, function(response) {
      //   console.log(response.statusCode + ' ' + response.statusMessage);
      //   var responseBody = '';
      //   response.on('data', function (chunk) {
      //     responseBody += chunk;
      //   });
      //   response.on('end', function (chunk) {
      //     console.log('Response body: ' + responseBody);
      //     const data = responseBody;
      //     dispatch({ type: 'LIST_SCHOOL_SUCCESS' , escuelas: data.hits, total: data.hits.total});
      //   });
      // }, function(error) {
      //   console.log('Error: ' + error);
      // });
      // const query = {
      //     CrossClusterSearchConnectionId: 'connectionid',
      //     index: 'educa2',
      //     type : "doc",
      //     body: query
      //   };
      // const commandData = new AcceptInboundCrossClusterSearchConnectionCommand(query);
      // const data = await client.send(commandData);
      //console.log("create",data);
      //dispatch({ type: 'LIST_SCHOOL_SUCCESS' , escuelas: data.hits, total: data.hits.total});
//     } catch (error) {
//       // error handling.
//       console.log("Error: " + error );
//       dispatch({ type: 'LIST_SCHOOL_ERROR', escuelas: []}, error);
//     } finally {
//       // finally.
//     }
//   }
// };
export const listSchools = (query, from) => {
  console.log(query)
  return (dispatch, getState) => {
    client.search({
      index: 'educa2',
      type : "_doc",
      body: query
    },function(err,resp,status) {
    if(err) {
      console.log("table already exists.");
      console.log("Error: " + err );
      dispatch({ type: 'LIST_SCHOOL_ERROR', escuelas: []}, err);
    }
    else {
      console.log("create",resp);
      dispatch({ type: 'LIST_SCHOOL_SUCCESS' , escuelas: resp.hits, total: resp.hits.total});
    }
    });
  }
};
