import React, { Component } from "react";
import apm from '../../rum'
//import styled from 'styled-components';
import MyFancyComponent from './Map';
//import i18n from 'i18next';
import { MDBContainer,
  MDBCol,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBRangeInput,
  MDBPopover,
  MDBPopoverHeader,
  MDBPopoverBody,
  MDBCollapse,
  MDBCollapseHeader,
  MDBCard, MDBCardBody,
  MDBIcon } from "mdbreact";
import Histoslider from "histoslider";
import MediaQuery from 'react-responsive'


class Filters extends Component {
constructor(props){
  super(props);
  console.log(props);
  this.state = {
    filters:  props.setFilters,
    edad: props.edad,
    city: props.city,
    data: props.data,
    selectedFormacion: '',
    selectedRed: ''
  }
  this.radioChange = this.radioChange.bind(this);
  this.setData = this.setData.bind(this);
  this.handleChangeMap = this.handleChangeMap.bind(this);
  this.handleChangeName = this.handleChangeName.bind(this);
}

state={
  collapseFilter: "collapseFilter",
  collapseID: "collapse1",
  filters: null,
  selectedFormacion: '',
  selectedRed: '',
  edad: '',
  name: '',
  city: '',
  data:[]
}

setData(data){
  this.setState({
    data
  });
}
radioChange(e) {
    var transaction;
    console.log(e.currentTarget.value)
    if (e.currentTarget.value === 'Laica') {
      transaction = apm.startTransaction("Laica", "filter");
      this.setState({
        selectedFormacion: e.currentTarget.value
      });
      this.state.filters({metodologia_laica: "True"})
      transaction.end()
    } else if (e.currentTarget.value === 'Religiosa') {
      transaction = apm.startTransaction("Religiosa", "filter");
      this.setState({
        selectedFormacion: e.currentTarget.value
      });
      this.state.filters({metodologia_laica: "False"})
      transaction.end()
    } else if (e.currentTarget.value === 'No') {
      transaction = apm.startTransaction("SinRed", "filter");
      this.setState({
        selectedRed: e.currentTarget.value
      });
      this.state.filters({redEscuelas: "False"})
      transaction.end()
    } else if (e.currentTarget.value === 'Si') {
      transaction = apm.startTransaction("ConRed", "filter");
      this.setState({
        selectedRed: e.currentTarget.value
      });
      this.state.filters({redEscuelas: "True"})
      transaction.end()
    } else {

    }
}
handleChange = (e) => {
  this.setState({
    [e.target.id]: e.target.value
  })
}

handleChangeName = value => {
    var transaction = apm.startTransaction("nombre", "filter");
    console.log("handleChangeName");
    console.log(value);
    this.state.filters({name: value});
    transaction.end()
}
handleChangeInversion = (value) => {
  var transaction = apm.startTransaction("inversion", "filter");
  console.log("handleChangeInversion");
  console.log(value);
  this.state.filters({inversion: value});
  transaction.end()
}
handleChangeOpinion = (value) => {
  var transaction = apm.startTransaction("opinion", "filter");
  console.log("handleChangeOpinion");
  console.log(value);
  this.state.filters({opinion: value});
  transaction.end()
}
handleChangeCheckbox = (evt) => {
  var transaction = apm.startTransaction(evt.target.id, "filter");
  console.log("handleChangeCheckbox");
  console.log(evt.target.id);
  console.log(evt.target.checked);
  this.state.filters({[evt.target.id]: evt.target.checked})
  transaction.end()
}
handleChangeMap = (evt) => {
  var transaction = apm.startTransaction("MapCircle", "filter");
  //console.log("handleChangeMap");
  //console.log(evt);
  this.state.filters({circle: {center: {lat: evt.overlay.center.lat(), lng: evt.overlay.center.lng()}, radius: evt.overlay.radius}});
  transaction.end()
}
handleMediaQueryChange = (matches) => {
    // matches will be true or false based on the value for the media query
    //console.log(matches)
    if (matches) {
      this.setState({collapseFilter: ''})
    } else {
      this.setState({collapseFilter: 'collapseFilter'})
    }
  }
toggleCollapseFilters = collapseid => () =>{
//console.log('toggleCollapseFilters')
this.setState(prevState => ({
  collapseFilter: prevState.collapseFilter !== collapseid ? collapseid : ""
}))
};
toggleCollapse = collapseID => () =>{
  //console.log('toggleCollapse')
this.setState(prevState => ({
  collapseID: prevState.collapseID !== collapseID ? collapseID : ""
}))};

render() {
    return (
      <div>
      <MDBContainer className="accordion md-accordion accordion-3">
        <MDBCard
          className="card-image"
          style={{
            background: "white"
          }}
        >
          <div className="rgba-white-strong py-3 px-2">
            <MDBRow className="d-flex text-center">
              <MDBCol md="12"><h5>{this.state.city} - {this.state.edad}</h5></MDBCol>
            </MDBRow>
            <MDBRow className="d-flex text-center">
              <MDBCol md="10" xl="12">
                <MDBCollapseHeader
                  tagClassName='d-flex justify-content-center'
                  onClick={this.toggleCollapseFilters("collapseFilter")}
                >
                  <MDBRow className="text-center">
                  <MDBCol md="12"><h2>Filtros</h2></MDBCol>
                  </MDBRow>
                  <MediaQuery maxWidth={1340} onChange={this.handleMediaQueryChange}>
                    <MDBIcon
                      icon={this.state.collapseFilter === 'collapseFilter' ? 'angle-up' : 'angle-down'}
                    />
                  </MediaQuery>
                </MDBCollapseHeader>
              </MDBCol>
            </MDBRow>
            <MDBCollapse id="collapseFilter" isOpen={this.state.collapseFilter || window.innerWidth > 1340}>
              <MDBRow className="d-flex justify-content-left">
                <MDBCol md="10" xl="12">
                  <MDBContainer className="accordion md-accordion accordion-3">
                  <MDBCard className="border-0">
                    <MDBCollapseHeader
                      onClick={this.toggleCollapse("collapse0")}
                      tag="h5"
                      tagClassName="text-camelcase black-text mb-0 d-flex justify-content-start align-items-center"
                    >
                    <div
                      className="d-flex justify-content-center align-items-center mr-2"
                      style={{ backgroundColor: "#fff", minWidth: "80px" }}
                    >

                      <img src="images/Nombre.svg" width="50" height="50" className="rounded float-left" alt="Nombre"/>

                    </div>
                      Nombre
                      <div
                        className="d-flex text-right black-text"
                      >
                        <MDBPopover placement="right" popover clickable id="popper0">
                          <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                            <MDBIcon
                              className="black-text"
                              size="4X"
                              icon="info-circle"
                            />
                          </MDBBtn>
                              <div>
                                <MDBPopoverHeader>Nombre</MDBPopoverHeader>
                                <MDBPopoverBody>
                                Filtro por texto contenido en el nombre de la escuela
                                </MDBPopoverBody>
                              </div>
                        </MDBPopover>
                      </div>
                    </MDBCollapseHeader>

                    <MDBCollapse id="collapse0" isOpen={this.state.collapseID}>
                      <MDBCardBody className="rgba-white black-text z-depth-1 ml-2">
                      <MDBRow className="my-4" center>
                        <MDBInput
                          id="name"
                          label="Texto a filtrar"
                          type="text"
                          value={this.state.name}
                          onChange={this.handleChange}
                        />
                      </MDBRow>
                      <MDBRow className="my-4" center>
                        <MDBBtn color="orange" onClick={() => {this.handleChangeName(this.state.name)}}>
                          Aplicar
                        </MDBBtn>
                      </MDBRow>
                      </MDBCardBody>
                    </MDBCollapse>
                  </MDBCard>
                    <MDBCard className="border-0">
                      <MDBCollapseHeader
                        onClick={this.toggleCollapse("collapse1")}
                        tag="h5"
                        tagClassName="text-camelcase black-text mb-0 d-flex justify-content-start align-items-center"
                      >
                      <div
                          className="d-flex justify-content-center align-items-center mr-2"
                          style={{ backgroundColor: "#fff", minWidth: "80px" }}
                        >
                        <img src="images/Metodologia.svg" width="50" height="50" className="rounded float-left" alt="Metodología Escolar"/>
                        </div>
                        Metodología
                        <div
                          className="d-flex justify-content-right"
                        >
                        <MDBPopover placement="right" popover clickable id="popper2">
                                <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                                  <MDBIcon
                                    className="black-text"
                                    size="4X"
                                    icon="info-circle"
                                  />
                                </MDBBtn>
                                <div>
                                  <MDBPopoverHeader>Metodología</MDBPopoverHeader>
                                  <MDBPopoverBody>
                                    Conjunto de técnicas o procedimientos específicos que se emplean habitualmente en la práctica de la enseñanza- aprendizaje
                                  </MDBPopoverBody>
                                </div>
                          </MDBPopover>
                        </div>
                      </MDBCollapseHeader>

                      <MDBCollapse id="collapse1" isOpen={this.state.collapseID}>
                        <MDBCardBody className="rgba-white black-text z-depth-1 ml-2">
                          <h4>Formación</h4>
                          <MDBInput onClick={this.radioChange}
                                 type="radio"
                                 value="Laica"
                                 id="radioLaica"
                                 label='Laica'
                                 checked={this.state.selectedFormacion === "Laica"} />

                          <MDBInput onClick={this.radioChange}
                                 type="radio"
                                 value="Religiosa"
                                 id="RadioReligiosa"
                                 label='Religiosa'
                                 checked={this.state.selectedFormacion === "Religiosa"} />
                          <h4>Red</h4>
                          <MDBInput onClick={this.radioChange}
                                 type="radio"
                                 value="Si"
                                 id="radioRedSi"
                                 label='Si'
                                 checked={this.state.selectedRed === "Si"} />

                          <MDBInput onClick={this.radioChange}
                                 type="radio"
                                 value="No"
                                 id="radioRedNo"
                                 label='No'
                                 checked={this.state.selectedRed === "No"} />

                          <h4>Modelo</h4>
                          <div
                            className="d-flex text-right row"
                          >
                          <MDBInput label="Constructivista" type="checkbox" id="methodology_constructivista" onChange={this.handleChangeCheckbox} />
                          <MDBPopover placement="right" popover clickable id="popper2.3.1">
                                  <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                                    <MDBIcon
                                      className="black-text"
                                      size="4X"
                                      icon="info-circle"
                                    />
                                  </MDBBtn>
                                  <div>
                                    <MDBPopoverHeader className="black-text">Constructivista</MDBPopoverHeader>
                                    <MDBPopoverBody>
                                    Se enfoca en la construcción del conocimiento mediante actividades
                                    basadas en experiencias ricas en contexto. Entregando al estudiante las
                                    herramientas necesarias para construir sus propios procedimientos y
                                    resolver problemas; lo que implica siga aprendiendo.
                                    </MDBPopoverBody>
                                  </div>
                            </MDBPopover>
                          </div>
                          <div
                            className="d-flex text-right row"
                          >
                          <MDBInput label="Conductista" type="checkbox" id="methodology_conductista" onChange={this.handleChangeCheckbox} />
                          <MDBPopover placement="right" popover clickable id="popper2.3.2">
                                  <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                                    <MDBIcon
                                      className="black-text"
                                      size="4X"
                                      icon="info-circle"
                                    />
                                  </MDBBtn>
                                  <div>
                                    <MDBPopoverHeader className="black-text">Conductista</MDBPopoverHeader>
                                    <MDBPopoverBody>
                                    Observa principalmente la conducta. Es un método orientado al desempeño
                                    superior el cual es selectivo a los &quot;más aptos&quot; con dificultades en la
                                    transparencia de la identificación de los estándares y requerimientos
                                    técnicos, está basado en los aspectos personales para el desempeño.
                                    </MDBPopoverBody>
                                  </div>
                            </MDBPopover>
                          </div>
                          <div
                            className="d-flex text-right row"
                          >
                          <MDBInput label="Académico" type="checkbox" id="methodology_metacademico" onChange={this.handleChangeCheckbox} />
                          <MDBPopover placement="right" popover clickable id="popper2.3.3">
                                  <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                                    <MDBIcon
                                      className="black-text"
                                      size="4X"
                                      icon="info-circle"
                                    />
                                  </MDBBtn>
                                  <div>
                                    <MDBPopoverHeader className="black-text">Académico</MDBPopoverHeader>
                                    <MDBPopoverBody>
                                    Consiste en una recopilación o síntesis de distintas teorías y enfoques
                                    pedagógicos, que orientan a los docentes en la elaboración de los
                                    programas de estudios y en la sistematización del proceso de enseñanza y aprendizaje.
                                    </MDBPopoverBody>
                                  </div>
                            </MDBPopover>
                          </div>
                          <div
                            className="d-flex text-right row"
                          >
                          <MDBInput label="Humanista" type="checkbox" id="methodology_humanista" onChange={this.handleChangeCheckbox} />
                          <MDBPopover placement="right" popover clickable id="popper2.3.4">
                                  <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                                    <MDBIcon
                                      className="black-text"
                                      size="4X"
                                      icon="info-circle"
                                    />
                                  </MDBBtn>
                                  <div>
                                    <MDBPopoverHeader className="black-text">Humanista</MDBPopoverHeader>
                                    <MDBPopoverBody>
                                    Está centrada en el alumno, mismo que posee un deseo natural por
                                    aprender, y que: “solo sirve aquello que deja huella en una persona y pasa a
                                    formar parte de su vida cognitiva, cultural, afectiva, espiritual y existencial”.
                                    </MDBPopoverBody>
                                  </div>
                            </MDBPopover>
                          </div>
                          <div
                            className="d-flex text-right row"
                          >
                          <MDBInput label="TICs" type="checkbox" id="methodology_tics" onChange={this.handleChangeCheckbox} />
                          <MDBPopover placement="right" popover clickable id="popper2.3.5">
                                  <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                                    <MDBIcon
                                      className="black-text"
                                      size="4X"
                                      icon="info-circle"
                                    />
                                  </MDBBtn>
                                  <div>
                                    <MDBPopoverHeader className="black-text">TICs</MDBPopoverHeader>
                                    <MDBPopoverBody>
                                    Las tecnologías de la comunicación, contribuyen al acceso universal a la
                                    educación, la igualdad en la instrucción, el ejercicio de la enseñanza y el
                                    aprendizaje de calidad y el desarrollo profesional de los docentes, así como
                                    a la gestión dirección y administración más eficientes del sistema educativo
                                    </MDBPopoverBody>
                                  </div>
                            </MDBPopover>
                          </div>
                          <div
                            className="d-flex text-right row"
                          >
                          <MDBInput label="Tradicional" type="checkbox" id="methodology_tradicional" onChange={this.handleChangeCheckbox} />
                          <MDBPopover placement="right" popover clickable id="popper2.3.6">
                                  <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                                    <MDBIcon
                                      className="black-text"
                                      size="4X"
                                      icon="info-circle"
                                    />
                                  </MDBBtn>
                                  <div>
                                    <MDBPopoverHeader className="black-text">Tradicional</MDBPopoverHeader>
                                    <MDBPopoverBody>
                                    Concibe la enseñanza como un verdadero arte y al profesor/a como un
                                    artesano, donde su función es explicar claramente y exponer de manera
                                    progresiva sus conocimientos, enfocándose de manera central en el
                                    aprendizaje del alumno; el estudiante es visto como una página en blanco
                                    que hay que llenar.
                                    </MDBPopoverBody>
                                  </div>
                            </MDBPopover>
                          </div>
                          <div
                            className="d-flex text-right row"
                          >
                          <MDBInput label="Por Proyectos" type="checkbox" id="methodology_porproyectos" onChange={this.handleChangeCheckbox} />
                          <MDBPopover placement="right" popover clickable id="popper2.3.7">
                                  <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                                    <MDBIcon
                                      className="black-text"
                                      size="4X"
                                      icon="info-circle"
                                    />
                                  </MDBBtn>
                                  <div>
                                    <MDBPopoverHeader className="black-text">Por Proyectos</MDBPopoverHeader>
                                    <MDBPopoverBody>
                                    Aprendizaje a través de la formulación de proyectos, en donde el docente
                                    propone un &quot;pretexto&quot; que es un elemento de interés para los interactuantes
                                    en el desarrollo de la propuesta. Incentiva la investigación en todas las
                                    líneas de conocimiento, desarrolla las potencialidades de los estudiantes, se
                                    basa en el interés de cada uno, permite la construcción colectiva de normas,
                                    como una fuente importante de convivencia ciudadana, plantea el respeto
                                    por el bien colectivo y construye el conocimiento a través de la experiencia.
                                    </MDBPopoverBody>
                                  </div>
                            </MDBPopover>
                          </div>
                          <div
                            className="d-flex text-right row"
                          >
                          <MDBInput label="Flipped Classroom" type="checkbox" id="methodology_flipped" onChange={this.handleChangeCheckbox} />
                          <MDBPopover placement="right" popover clickable id="popper2.3.8">
                                  <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                                    <MDBIcon
                                      className="black-text"
                                      size="4X"
                                      icon="info-circle"
                                    />
                                  </MDBBtn>
                                  <div>
                                    <MDBPopoverHeader className="black-text">Flipped Classroom</MDBPopoverHeader>
                                    <MDBPopoverBody>
                                    Transfiere el trabajo de determinados procesos de aprendizaje fuera del
                                    aula y utiliza el tiempo de clase, junto con la experiencia del docente, para
                                    facilitar y potenciar otros procesos de adquisición y práctica de
                                    conocimientos dentro del aula.
                                    </MDBPopoverBody>
                                  </div>
                            </MDBPopover>
                          </div>
                          <div
                            className="d-flex text-right row"
                          >
                          <MDBInput label="Design Thinking" type="checkbox" id="methodology_thinking" onChange={this.handleChangeCheckbox} />
                          <MDBPopover placement="right" popover clickable id="popper2.3.9">
                                  <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                                    <MDBIcon
                                      className="black-text"
                                      size="4X"
                                      icon="info-circle"
                                    />
                                  </MDBBtn>
                                  <div>
                                    <MDBPopoverHeader className="black-text">Design Thinking</MDBPopoverHeader>
                                    <MDBPopoverBody>
                                    Se fundamenta en la capacidad creativa y en la toma de decisiones que
                                    atienden necesidades específicas. Consta de cinco fases para su
                                    implementación: 1. Descubrimiento 2. Interpretación 3. Ideación 4. Experimentación
                                    </MDBPopoverBody>
                                  </div>
                            </MDBPopover>
                          </div>
                          <div
                            className="d-flex text-right row"
                          >
                          <MDBInput label="IBO" type="checkbox" id="methodology_ibo" onChange={this.handleChangeCheckbox} />
                          <MDBPopover placement="right" popover clickable id="popper2.3.10">
                                  <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                                    <MDBIcon
                                      className="black-text"
                                      size="4X"
                                      icon="info-circle"
                                    />
                                  </MDBBtn>
                                  <div>
                                    <MDBPopoverHeader className="black-text">IBO</MDBPopoverHeader>
                                    <MDBPopoverBody>
                                    Propone transformar a los alumnos en el transcurso de su aprendizaje
                                    mediante ciclos dinámicos de indagación, acción y reflexión.  Los profesores
                                    apoyan a los alumnos y potencian sus capacidades a medida que
                                    desarrollan los enfoques del aprendizaje necesarios.
                                    </MDBPopoverBody>
                                  </div>
                            </MDBPopover>
                          </div>
                          <h4>Co curriculares</h4>
                          <MDBInput label="Tarea" type="checkbox" id="elementosclase_tarea" onChange={this.handleChangeCheckbox} />
                          <h6>Clase de:</h6>
                          <MDBInput label="Valores" type="checkbox" id="elementosclase_valores" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Religión" type="checkbox" id="elementosclase_religion" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Computación" type="checkbox" id="elementosclase_computacion" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Arte" type="checkbox" id="elementosclase_arte" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Sustentabilidad" type="checkbox" id="elementosclase_sustentabilidad" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Inteligencia Emocional" type="checkbox" id="elementosclase_intelig" onChange={this.handleChangeCheckbox} />
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>

                    <MDBCard className="border-0">
                      <MDBCollapseHeader
                        onClick={this.toggleCollapse("collapse2")}
                        tag="h5"
                        tagClassName="text-camelcase black-text mb-0 d-flex justify-content-start align-items-center"
                      >
                      <div
                          className="d-flex justify-content-center align-items-center mr-2"
                          style={{ backgroundColor: "#fff", minWidth: "80px" }}
                        >
                            <img src="images/idiomas impartidos por la escuela.svg" width="50" height="50" className="rounded float-left" alt="idiomas impartidos por la escuela"/>
                        </div>
                        Idiomas
                        <div
                          className="d-flex text-right black-text z-depth-0"
                          style={{ display: "flex" }}
                        >
                        <MDBPopover placement="right" popover clickable id="popper3">
                                <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                                  <MDBIcon
                                    className="black-text"
                                    size="4X"
                                    icon="info-circle"
                                  />
                                </MDBBtn>
                                <div>
                                  <MDBPopoverHeader>Idiomas</MDBPopoverHeader>
                                  <MDBPopoverBody>
                                    Permite a los alumnos dotarse de más destrezas comunicativas y les abre el camino hacia la comprensión de otros modos de vida.
                                  </MDBPopoverBody>
                                </div>
                          </MDBPopover>
                        </div>
                      </MDBCollapseHeader>

                      <MDBCollapse id="collapse2" isOpen={this.state.collapseID}>
                        <MDBCardBody className="rgba-white black-text z-depth-1 ml-2">
                          <h4>Exposición a horas de inglés a la semana</h4>
                          <MDBInput label="1-5 horas" type="checkbox" id="idioma_horas_2" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="6-10 horas" type="checkbox" id="idioma_horas_3" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="11-15 horas" type="checkbox" id="idioma_horas_4" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="16-20 horas" type="checkbox" id="idioma_horas_5" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="20 + horas" type="checkbox" id="idioma_horas_6" onChange={this.handleChangeCheckbox} />
                          <h4>Ambiente de Inglés</h4>
                          <MDBRow>
                          <MDBInput className="m-1" label="Bicultural" type="checkbox" id="idioma_modelo_Bicultural" onChange={this.handleChangeCheckbox} />
                          <div
                            className="d-flex text-right black-text"
                          >
                          <MDBPopover placement="right" popover clickable id="popper3.1">
                                  <MDBBtn tag="a" className="m-1 " size="sm" floating>
                                    <MDBIcon
                                      className="gray-text"
                                      size="2X"
                                      icon="plus"
                                    />
                                  </MDBBtn>
                                  <div>
                                    <MDBPopoverHeader>Bicultural</MDBPopoverHeader>
                                    <MDBPopoverBody>
                                    Enfoque integral de educación intercultural donde se contextualiza y adquiere el conocimiento simultáneamente en los dos idiomas. “llevan clases en inglés”
                                    </MDBPopoverBody>
                                  </div>
                            </MDBPopover>
                            </div>
                          </MDBRow>
                          <MDBRow>
                          <MDBInput label="Bilingüe" type="checkbox" id="idioma_modelo_Bilingue" onChange={this.handleChangeCheckbox} />
                          <div
                            className="d-flex text-right black-text"
                          >
                          <MDBPopover placement="right" popover clickable id="popper3.2">
                                  <MDBBtn tag="a" className="m-1 " size="sm" floating>
                                    <MDBIcon
                                      className="gray-text"
                                      size="2X"
                                      icon="plus"
                                    />
                                  </MDBBtn>
                                  <div>
                                    <MDBPopoverHeader>Bilingüe</MDBPopoverHeader>
                                    <MDBPopoverBody>
                                    Desarrolla el aprendizaje de la lengua inglesa mediante la enseñanza de la estructura del idioma. “llevan clases de inglés”

                                    </MDBPopoverBody>
                                  </div>
                            </MDBPopover>
                            </div>
                          </MDBRow>

                          <h4>Otros Idiomas</h4>
                          <MDBInput label="Francés" type="checkbox" id="idiomas_Francés" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Italiano" type="checkbox" id="idiomas_Italiano" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Alemán" type="checkbox" id="idiomas_Alemán" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Japonés" type="checkbox" id="idiomas_Japonés" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Mandarin" type="checkbox" id="idiomas_Mandarin" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Portugués" type="checkbox" id="idiomas_Portugués" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Coreano" type="checkbox" id="idiomas_Coreano" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Ruso" type="checkbox" id="idiomas_Ruso" onChange={this.handleChangeCheckbox} />
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>
                    <MDBCard className="border-0">
                      <MDBCollapseHeader
                        onClick={this.toggleCollapse("collapse3")}
                        tag="h5"
                        tagClassName="text-camelcase black-text mb-0 d-flex justify-content-start align-items-center"
                      >
                      <div
                          className="d-flex justify-content-center align-items-center mr-2"
                          style={{ backgroundColor: "#fff", minWidth: "80px" }}
                        >
                        <img src="images/extracurriculares de la escuela.svg" width="50" height="50" className="rounded float-left" alt="extracurriculares" />
                        </div>
                        Extracurriculares
                        <div
                          className="d-flex text-right black-text"
                        >
                          <MDBPopover placement="right" popover clickable id="popper4">
                            <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                              <MDBIcon
                                className="black-text"
                                size="4X"
                                icon="info-circle"
                              />
                            </MDBBtn>
                                <div>
                                  <MDBPopoverHeader>Extracurriculares</MDBPopoverHeader>
                                  <MDBPopoverBody>
                                  Aquellas que son una apertura a las necesidades culturales, deportivas o artísticas de la comunidad, abriendo la escuela al mundo y que generan oportunidades de expresar las habilidades de los alumnos.
                                  </MDBPopoverBody>
                                </div>
                          </MDBPopover>
                        </div>
                      </MDBCollapseHeader>

                      <MDBCollapse id="collapse3" isOpen={this.state.collapseID}>
                        <MDBCardBody className="rgba-white black-text z-depth-1 ml-2">
                          <h4>Deportivas</h4>
                          <MDBInput label="Basketbol" type="checkbox" id="deportivas_basketbol" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Voleibol" type="checkbox" id="deportivas_volei" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Fútbol" type="checkbox" id="deportivas_futbol" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Soccer" type="checkbox" id="deportivas_soccer" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Béisbol" type="checkbox" id="deportivas_beisbol" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Atletismo" type="checkbox" id="deportivas_atletismo" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Porristas" type="checkbox" id="deportivas_porristas" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Natación" type="checkbox" id="deportivas_natacion" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Tae Kwon Do" type="checkbox" id="deportivas_taekwondo" onChange={this.handleChangeCheckbox} />
                          <h4>Culturales</h4>
                          <MDBInput label="Ajedrez" type="checkbox" id="cultural_ajedrez" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Oratoria" type="checkbox" id="cultural_oratoria" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Debate" type="checkbox" id="cultural_debate" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Pintura" type="checkbox" id="cultural_pintura" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Danza" type="checkbox" id="cultural_danza" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Teatro" type="checkbox" id="cultural_teatro" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Cocina" type="checkbox" id="cultural_cocina" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Banda" type="checkbox" id="cultural_banda" onChange={this.handleChangeCheckbox} />
                          <h4>Académicas</h4>
                          <MDBInput label="Matemáticas" type="checkbox" id="academica_matematicas" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Entomología" type="checkbox" id="academica_entomologia" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="" type="checkbox" id="" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="" type="checkbox" id="" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="" type="checkbox" id="" onChange={this.handleChangeCheckbox} />
                          <h4>Tecnológicas</h4>
                          <MDBInput label="Huerto" type="checkbox" id="tecnologicas_huerto" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Robótica" type="checkbox" id="tecnologicas_robotica" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Programación" type="checkbox" id="tecnologicas_programacion" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Edición de foto o video" type="checkbox" id="tecnologicas_edicion" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Radio" type="checkbox" id="tecnologicas_radio" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Electricidad" type="checkbox" id="tecnologicas_electricidad" onChange={this.handleChangeCheckbox} />
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>
                    <MDBCard className="border-0">
                      <MDBCollapseHeader
                        onClick={this.toggleCollapse("collapse4")}
                        tag="h5"
                        tagClassName="text-camelcase black-text mb-0 d-flex justify-content-start align-items-center"
                      >
                      <div
                          className="d-flex justify-content-center align-items-center mr-2"
                          style={{ backgroundColor: "#fff", minWidth: "80px" }}
                        >
                        <img src="images/Infraestructura de la escuela.svg" width="50" height="50" className="rounded float-left" alt="Infraestructura" />
                        </div>
                        Infraestructura
                        <div
                          className="d-flex text-right black-text"
                        >
                          <MDBPopover placement="right" popover clickable id="popper5">
                            <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                              <MDBIcon
                                className="black-text"
                                size="4X"
                                icon="info-circle"
                              />
                            </MDBBtn>
                                <div>
                                  <MDBPopoverHeader>Infraestructura</MDBPopoverHeader>
                                  <MDBPopoverBody>
                                  Comprende aquellos servicios y espacios que permiten el desarrollo de las tareas educativas.
                                  De esta forma, tener el conocimiento de la infraestructura, es necesario para tener una vista más amplia de las prestaciones que brinda su instituto.
                                  </MDBPopoverBody>
                                </div>
                          </MDBPopover>
                        </div>
                      </MDBCollapseHeader>

                      <MDBCollapse id="collapse4" isOpen={this.state.collapseID}>
                        <MDBCardBody className="rgba-white black-text z-depth-1 ml-2">
                          <h4>Canchas</h4>
                          <MDBInput label="Fútbol" type="checkbox" id="canchas_futbol" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Basketbol" type="checkbox" id="canchas_basquet" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Soccer" type="checkbox" id="canchas_soccer" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Tenis" type="checkbox" id="canchas_tenis" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Alberca" type="checkbox" id="canchas_alberca" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Arenero" type="checkbox" id="canchas_arenero" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Béisbol" type="checkbox" id="canchas_beisbol" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Área de Juegos" type="checkbox" id="canchas_areajuegos" onChange={this.handleChangeCheckbox} />
                          <h4>Laboratorios</h4>
                          <MDBInput label="Química" type="checkbox" id="lab_quimica" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Cocina" type="checkbox" id="lab_cocina" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Electricidad" type="checkbox" id="lab_electricidad" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Dibujo" type="checkbox" id="lab_dibujo" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Música" type="checkbox" id="lab_musica" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Computación" type="checkbox" id="lab_computacion" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Huertos" type="checkbox" id="lab_huertos" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Biblioteca" type="checkbox" id="lab_biblioteca" onChange={this.handleChangeCheckbox} />
                          <h4>Instalaciones</h4>
                          <MDBInput label="Oficinas" type="checkbox" id="instalaciones_oficina" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Comedor" type="checkbox" id="instalaciones_comedor" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Baños" type="checkbox" id="instalaciones_banios" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Vestidores" type="checkbox" id="instalaciones_vestidores" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Ambiente digital" type="checkbox" id="instalaciones_ambientedigital" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Auditorio" type="checkbox" id="instalaciones_auditorio" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Bodega" type="checkbox" id="instalaciones_bodega" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Jardines" type="checkbox" id="instalaciones_jardines" onChange={this.handleChangeCheckbox} />
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>
                    <MDBCard className="border-0">
                      <MDBCollapseHeader
                        onClick={this.toggleCollapse("collapse9")}
                        tag="h5"
                        tagClassName="text-camelcase black-text mb-0 d-flex justify-content-start align-items-center"
                      >
                      <div
                          className="d-flex justify-content-center align-items-center mr-2"
                          style={{ backgroundColor: "#fff", minWidth: "80px" }}
                        >
                        <img src="images/Inclusion.svg" width="50" height="50" className="rounded float-left" alt="Inclusión" />
                        </div>
                        Inclusión
                        <div
                          className="d-flex text-right black-text"
                        >
                          <MDBPopover placement="right" popover clickable id="popper5">
                            <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                              <MDBIcon
                                className="black-text"
                                size="4X"
                                icon="info-circle"
                              />
                            </MDBBtn>
                                <div>
                                  <MDBPopoverHeader>Inclusión</MDBPopoverHeader>
                                  <MDBPopoverBody>
                                  La Inclusión Educativa es un proceso orientado a garantizar el derecho a una educación de calidad a todos los estudiantes en igualdad de condiciones, prestando especial atención a quienes están en situación de mayor exclusión o en riesgo de ser marginados.
                                  </MDBPopoverBody>
                                </div>
                          </MDBPopover>
                        </div>
                      </MDBCollapseHeader>

                      <MDBCollapse id="collapse9" isOpen={this.state.collapseID}>
                        <MDBCardBody className="rgba-white black-text z-depth-1 ml-2">
                          <MDBInput label="Inclusión" type="checkbox" id="inclusion" onChange={this.handleChangeCheckbox} />
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>
                    <MDBCard className="border-0">
                      <MDBCollapseHeader
                        onClick={this.toggleCollapse("collapse5")}
                        tag="h5"
                        tagClassName="text-camelcase black-text mb-0 d-flex justify-content-start align-items-center"
                      >
                      <div
                          className="d-flex justify-content-center align-items-center mr-2"
                          style={{ backgroundColor: "#fff", minWidth: "80px" }}
                        >
                        <img src="images/Servicios escolares.svg" width="50" height="50" className="rounded float-left" alt="Servicios escolares" />
                        </div>
                        Servicios
                        <div
                          className="d-flex text-right black-text"
                        >
                          <MDBPopover placement="right" popover clickable id="popper6">
                            <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                              <MDBIcon
                                className="black-text"
                                size="4X"
                                icon="info-circle"
                              />
                            </MDBBtn>
                                <div>
                                  <MDBPopoverHeader>Servicios</MDBPopoverHeader>
                                  <MDBPopoverBody>
                                  Herramientas de gestión o características que facilitan la experiencia de cliente o usuario haciéndola, agradable, ergonómica, rápida, o práctica
                                  </MDBPopoverBody>
                                </div>
                          </MDBPopover>
                        </div>
                      </MDBCollapseHeader>

                      <MDBCollapse id="collapse5" isOpen={this.state.collapseID}>
                        <MDBCardBody className="rgba-white black-text  z-depth-1 ml-2">
                          <h4>Académicos</h4>
                          <MDBInput label="LMS" type="checkbox" id="academicos_lms" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Apps" type="checkbox" id="academicos_apps" onChange={this.handleChangeCheckbox} />
                          <h6>Certificación docente:</h6>
                          <MDBInput label="Inglés" type="checkbox" id="academicos_certingles" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Metodología" type="checkbox" id="academicos_certmetodo" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="ISO" type="checkbox" id="academicos_certiso" onChange={this.handleChangeCheckbox} />
                          <h4>Administración</h4>
                          <MDBInput label="Plataforma de pagos" type="checkbox" id="administracion_plataforma" onChange={this.handleChangeCheckbox} />
                          <h6>Acepta pagos:</h6>
                          <MDBInput label="Domiciliado" type="checkbox" id="administracion_domiciliado" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="TC/TD" type="checkbox" id="administracion_tdtc" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Anual" type="checkbox" id="administracion_anual" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Cheque" type="checkbox" id="administracion_cheque" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Descuento de Nómina" type="checkbox" id="administracion_descuento" onChange={this.handleChangeCheckbox} />
                          <h4>Comunicación</h4>
                          <MDBInput label="Página Web" type="checkbox" id="comunicacion_web" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Apps" type="checkbox" id="comunicacion_apps" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Facebook" type="checkbox" id="comunicacion_facebook" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Twitter" type="checkbox" id="comunicacion_twiter" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Whatsapp" type="checkbox" id="comunicacion_whatsapp" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="YouTube" type="checkbox" id="comunicacion_youtube" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Webinarios" type="checkbox" id="comunicacion_webinarios" onChange={this.handleChangeCheckbox} />
                          <h4>Seguridad</h4>
                          <MDBInput label="Enfermera" type="checkbox" id="seguridad_enfermera" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Psicólogo" type="checkbox" id="seguridad_psicologo" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Guardia" type="checkbox" id="seguridad_guardia" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="CCTV" type="checkbox" id="seguridad_cctv" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Certificación primer Respondiente" type="checkbox" id="seguridad_primerrespondiente" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Ambulancia" type="checkbox" id="seguridad_ambulancia" onChange={this.handleChangeCheckbox} />
                          <h4>Adicionales</h4>
                          <MDBInput label="Transporte" type="checkbox" id="adicional_transporte" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Programa de alimentación" type="checkbox" id="adicional_alimentacion" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Menú Nutritivo" type="checkbox" id="adicional_menu" onChange={this.handleChangeCheckbox} />
                          <MDBInput label="Cafetería de padres" type="checkbox" id="adicional_cafeteria" onChange={this.handleChangeCheckbox} />
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>
                    <MDBCard className="border-0">
                      <MDBCollapseHeader
                        onClick={this.toggleCollapse("collapse6")}
                        tag="h5"
                        tagClassName="text-camelcase black-text mb-0 d-flex justify-content-start align-items-center"
                      >
                      <div
                          className="d-flex justify-content-center align-items-center mr-2"
                          style={{ backgroundColor: "#fff", minWidth: "80px" }}
                        >
                        <img src="images/opinion de los padres.svg" width="50" height="50" className="rounded float-left" alt="opinion padres" />
                        </div>
                        Valoración
                        <div
                          className="d-flex text-right black-text"
                        >
                          <MDBPopover placement="right" popover clickable id="popper7">
                            <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                              <MDBIcon
                                className="black-text"
                                size="4X"
                                icon="info-circle"
                              />
                            </MDBBtn>
                                <div>
                                  <MDBPopoverHeader>Valoración</MDBPopoverHeader>
                                  <MDBPopoverBody>
                                  Evaluación diagnóstica de cada institución educativa, se presentan aquí los resultados en la plataforma
                                  </MDBPopoverBody>
                                </div>
                          </MDBPopover>
                        </div>
                      </MDBCollapseHeader>

                      <MDBCollapse id="collapse6" isOpen={this.state.collapseID}>
                        <MDBCardBody className="rgba-white black-text z-depth-1 ml-2">
                        <MDBRow className="my-4" center>
                          <MDBIcon
                            className="font-weight-bold indigo-text mr-2 mt-1"
                            icon="star"
                          />
                          <MDBRangeInput
                            id="opinion"
                            min={1}
                            max={5}
                            value={1}
                            formClassName="w-75"
                            getValue={this.handleChangeOpinion}
                          />
                          <MDBIcon
                            className="font-weight-bold indigo-text ml-2 mt-1"
                            icon="star"
                          />
                          <MDBIcon
                            className="font-weight-bold indigo-text ml-2 mt-1"
                            icon="star"
                          />
                        </MDBRow>
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>
                    <MDBCard className="border-0">
                      <MDBCollapseHeader
                        onClick={this.toggleCollapse("collapse7")}
                        tag="h5"
                        tagClassName="text-camelcase black-text mb-0 d-flex justify-content-start align-items-center"
                      >
                        <div
                          className="d-flex justify-content-center align-items-center mr-2"
                          style={{ backgroundColor: "#fff", minWidth: "80px" }}
                        >

                          <img src="images/Inversion anual mensualizada en escuela.svg" width="50" height="50" className="rounded float-left" alt="Inversión Anual Mensualizada"/>

                        </div>
                        Inversión Mensual
                        <div
                          className="d-flex text-right black-text"
                        >
                        <MDBPopover placement="right" popover clickable id="popper1" floating>
                                <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                                  <MDBIcon
                                    className="black-text"
                                    size="4X"
                                    icon="info-circle"
                                  />
                                </MDBBtn>
                                <div>
                                  <MDBPopoverHeader>Inversión Anualizada</MDBPopoverHeader>
                                  <MDBPopoverBody>
                                    IAM es el valor acumulado de los conceptos en que una familia debe invertir en el lapso de un ciclo escolar, e incluye mas no se limita a: Inscripción, colegiatura, cuotas, seguros, uniformes, eventos, viajes, materiales, paquetes de libros, software, equipos, disfraces, etc. La sumatoria se divide entre 12 meses.
                                  </MDBPopoverBody>
                                </div>
                          </MDBPopover>
                        </div>
                      </MDBCollapseHeader>

                      <MDBCollapse id="collapse7" isOpen={this.state.collapseID}>
                        <MDBCardBody className="rgba-white black-text z-depth-1 ml-2">
                              <MDBRow className="my-4" center>
                                <Histoslider
                                    // An array of objects to create the histogram
                                    data={[
                                      {
                                        x0: 0,    // Start of the bucket
                                        x: 1,     // End of the bucket
                                        y: 100    // Value
                                      },
                                      {
                                        x0: 1,    // Start of the bucket
                                        x: 2,     // End of the bucket
                                        y: 120    // Value
                                      },
                                      {
                                        x0:2,
                                        x: 3,
                                        y: 80
                                      }
                                    ]}
                                    // How much to pad the slider and histogram by, defaults to 20
                                    padding={20}
                                    // The extent of the selection, this doesn't have to be sorted (and you shouldn't sort it to store it)
                                    selection={[0, 3]}
                                    // A function to handle a change in the selection
                                    onChange={array => { console.log(array) }}
                                  />
                                <MDBIcon
                                  className="font-weight-bold indigo-text mr-2 mt-1"
                                  icon="dollar"
                                />
                                <MDBRangeInput
                                  id="inversion"
                                  min={1}
                                  max={5}
                                  value={1}
                                  formClassName="w-75"
                                  getValue={this.handleChangeInversion}
                                />
                                <MDBIcon
                                  className="font-weight-bold indigo-text ml-2 mt-1"
                                  icon="dollar"
                                />
                                <MDBIcon
                                  className="font-weight-bold indigo-text ml-2 mt-1"
                                  icon="dollar"
                                />
                              </MDBRow>
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>

                    <MDBCard className="border-0">
                      <MDBCollapseHeader
                        onClick={this.toggleCollapse("collapse8")}
                        tag="h5"
                        tagClassName="text-camelcase black-text mb-0 d-flex justify-content-start align-items-center"
                      >
                      <div
                          className="d-flex justify-content-center align-items-center mr-2"
                          style={{ backgroundColor: "#fff", minWidth: "80px" }}
                        >
                        <img src="images/ubicación en el mapa.svg" width="50" height="50" className="rounded float-left" alt="ubicación en el mapa"/>
                        </div>
                        Mapa
                        <div
                          className="d-flex justify-content-right"
                        >
                        <MDBPopover placement="right" popover clickable id="popper8">
                                <MDBBtn tag="a" className="mr-3 mt-2 p-0 z-depth-0" size="lg" color="white" >
                                  <MDBIcon
                                    className="black-text"
                                    size="4X"
                                    icon="info-circle"
                                  />
                                </MDBBtn>
                                <div>
                                  <MDBPopoverHeader>Mapa</MDBPopoverHeader>
                                  <MDBPopoverBody>
                                    Ubicación Geografica de la escuela.
                                  </MDBPopoverBody>
                                </div>
                          </MDBPopover>
                        </div>
                      </MDBCollapseHeader>

                      <MDBCollapse id="collapse8" isOpen={this.state.collapseID}>
                        <MDBCardBody className="rgba-white black-text z-depth-1 ml-2">
                          <MyFancyComponent isMarkerShown handleChangeMap={this.handleChangeMap} />
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>
                  </MDBContainer>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </div>
        </MDBCard>
        <MDBRow>
        </MDBRow>
      </MDBContainer>
      </div>
    );
  }
}

export default Filters;
