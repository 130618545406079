import React, { useEffect, useState }  from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput } from 'mdbreact';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase';
import { toast } from "react-toastify";

const CitaForm = (props) => {
  const initialStateValues = {
      email: "",
      name: "",
      message: "",
      whatsapp: "",
      sons: 0,
      sonList: [],
    };
    const { firestore} = props;
    const [values, setValues] = useState(initialStateValues);
    const handleChange = (e) => {
      const { name, value } = e.target;
      console.log(name);
      console.log(value);
      var id = name.charAt(name.length-1);
      var elem =  name.slice(0,-1);
      console.log(id);
      console.log(elem);
      const myNewArray = Object.assign([...values['sonList']], {
        [id]: {
            ...values['sonList'][id],
            [elem]: value
        }
    });
    console.log(myNewArray);
    setValues({ ...values, sonList: myNewArray });
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setValues({ ...values, [name]: value });
    };
    const validEmail = (str) => {
        return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(str);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("handleSubmit")
        console.log(values.email);
        if (!validEmail(values.email)) {
          return toast("Correo inválido", { type: "warning", autoClose: 1000 });
        }

        props.addOrEditCita(values);
        setValues({ ...initialStateValues });
      };

      const getLinkById = async (id) => {
          const doc = await firestore.collection("citas").doc(id).get();
          setValues({ ...doc.data() });
        };

        useEffect(() => {
          if (props.currentId === "") {
            setValues({ ...initialStateValues });
          } else {
            getLinkById(props.currentId);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.currentId]);

        useEffect(()=>{
          console.log("sons");
          var items = [];
          for (var i=0; i < values.sons; i++) {
            items.push({id: i, age: "", gender:""})
          }
          setValues({ ...values, sonList: items });
        },[values.sons])

return (
<MDBContainer>
  <MDBRow>
    <MDBCol>
      <form onSubmit={handleSubmit} >
        <p className="h5 text-center mb-4">Cita para {props.escuela.comercialName}</p>
        <div className="grey-text">
          <MDBInput label="Tú nombre" icon="user" group type="text" validate error="wrong"
            success="right" name="name"
          onChange={handleInputChange}/>
          <MDBInput label="Tú correo" icon="envelope" group type="email" validate error="wrong"
            success="right" name="email"
          onChange={handleInputChange} />
          <MDBInput label="Whatsapp" icon="phone" group type="text" validate error="wrong" success="right" name="whatsapp"
          onChange={handleInputChange}  />
          <MDBInput type="textarea" rows="3" label="Mensaje" icon="tag" name="message"
          onChange={handleInputChange} />
          <MDBInput type="number" min="0" max="5" label="Hijos" icon="user" name="sons"
          onChange={handleInputChange} />
          {values.sonList.map((item,i) => (
                <div>
                <label>
                  Genero:
                </label>
                <div>

                  <select  className="browser-default custom-select" key={i} name={'gender'+i} onChange={handleChange}>
                        <option key='' value=''>Escoja uno</option>
                        <option key='M' value='M'>Masculino</option>
                        <option key='F' value='F'>Femenino</option>
                  </select>
                </div>
                <label>
                  Edad:
                  <input
                    type="number"
                    name={"age"+i}
                    min="1"
                    max="25"
                    value={item.age}
                    onChange={handleChange}
                  />
                </label>
                </div>
              ))}
        </div>
        {props.currentId === "" ? (<div className="text-center">
          <MDBBtn outline color="secondary" type="submit">
            {props.currentId === "" ? "Enviar" : "Actualizar"}
            <MDBIcon far icon="paper-plane" className="ml-1" />
          </MDBBtn>
        </div>):(null)}
      </form>
    </MDBCol>
  </MDBRow>
</MDBContainer>
);
};

const mapDispatchToProps = (dispatch) => {
	return {
	}
}


const mapStateToProps = (state) => {
	console.log(state)
	return {
		auth: state.firebase.auth
	}
}
export default compose(
  withFirestore,
	connect(mapStateToProps, mapDispatchToProps),)(CitaForm);
