import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { deleteGoal, getAGoal } from '../../store/actions/createGoal'
import getGoal from '../../store/reducers/createGoal'

const Goals = (props) => {
	console.log(props)
	const { goal, deleteGoal, getAGoal }  = props
	let styling
	let goals

	if(goal){

		styling = "collection"
		goals = ( goal.map( g => {
				return (
					<li className="collection-item" key={ g.id } >
						{ g.comercialName }
						<Link className='secondary-content' to='/admin' onClick={ () => deleteGoal(g.id) }>
							<i className='material-icons delete'>delete</i>
						</Link>
						<Link className='secondary-content' to={`create/${g.id}`}>
							<i className='material-icons edit'>edit</i>
						</Link>
					</li>
				)
			})
		)
	}else{
		goals = <h4 style={{ textAlign: 'center' }}>Loading...</h4>
		styling = ''
	}

	return (
		<div>
			<ul className={`${styling}`} style={{ marginTop: '70px' }}>
				{ goals }
			</ul>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		deleteGoal: (id) => { dispatch(deleteGoal(id))},
		getAGoal: (id) => { dispatch(getAGoal(id))},
		getGoal: () => {dispatch(getGoal)}
	}
}


const mapStateToProps = (state) => {
	console.log(state)
	return {
		goal: state.firestore.ordered.ordenes,
		auth: state.firebase.auth
	}
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	firestoreConnect(props => {
		console.log(props);
		if (props.auth.uid === 'RSaL6cB7EEaylr2eQYzuSMq4nW22') {
			return [
				{ collection: 'ordenes'}
			]
		} else {
		 	return [
				{ collection: 'ordenes',
        	where: [
            	['authorId', '==', props.auth.uid]
        	]
				}
			]
		}
}),
)(Goals)
